import { Layout, MenuProps, Menu, Form } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { ReactNode, useEffect, useMemo, useState } from 'react';
import { ItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';

import './HomeworkLayout.scss';
import { TRootState } from 'src/stores';
import { BaseText } from 'src/components/text';
import { RoutePaths } from 'src/routes/routes-constants';
import Button from 'src/components/button';
import { HomeworkEditIcon, HomeworkNewIcon } from 'src/assets/icons';
import { ConfirmModal } from 'src/components/modals';
import Input from 'src/components/input';
import { useAppDispatch } from 'src/stores';
import { EHomeworkTopicSortBy, EHomework, EHomeworkType, ESortType } from 'src/variables/enum-variables';
import { FormItem } from 'src/components/forms';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import { TGetHomeworkTopicsResponse } from 'src/interfaces/homework-interface';
import {
  EHomeworkActions,
  createHomeworkTopics,
  deleteHomeworkTopicAction,
  getHomeworkTopics,
  setActivityTopicList,
  setQuestionTopicList,
  setVideoTopicList,
  setWrittenTaskTopicList,
  updateHomeworkTopicAction,
} from 'src/stores/homework';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import { usePathWithoutParams } from 'src/hooks/usePathWithoutParams';
import ConfirmModal2 from 'src/components/popup/confirmModal/ConfirmModal';

const { Sider, Content } = Layout;
interface IProps {
  children: ReactNode;
}

const ROUTE_PATH_TYPES: { [key: string]: EHomeworkType } = {
  activities: EHomeworkType.ACTIVITY,
  questionnaires: EHomeworkType.QUESTIONNAIRE,
  'written-tasks': EHomeworkType.WRITTEN_TASK,
  videos: EHomeworkType.VIDEO,
};

const HomeworkLayout = ({ children }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const params = useParams();
  const pathWithoutParams = usePathWithoutParams();
  const {
    editingHomeworkTopic,
    deletingHomeworkTopic,
    activityTopicList,
    videoTopicList,
    writtenTaskTopicList,
    questionTopicList,
  } = useSelector((state: TRootState) => ({
    editingHomeworkTopic: state.loading[EHomeworkActions.EDIT_HOMEWORK_TOPIC],
    deletingHomeworkTopic: state.loading[EHomeworkActions.DELETE_HOMEWORK_TOPIC],
    activityTopicList: state.homework.activityTopicList,
    writtenTaskTopicList: state.homework.writtenTaskTopicList,
    videoTopicList: state.homework.videoTopicList,
    questionTopicList: state.homework.questionTopicList,
  }));

  const DEFAULT_SELECTED_SUBMENU_KEYS: string[] =
    location.pathname === RoutePaths.HOMEWORK ? [RoutePaths.HOMEWORK_ACTIVITIES] : [location.pathname];
  const DEFAULT_OPEN_MENU_KEYS: string[] =
    location.pathname === RoutePaths.HOMEWORK ? [RoutePaths.HOMEWORK_ACTIVITIES] : [pathWithoutParams];
  const currentTopicId = params?.topicId;

  const [form] = useForm();
  const [selectedKeys, setSelectedKeys] = useState<string[]>(DEFAULT_SELECTED_SUBMENU_KEYS);
  const [addNewTopicModalInfo, setAddNewTopicModalInfo] = useState<{
    isOpen: boolean;
    type:
      | EHomeworkType.ACTIVITY
      | EHomeworkType.VIDEO
      | EHomeworkType.WRITTEN_TASK
      | EHomeworkType.QUESTIONNAIRE
      | null;
    isEdit?: boolean;
  }>({
    isOpen: false,
    isEdit: false,
    type: null,
  });
  const [visibleConfirmDeleteModal, setVisibleConfirmDeleteModal] = useState<boolean>(false);

  const getTopics = async () => {
    const responses = await Promise.all([
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.ACTIVITY,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.QUESTIONNAIRE,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.WRITTEN_TASK,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.VIDEO,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
    ]);

    const activityTopicsRes = responses[0].payload as TGetHomeworkTopicsResponse;
    const questionTopicsRes = responses[1].payload as TGetHomeworkTopicsResponse;
    const writtenTaskTopicsRes = responses[2].payload as TGetHomeworkTopicsResponse;
    const videoTopicRes = responses[3].payload as TGetHomeworkTopicsResponse;
    dispatch(setActivityTopicList(activityTopicsRes.data));
    dispatch(setQuestionTopicList(questionTopicsRes.data));
    dispatch(setWrittenTaskTopicList(writtenTaskTopicsRes.data));
    dispatch(setVideoTopicList(videoTopicRes.data));
  };

  const NavBarList: ItemType[] = useMemo(() => {
    return [
      {
        key: RoutePaths.HOMEWORK_ACTIVITIES,
        label: EHomework.ACTIVITIES,
        children: [
          {
            key: `add-new-activity`,
            label: (
              <Button
                className="new-topic"
                onClick={() =>
                  setAddNewTopicModalInfo({
                    isOpen: true,
                    type: EHomeworkType.ACTIVITY,
                  })
                }
              >
                <BaseText>New Topic</BaseText>
              </Button>
            ),
          },
          ...activityTopicList.map((topic) => ({
            key: `${RoutePaths.HOMEWORK_ACTIVITIES}/${topic.id}`,
            label: topic.name,
          })),
        ],
      },
      {
        key: RoutePaths.HOMEWORK_QUESTIONNAIRES,
        label: EHomework.QUESTIONNAIRES,
        children: [
          {
            key: `add-new-question`,
            label: (
              <Button
                className="new-topic"
                onClick={() =>
                  setAddNewTopicModalInfo({
                    isOpen: true,
                    type: EHomeworkType.QUESTIONNAIRE,
                  })
                }
              >
                <BaseText>New Topic</BaseText>
              </Button>
            ),
          },
          ...questionTopicList.map((topic) => ({
            key: `${RoutePaths.HOMEWORK_QUESTIONNAIRES}/${topic.id}`,
            label: topic.name,
          })),
        ],
      },
      {
        key: RoutePaths.HOMEWORK_WRITTEN_TASKS,
        label: EHomework.WRITTEN_TASKS,
        children: [
          {
            key: `add-new-written-task`,
            label: (
              <Button
                className="new-topic"
                onClick={() =>
                  setAddNewTopicModalInfo({
                    isOpen: true,
                    type: EHomeworkType.WRITTEN_TASK,
                  })
                }
              >
                <BaseText>New Topic</BaseText>
              </Button>
            ),
          },
          ...writtenTaskTopicList.map((topic) => ({
            key: `${RoutePaths.HOMEWORK_WRITTEN_TASKS}/${topic.id}`,
            label: topic.name,
          })),
        ],
      },
      {
        key: RoutePaths.HOMEWORK_VIDEOS,
        label: EHomework.VIDEOS,
        children: [
          {
            key: `add-new-video`,
            label: (
              <Button
                className="new-topic"
                onClick={() =>
                  setAddNewTopicModalInfo({
                    isOpen: true,
                    type: EHomeworkType.VIDEO,
                  })
                }
              >
                <BaseText>New Topic</BaseText>
              </Button>
            ),
          },
          ...videoTopicList.map((topic) => ({
            key: `${RoutePaths.HOMEWORK_VIDEOS}/${topic.id}`,
            label: topic.name,
          })),
        ],
      },
    ];
  }, [activityTopicList, videoTopicList]);

  const handleNavigation: MenuProps['onClick'] = (e) => {
    if (!e.key.includes('add-new')) {
      setSelectedKeys(e.keyPath);
      navigate(e.key);
    }
  };

  const routeTitle = useMemo(() => {
    const iterate = (item: ItemType) => {
      const menuItem = item as SubMenuType;

      if (menuItem.key === selectedKeys[0]) {
        selectedMenuItem = menuItem;
        return true;
      }
      return Array.isArray(menuItem.children) && menuItem.children.some(iterate);
    };

    let selectedMenuItem;

    NavBarList.some(iterate);
    return (selectedMenuItem as unknown as SubMenuType)?.label;
  }, [NavBarList, selectedKeys]);

  const onCloseModal = () =>
    setAddNewTopicModalInfo({
      isOpen: false,
      type: null,
      isEdit: false,
    });

  const onSaveNewTopic = async (values: { topicName: string }) => {
    const { topicName } = values;
    if (addNewTopicModalInfo.isEdit) {
      await dispatch(
        updateHomeworkTopicAction({
          id: params?.topicId ?? '',
          name: topicName,
          type: addNewTopicModalInfo.type as EHomeworkType,
          onSuccess: () => {
            showSuccessToast('Edit homework topic successfully!');
            getTopics();
          },
          onError: (error) => showErrorToast(error.message),
        }),
      );
      onCloseModal();
      return;
    }

    if (addNewTopicModalInfo.type && topicName) {
      const res = await dispatch(
        createHomeworkTopics({
          homeworkType: addNewTopicModalInfo.type,
          name: topicName,
        }),
      );

      if (res) {
        setAddNewTopicModalInfo({
          isOpen: false,
          type: null,
        });
        form.resetFields();
        getTopics();
      }
    }
  };

  const handleClickEditTopic = () => {
    const type = selectedKeys[0].split('/')[2];
    const homeworkType = type && ROUTE_PATH_TYPES[type];

    form.setFieldValue('topicName', routeTitle);
    setAddNewTopicModalInfo({ isOpen: true, isEdit: true, type: homeworkType as EHomeworkType });
  };

  const toggleVisibleConfirmDeleteModal = () => {
    setVisibleConfirmDeleteModal(!visibleConfirmDeleteModal);
  };

  const handleDeleteTopic = () => {
    dispatch(
      deleteHomeworkTopicAction({
        id: params?.topicId ?? '',
        onSuccess: () => {
          getTopics();
          toggleVisibleConfirmDeleteModal();
          showSuccessToast('Delete homework topic successfully!');
          navigate(RoutePaths.HOMEWORK);
        },
        onError: (error) => showErrorToast(error.message),
      }),
    );
  };

  useEffect(() => {
    getTopics();
  }, []);

  return (
    <>
      <div className="HomeworkHeader">
        <BaseText type="heading">Homework</BaseText>
        <Button className="HomeworkHeader__button" onClick={() => navigate(RoutePaths.HOMEWORK_ADD_NEW)}>
          <HomeworkNewIcon />
          <BaseText>New homework</BaseText>
        </Button>
      </div>
      <Layout className="HomeworkLayout">
        <Sider className="Sider" width={230}>
          <Menu
            theme="light"
            mode="inline"
            selectedKeys={selectedKeys}
            onClick={handleNavigation}
            items={NavBarList}
            className="Sider__menu"
            defaultSelectedKeys={DEFAULT_SELECTED_SUBMENU_KEYS}
            defaultOpenKeys={DEFAULT_OPEN_MENU_KEYS}
          />
        </Sider>
        <Content className="Content">
          <div className="Content__header">
            <div className="Content__header-left">
              <BaseText type="title">{routeTitle}</BaseText>
              {currentTopicId && (
                <HomeworkEditIcon className="Content__header-editIcon" onClick={handleClickEditTopic} />
              )}
            </div>
            {currentTopicId && (
              <Button
                danger
                type="primary"
                size="small"
                className="Content__header-delete"
                onClick={toggleVisibleConfirmDeleteModal}
              >
                Delete
              </Button>
            )}
          </div>
          {children}
        </Content>
      </Layout>
      <ConfirmModal
        open={addNewTopicModalInfo.isOpen}
        onCancel={onCloseModal}
        modalHeader={!addNewTopicModalInfo.isEdit ? 'Add new topic' : 'Edit topic'}
        className="HomeworkLayout__AddNewTopicModal"
      >
        <Form onFinish={onSaveNewTopic} form={form}>
          <div>
            <BaseText>Topic name</BaseText>
            <FormItem
              name="topicName"
              rules={[
                {
                  required: true,
                  message: 'Please enter the topic name',
                },
                {
                  max: 100,
                  message: `Valid topic name shouldn't exceed 100 characters`,
                },
                { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the topic name' },
              ]}
            >
              <Input />
            </FormItem>
          </div>
          <div className="AddNewTopicModal__buttons">
            <Button className="AddNewTopicModal__button" onClick={onCloseModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="AddNewTopicModal__button"
              htmlType="submit"
              loading={editingHomeworkTopic}
            >
              Save
            </Button>
          </div>
        </Form>
      </ConfirmModal>

      <ConfirmModal2
        open={visibleConfirmDeleteModal}
        titleModal="Remove this topic?"
        txtBtnCancel="No"
        txtBtnConfirm="Yes"
        loading={deletingHomeworkTopic}
        onsubmit={handleDeleteTopic}
        onCancelButton={toggleVisibleConfirmDeleteModal}
        onCancel={toggleVisibleConfirmDeleteModal}
      >
        <div className="LogoutModal">
          <BaseText type="body1">Do you want remove this topic?</BaseText>
        </div>
      </ConfirmModal2>
    </>
  );
};

export default HomeworkLayout;
