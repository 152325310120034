export interface IAddAndEditQuoteFormValues {
  message: string;
  author: string;
}

export enum EQuoteAction {
  ADD_NEW = 'ADD_NEW',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}
