import { STRING_WITHOUT_MARKUP_REGEX } from 'src/variables/constants';
import { EDateTimeSuffix } from 'src/variables/enum-variables';

export const convertRemindTime = (time: string, session: string) => {
  const timeConvert = parseInt(time.split(':').join(''));
  // if (session === EDateTimeSuffix.PM) {
  //   timeConvert += 1200;
  // }
  return timeConvert;
};

export const revertRemindTime = (time: number) => {
  let dateTimeSuffix = EDateTimeSuffix.AM;
  const timeSplit = time.toString().split('').reverse();
  const timeSplitLength = timeSplit.length;

  if (time < 100) {
    for (let i = 0; i < 4 - timeSplitLength; i++) {
      timeSplit.push('0');
    }
  }
  const minute = `${timeSplit[1]}${timeSplit[0]}`;
  const hour = `${timeSplit[3]}${!timeSplit[2] ? '' : timeSplit[2]}`;
  if (time - 1159 > 0) {
    dateTimeSuffix = EDateTimeSuffix.PM;
  }
  return {
    timeRevert: time ? `${hour}:${minute}` : '08:00',
    dateTimeSuffix,
  };
};

export const getTextFromHtmlTags = (string: string) => {
  return string.replace(STRING_WITHOUT_MARKUP_REGEX, '');
};
