import Container from 'src/components/containers/Container';
import './Home.scss';
import CommonContent from 'src/components/containers/CommonContent';
import { BaseText } from 'src/components/text';

import Overview from './overview/Overview';
import RevenueReport from './revenue-report/RevenueReport';
import { TRootState } from 'src/stores';
import { useSelector } from 'react-redux';
import { Spin } from 'antd';
import { EUserActions } from 'src/stores/user/user-constants';

const Home = () => {
  const profile = useSelector((state: TRootState) => state.user.profile);
  const { loading } = useSelector((state: TRootState) => ({
    loading: state.loading[EUserActions.GET_ADMIN_PROFILE],
  }));

  return (
    <Container className="Home">
      <CommonContent title="Dashboard" className="Home__header">
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', height: 42 }}>
            <Spin />
          </div>
        ) : (
          <div>
            <BaseText>Welcome to antsa, {`${profile?.firstName} ${profile?.lastName}`}</BaseText>
            <BaseText>---</BaseText>
          </div>
        )}
      </CommonContent>
      <Overview />
      <RevenueReport />
    </Container>
  );
};

export default Home;
