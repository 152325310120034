import { createSlice } from '@reduxjs/toolkit';

import { getAdminProfile, login } from './user-actions';
import { TMyProfileResponse } from 'src/interfaces/profile-interface';

interface IUser {
  username: string;
  password: string;
  profile?: TMyProfileResponse;
}

const initialState: IUser = {
  username: '',
  password: '',
  profile: undefined,
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    },
  },
  extraReducers(builder) {
    builder.addCase(login.fulfilled, (state, action) => {
      state.username = action.payload.username;
      state.password = action.payload.password;
    });
    builder.addCase(getAdminProfile.fulfilled, (state, action) => {
      state.profile = action.payload;
    });
  },
});

export const { setUser } = userSlice.actions;

export default userSlice;
