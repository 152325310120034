import { unwrapResult } from '@reduxjs/toolkit';
import { Form } from 'antd';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Button from 'src/components/button';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import Select from 'src/components/select';
import { ListLoadingSpinner } from 'src/components/spinner';
import { BaseText } from 'src/components/text';
import { TextArea } from 'src/components/text-area';
import { TimePicker } from 'src/components/timePicker';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import {
  ECategoryType,
  TCreateHomeworkParams,
  TGetHomeworkTopicsResponse,
  THomework,
  THomeworkTopic,
} from 'src/interfaces/homework-interface';
import { AddHomeworkLayout } from 'src/layouts/homework';
import { TRootState, useAppDispatch } from 'src/stores';
import {
  createHomework,
  deleteHomework,
  getHomeworkById,
  getHomeworkTopics,
  setActivityTopicList,
  setQuestionTopicList,
  setVideoTopicList,
  setWrittenTaskTopicList,
  updateHomeworkById,
  uploadHomeworkPhoto,
} from 'src/stores/homework';
import { convertRemindTime, revertRemindTime } from 'src/utils/txt-utils';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import { EHomeworkStatus, EHomeworkTopicSortBy, EHomeworkType, ESortType } from 'src/variables/enum-variables';
import './AddNew.scss';
import { NewQuestionnaryComponent } from './components';
import {
  EAnswerOption,
  HOME_WORK_TYPES,
  IHomeWorkFormData,
  INIT_FORM_ITEM_HEADER,
  INIT_FORM_ITEM_QUESTION,
} from './constants';
import { useTimezoneSelect, allTimezones, ITimezoneOption } from 'react-timezone-select';

const initialValues: IHomeWorkFormData = {
  title: '',
  type: '',
  description: '',
  homeworkTopicId: '',
  suffixTimePicker: 'AM',
  timePicker: dayjs('08:00', 'hh:mm'),
  videoLink: '',
  timezone: 'Australia/Sydney',

  items: [
    { ...INIT_FORM_ITEM_HEADER, homeworkId: uuidv4() },
    { ...INIT_FORM_ITEM_QUESTION, homeworkId: uuidv4() },
  ],
};

const labelStyle = 'original';
const timezones = {
  ...allTimezones,
  'Europe/Berlin': 'Frankfurt',
};

const AddNew = () => {
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm<IHomeWorkFormData>();

  const activityTopicList = useSelector((state: TRootState) => state.homework.activityTopicList);
  const videoTopicList = useSelector((state: TRootState) => state.homework.videoTopicList);
  const writtenTaskTopicList = useSelector((state: TRootState) => state.homework.writtenTaskTopicList);
  const questionTopicList = useSelector((state: TRootState) => state.homework.questionTopicList);

  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState<boolean>(false);
  const homeworkType = Form.useWatch('type', form);
  const homeworkId = param.homeworkId;
  const isEdit = !!homeworkId;

  const { options, parseTimezone } = useTimezoneSelect({ labelStyle, timezones });
  const filterTimeZone = options.filter((tz: ITimezoneOption) => Number(tz.offset) >= 0);
  const timeZoneOptions = filterTimeZone.map((tz: ITimezoneOption) => ({ label: tz.label, value: tz.value }));

  const getHomework = async () => {
    try {
      setLoading(true);
      if (homeworkId) {
        const result = await dispatch(getHomeworkById(homeworkId));
        const data = result.payload as THomework;
        if (data) {
          form.setFieldValue('title', data.title);
          form.setFieldValue('type', data.type);
          form.setFieldValue('description', data.description);
          form.setFieldValue('homeworkTopicId', data.homeworkTopicId);
          form.setFieldValue('videoLink', data.videoLink);
          form.setFieldValue('timezone', data.timezone);
          if (data.items) {
            data.items
              .sort((a, b) => a.index - b.index)
              .map((it) => {
                const newOptions = [] as any;
                if (it.options && Object.keys(it.options).length !== 0) {
                  Object.keys(it.options).map((prop: any) => {
                    if (it.options) {
                      return newOptions.push({ key: prop, value: it.options[prop] });
                    }
                  });
                }
                it.options = newOptions;
                return it;
              });
          }

          form.setFieldValue('items', data.items);
          if (data.remindAtHour) {
            form.setFieldValue('suffixTimePicker', revertRemindTime(data.remindAtHour).dateTimeSuffix);
            form.setFieldValue('timePicker', dayjs(revertRemindTime(data.remindAtHour).timeRevert, 'hh:mm'));
          }
        }
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      throw error;
    } finally {
      setLoading(false);
    }
  };
  Form.useWatch('items', form);

  const onCancel = () => {
    if (step === 1) {
      form.resetFields();
      navigate(-1);
    } else {
      setStep(step - 1);
    }
  };

  const getTopics = async () => {
    const responses = await Promise.all([
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.ACTIVITY,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.QUESTIONNAIRE,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.WRITTEN_TASK,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
      dispatch(
        getHomeworkTopics({
          homeworkType: EHomeworkType.VIDEO,
          page: 1,
          size: 10000,
          sortBy: EHomeworkTopicSortBy.NAME,
          sortType: ESortType.ASC,
        }),
      ),
    ]);

    const activityTopicsRes = responses[0].payload as TGetHomeworkTopicsResponse;
    const questionTopicsRes = responses[1].payload as TGetHomeworkTopicsResponse;
    const writtenTaskTopicsRes = responses[2].payload as TGetHomeworkTopicsResponse;
    const videoTopicRes = responses[3].payload as TGetHomeworkTopicsResponse;
    dispatch(setActivityTopicList(activityTopicsRes.data));
    dispatch(setQuestionTopicList(questionTopicsRes.data));
    dispatch(setWrittenTaskTopicList(writtenTaskTopicsRes.data));
    dispatch(setVideoTopicList(videoTopicRes.data));
  };

  const onSaveNewHomeWork = async (values: IHomeWorkFormData) => {
    if (step === 1 && values.type === EHomeworkType.QUESTIONNAIRE) {
      setStep(step + 1);
    } else {
      const newHomeworkParams: TCreateHomeworkParams | THomework = {
        category: ECategoryType.ACTION,
        title: values.title,
        homeworkTopicId: values.homeworkTopicId,
        type: values.type,
        description: values.description,
        enableRemind: true,
        videoLink: values.videoLink,
        status: EHomeworkStatus.ACTIVE,
        timezone: values.timezone,
      };

      newHomeworkParams.remindAtHour = convertRemindTime(
        dayjs(values.timePicker).format('HHmm'),
        dayjs(values.timePicker).format('A'),
      );

      setLoading(true);

      try {
        if (values?.items?.length > 0) {
          newHomeworkParams.items = await Promise.all(
            values.items.map(async (item, index) => {
              const twoDigitIndex = index < 10 ? `0${index}` : index;
              const options =
                item.options && item.options.length > 0
                  ? item.options.reduce(
                      (option, value, index) => ({
                        ...option,
                        // [`${index < 10 ? '0' : '1'}${index}-${value.key}`]: value.value,
                        [`${twoDigitIndex}-${value.key}`]: value.value,
                      }),
                      {},
                    )
                  : {};
              const photoResponse = item.photo
                ? unwrapResult(await dispatch(uploadHomeworkPhoto({ avatar: item.photo.file })))
                : undefined;

              return {
                homeworkId: item.homeworkId,
                title: item.title,
                description: item.description,
                required: item.required,
                index: index,
                options: item.questionType !== EAnswerOption.SHORT_ANSWER ? options : {},
                photoTitle: item.photoTitle,
                photoUri: photoResponse?.url,
                question: item.question,
                questionType: item.questionType,
                type: item.type,
                ...(item.id && { id: item.id }),
              };
            }),
          );
        }
        if (param.homeworkId) {
          newHomeworkParams.id = param.homeworkId;
          unwrapResult(await dispatch(updateHomeworkById(newHomeworkParams)));
        } else {
          unwrapResult(await dispatch(createHomework(newHomeworkParams)));
        }
        showSuccessToast(`${isEdit ? 'Edit' : 'Add new'} homework successfully!`);
        navigate(-1);
      } catch (error) {
        const message = (error as ResponseError).message;
        showErrorToast(message);
        throw error;
      } finally {
        setLoading(false);
      }
    }
  };

  const getListTopic = () => {
    let listTopic: THomeworkTopic[] = [];
    switch (homeworkType) {
      case EHomeworkType.ACTIVITY:
        listTopic = activityTopicList;
        break;
      case EHomeworkType.VIDEO:
        listTopic = videoTopicList;
        break;
      case EHomeworkType.QUESTIONNAIRE:
        listTopic = questionTopicList;
        break;
      case EHomeworkType.WRITTEN_TASK:
        listTopic = writtenTaskTopicList;
        break;
      default:
        listTopic = [];
    }

    return listTopic.map((topic) => ({
      label: topic.name,
      value: topic.id,
    }));
  };

  const handleDeleteHomework = async () => {
    if (homeworkId) {
      const res = await dispatch(deleteHomework(homeworkId));
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(-1);
      }
    }
  };

  useEffect(() => {
    getTopics();
    if (isEdit) {
      getHomework();
    }
  }, []);

  return (
    <AddHomeworkLayout
      title={
        step === 2 && form.getFieldValue('type') === EHomeworkType.QUESTIONNAIRE
          ? 'Add new questionnaries'
          : isEdit
          ? 'Edit Homework'
          : 'New Homework'
      }
      onBack={onCancel}
      onDelete={handleDeleteHomework}
    >
      <Form className="AddNewForm" initialValues={initialValues} form={form} onFinish={onSaveNewHomeWork}>
        {loading ? (
          <ListLoadingSpinner />
        ) : (
          <>
            <div hidden={step !== 1} className="AddNewForm__item">
              <FormItem
                label="Homework type"
                name="type"
                className="AddNewForm__item-container"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the homework type',
                  },
                ]}
              >
                <Select data={HOME_WORK_TYPES} disabled={isEdit} />
              </FormItem>

              <FormItem
                label="Topic"
                name="homeworkTopicId"
                className="AddNewForm__item-container"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the homework topic',
                  },
                ]}
              >
                <Select data={getListTopic()} disabled={isEdit} />
              </FormItem>

              <FormItem
                label="Homework title"
                name="title"
                className="AddNewForm__item-container"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the homework title',
                  },
                  {
                    max: 100,
                    message: `Valid homework title shouldn't exceed 100 characters`,
                  },
                  { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the homework title' },
                ]}
              >
                <Input />
              </FormItem>

              <FormItem
                leftLabel="Description"
                name="description"
                className="AddNewForm__item-container"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the homework description',
                  },
                  {
                    max: 2500,
                    message: `Valid homework title shouldn't exceed 2500 characters`,
                  },
                  { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the homework description' },
                ]}
              >
                <TextArea className="AddNewForm__text-area" />
              </FormItem>

              {homeworkType === EHomeworkType.VIDEO && (
                <FormItem
                  label="Video Link"
                  name="videoLink"
                  className="AddNewForm__item-container"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the video link',
                    },
                    {
                      type: 'url',
                      message: 'Invalid input. Video link must be a valid url.',
                    },
                  ]}
                >
                  <Input />
                </FormItem>
              )}

              <div className="AddNewForm__item-container row" style={{ marginTop: 30 }}>
                <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
                  <BaseText>Reminder at:</BaseText>
                </div>
                <FormItem
                  name="timePicker"
                  className="AddNewForm__item-container"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the time',
                    },
                  ]}
                  style={{ width: 140 }}
                >
                  <TimePicker
                    format="hh:mm A"
                    use12Hours
                    // disabledTime={() => ({ disabledHours: () => [13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23] })}
                    hideDisabledOptions
                    clearIcon={null}
                    className="AddNewForm__time-picker"
                  />
                </FormItem>
              </div>

              <FormItem
                label="Time zone"
                name="timezone"
                className="AddNewForm__item-container"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the time zone',
                  },
                ]}
              >
                <Select data={timeZoneOptions} placement="topLeft" />
              </FormItem>
            </div>

            {step === 2 && form.getFieldValue('type') === EHomeworkType.QUESTIONNAIRE ? (
              <div className="AddNewForm__item">
                <NewQuestionnaryComponent isEdit />
              </div>
            ) : null}

            <div className="AddNewForm__item-container row center">
              <Button className="AddNewForm__button" onClick={onCancel}>
                {step === 1 ? 'Cancel' : 'Back'}
              </Button>
              <Button className="AddNewForm__button" type="primary" htmlType="submit" loading={loading}>
                {homeworkType === EHomeworkType.QUESTIONNAIRE && step === 1 ? 'Next' : 'Save'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </AddHomeworkLayout>
  );
};

export default AddNew;
