import { PropsWithChildren } from 'react';

import './CommonContent.scss';
import { BaseText } from '../text';

interface IProps extends PropsWithChildren {
  className?: string;
  title: React.ReactNode;
  action?: React.ReactNode;
}

const CommonContent = ({ className, title, action, children }: IProps) => {
  return (
    <div className={`CommonContent ${className ?? ''}`}>
      <div className="CommonContent__header">
        <div className="CommonContent__header-title">
          {typeof title === 'string' ? <BaseText type="display1">{title}</BaseText> : title}
        </div>
        {action && <div className="CommonContent__header-action">{action}</div>}
      </div>
      <div className="CommonContent__content">{children}</div>
    </div>
  );
};

export default CommonContent;
