import { AxiosResponse } from 'axios';

import { ApiClient } from './api-client';
import {
  TCheckStatusLinkChangePasswordRequest,
  TCheckStatusLinkChangePasswordResponse,
  TForgotPasswordRequest,
  TForgotPasswordResponse,
  TResetPasswordRequest,
  TResetPasswordResponse,
  TSendOTPLoginRequest,
  TSendOTPLoginResponse,
  TSignInRequest,
  TSignInResponse,
} from 'src/interfaces/auth-interface';

export const signIn = async (payload: TSignInRequest) => {
  const response: AxiosResponse<TSignInResponse> = await ApiClient.post(`/api/v1/auth/login`, payload);
  return response.data;
};

export const forgotPassword = async (payload: TForgotPasswordRequest) => {
  const response: AxiosResponse<TForgotPasswordResponse> = await ApiClient.post(
    `/api/v1/auth/forgot-password`,
    payload,
  );

  return response.data;
};

export const resetPassword = async (payload: TResetPasswordRequest) => {
  const response: AxiosResponse<TResetPasswordResponse> = await ApiClient.post(`/api/v1/auth/reset-password`, payload);
  return response.data;
};

export const checkStatusLinkChangePassword = async (params: TCheckStatusLinkChangePasswordRequest) => {
  const response: AxiosResponse<TCheckStatusLinkChangePasswordResponse> = await ApiClient.get(`/api/v1/reset-tokens`, {
    params,
  });

  return response.data;
};

export const sendOTPLogin = async (payload: TSendOTPLoginRequest) => {
  const response: AxiosResponse<TSendOTPLoginResponse> = await ApiClient.post('/api/v1/auth/send-verify-code', payload);
  return response.data;
};
