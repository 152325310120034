import { createMemoryHistory } from 'history';
import { combineReducers } from 'redux';
import { createReduxHistoryContext } from 'redux-first-history';

import { loadingReducer } from '../loading';
import { userReducer } from '../user';
import { homeworkReducer } from '../homework';
import { clientsReducer } from '../clients';
import { quotesReducer } from '../quotes';
import { planManagementReducers } from '../plan-management';
import { psychoeducationReducer } from '../psychoeducation';

const { routerReducer } = createReduxHistoryContext({
  history: createMemoryHistory(),
});

const rootReducer = combineReducers({
  router: routerReducer,
  loading: loadingReducer,
  user: userReducer,
  homework: homeworkReducer,
  clients: clientsReducer,
  quotes: quotesReducer,
  planManagement: planManagementReducers,
  psychoeducation: psychoeducationReducer,
});

export default rootReducer;
