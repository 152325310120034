import { LeftOutlined } from '@ant-design/icons';
import { Layout } from 'antd';

import { useNavigate, useParams } from 'react-router-dom';
import { BaseText } from 'src/components/text';
import './AddHomeworkLayout.scss';
import Button from 'src/components/button';
import { useSelector } from 'react-redux';
import { TRootState } from 'src/stores';
import { EHomeworkActions } from 'src/stores/homework';
import ConfirmModal from 'src/components/popup/confirmModal/ConfirmModal';
import { useState } from 'react';

const { Header, Content } = Layout;

interface IProps {
  children: React.ReactNode;
  title?: string;
  onBack?: () => void;
  onDelete?: () => void;
}

const AddHomeworkLayout = ({ children, title = 'Home work', onBack, onDelete }: IProps) => {
  const navigate = useNavigate();
  const params = useParams();
  const isEdit = params?.homeworkId;
  const deleting = useSelector((state: TRootState) => state.loading[EHomeworkActions.DELETE_HOMEWORK]);
  const [visibleConfirmDeleteModal, setVisibleConfirmDeleteModal] = useState<boolean>(false);

  const handleBack = () => {
    if (onBack) {
      onBack();
    } else {
      navigate(-1);
    }
  };

  const toggleVisibleConfirmDeleteModal = () => {
    setVisibleConfirmDeleteModal(!visibleConfirmDeleteModal);
  };

  return (
    <Layout className="AddHomeworkLayout">
      <Header className="AddHomeworkLayoutHeader">
        <BaseText type="heading">Homework</BaseText>
      </Header>
      <Content className="Content">
        <div className="Content__header">
          <div className="Content__header-left">
            <LeftOutlined onClick={handleBack} className="Content__header-back" />
            <BaseText type="title">{title}</BaseText>
          </div>
          {isEdit && (
            <Button
              type="primary"
              danger
              className="Content__header-delete"
              loading={deleting}
              onClick={toggleVisibleConfirmDeleteModal}
            >
              Delete
            </Button>
          )}
        </div>
        {children}
      </Content>

      <ConfirmModal
        open={visibleConfirmDeleteModal}
        onCancelButton={toggleVisibleConfirmDeleteModal}
        onsubmit={() => {
          toggleVisibleConfirmDeleteModal();
          onDelete?.();
        }}
        titleModal="Remove this task?"
        txtBtnCancel="No"
        txtBtnConfirm="Yes"
      >
        <div className="LogoutModal">
          <BaseText type="body1">Do you want remove this task?</BaseText>
        </div>
      </ConfirmModal>
    </Layout>
  );
};

export default AddHomeworkLayout;
