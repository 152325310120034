import { CheckOutlined } from '@ant-design/icons';
import { useMemo } from 'react';
import Button from 'src/components/button';
import Select from 'src/components/select';
import { BaseText } from 'src/components/text';
import { TSubscriptionData } from 'src/interfaces/pricing-plan-interface';
import { ESubscriptionType } from 'src/variables/enum-variables';
import './SubscriptionCard.scss';

interface IProps {
  type?: ESubscriptionType;
  data: TSubscriptionData;
  selectedPlanId?: string;
  onChangePlan?: (planId: string) => void;
  onClickEdit: () => void;
}

const SubscriptionCard = ({
  type = ESubscriptionType.SOLO,
  data,
  selectedPlanId,
  onChangePlan,
  onClickEdit,
}: IProps) => {
  const isSoloPractitioner = type === ESubscriptionType.SOLO;
  const selectedPlan = data.plans?.length ? data.plans?.find((plan) => plan.id === selectedPlanId) : null;
  const selectedPlanTotal = selectedPlan?.amount ?? 0;

  const formattedPlanOptions = useMemo(() => {
    return data.plans?.map((plan) => ({
      label: (
        <div>
          <BaseText inline type="subHeading">
            ${plan.price}&nbsp;
          </BaseText>
          <BaseText inline>
            {plan.amount} practitioner license{plan.amount > 1 ? 's' : ''}
          </BaseText>
        </div>
      ),
      value: plan.id,
    }));
  }, [data]);

  return (
    <div className="SubscriptionPricingCard">
      <div className="SubscriptionPricingCard__top">
        <BaseText className="SubscriptionPricingCard__name">{data.name}</BaseText>
        <BaseText className="SubscriptionPricingCard__price">${data.price ?? selectedPlan?.price}</BaseText>

        <BaseText>
          {isSoloPractitioner ? 1 : selectedPlan?.amount} practitioner license{selectedPlanTotal > 1 ? 's ' : ''}
        </BaseText>
        <div className="SubscriptionPricingCard__descriptions">
          <BaseText>Description</BaseText>
          {data.descriptions?.map((description, index) => (
            <div className="SubscriptionPricingCard__description" key={index}>
              <CheckOutlined className="SubscriptionPricingCard__description-checkIcon" />
              <BaseText className="SubscriptionPricingCard__description-text">{description}</BaseText>
            </div>
          ))}
        </div>

        {!isSoloPractitioner && (
          <>
            <BaseText>Select plan</BaseText>
            <Select
              data={formattedPlanOptions}
              listItemHeight={undefined}
              className="SubscriptionPricingCard__selectPlan"
              popupClassName="SubscriptionPricingCard__selectPlan-popup"
              value={selectedPlan?.id}
              onChange={onChangePlan}
            />
          </>
        )}
      </div>
      <div>
        <BaseText textAlign="right" type="body2">
          {data.trialDay}-day Free trial
        </BaseText>
        <Button block type="primary" className="SubscriptionPricingCard__editBtn" onClick={onClickEdit}>
          Edit
        </Button>
      </div>
    </div>
  );
};

export default SubscriptionCard;
