import { Modal, ModalProps } from 'antd';
import React from 'react';
import { BaseText } from '../text';

import './ConfirmModal.scss';

interface IConfirmModalProps extends ModalProps {
  modalHeader: string;
  children: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
}

const ConfirmModal = ({ modalHeader, children, icon, className, ...rest }: IConfirmModalProps) => {
  return (
    <Modal className={`ConfirmModal ${className}`} footer={null} closable={false} centered {...rest}>
      {icon && <div className="icon">{icon}</div>}
      <BaseText type="heading" textAlign="center">
        {modalHeader}
      </BaseText>
      {children}
    </Modal>
  );
};

export default ConfirmModal;
