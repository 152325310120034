import { LeftOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import { BaseText } from 'src/components/text';
import './AddAndEditPsychoeducation.scss';
import { FormItem } from 'src/components/forms';
import Select from 'src/components/select';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import { TextArea } from 'src/components/text-area';
import Input from 'src/components/input';
import Button from 'src/components/button';
import {
  EAddAndEditPsychoeducationPageType,
  ICreateNewPsychoeducationForm,
  PSYCHOEDUCATION_ARTICLE_QUILL_FORMATS,
  PSYCHOEDUCATION_ARTICLE_QUILL_MODULES,
} from './add-and-edit-psychoeducation-constants';
import { EPsychoeducationType } from 'src/variables/enum-variables';
import Editor from 'src/components/editor/Editor';
import { useEffect, useLayoutEffect, useState } from 'react';
import { PreviewIcon } from 'src/assets/icons';
import PreviewModal from './PreviewModal';
import { useForm, useWatch } from 'antd/es/form/Form';
import { TRootState, useAppDispatch } from 'src/stores';
import { getPsychoeducationDetail } from 'src/stores/psychoeducation';
import {
  IPsychoeducation,
  TCreateNewPsychoeducationRequest,
  TUpdatePsychoeducationRequest,
} from 'src/interfaces/psychoeducation-interface';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { ListLoadingSpinner } from 'src/components/spinner';
import { validatePsychoeducationContent } from 'src/utils/validate-utils';

interface IAddAndEditPsychoeducationProps {
  psychoeducation: IPsychoeducation | null;
  pageType: EAddAndEditPsychoeducationPageType;
  psychoEducationType: EPsychoeducationType;
  isLoadingCreateAndEdit: boolean;
  onBack: () => void;
  onCreatePsychoeducation: (params: TCreateNewPsychoeducationRequest) => void;
  onEditPsychoeducation: (params: TUpdatePsychoeducationRequest) => void;
}

const AddAndEditPsychoeducation = ({
  psychoeducation,
  pageType,
  psychoEducationType,
  isLoadingCreateAndEdit,
  onBack,
  onCreatePsychoeducation,
  onEditPsychoeducation,
}: IAddAndEditPsychoeducationProps) => {
  const dispatch = useAppDispatch();
  const { psychoeducationTopicList } = useSelector((state: TRootState) => state.psychoeducation);
  const psychoeducationTypeLabel = psychoEducationType === EPsychoeducationType.ARTICLE ? 'Article' : 'Video';
  const [form] = useForm<ICreateNewPsychoeducationForm>();

  const content = useWatch('content', form);
  const [openPreviewModal, setOpenPreviewModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const addCustomPreviewButton = () => {
    const previewButton = document.querySelector('.ql-toolbar.ql-snow .ql-preview');

    if (!previewButton) {
      const test = document.querySelector('.ql-toolbar.ql-snow');
      const quillToolBar = document.createElement('span');
      quillToolBar.classList.add('ql-formats');
      const button = document.createElement('button');
      button.classList.add('ql-preview');
      button.type = 'button';
      button.addEventListener('click', () => setOpenPreviewModal(true));
      const previewIcon = document.createElement('img');
      previewIcon.setAttribute('src', PreviewIcon);
      button.append(previewIcon);
      quillToolBar.append(button);
      test?.append(quillToolBar);
    }
  };

  const handleClosePreviewModal = () => setOpenPreviewModal(false);

  const handleSave = (values: ICreateNewPsychoeducationForm) => {
    const params = {
      content: values.content,
      psychoeducationTopicId: values.psychoeducationTopicId,
      title: values.title,
      type: psychoEducationType,
      ...(values.videoLink && { videoLink: values.videoLink }),
    };

    if (pageType === EAddAndEditPsychoeducationPageType.ADD_NEW) {
      onCreatePsychoeducation(params);
    } else {
      psychoeducation &&
        onEditPsychoeducation({
          id: psychoeducation.id,
          ...params,
        });
    }
  };

  const getDetailPsychoeducation = async () => {
    if (psychoeducation) {
      setIsLoading(true);
      const data = unwrapResult(await dispatch(getPsychoeducationDetail({ id: psychoeducation.id })));
      form.setFieldsValue(data);
      setIsLoading(false);
    }
  };

  useLayoutEffect(() => {
    if (psychoEducationType === EPsychoeducationType.ARTICLE) {
      addCustomPreviewButton();
    }
  }, [isLoading]);

  useEffect(() => {
    getDetailPsychoeducation();
  }, []);

  return (
    <div className="AddAndEditPsychoeducation">
      <div className="AddAndEditPsychoeducation__header">
        <LeftOutlined onClick={onBack} className="AddAndEditPsychoeducation__header-back" />
        <BaseText type="title">
          {pageType === EAddAndEditPsychoeducationPageType.ADD_NEW ? 'New' : 'Edit'} {psychoeducationTypeLabel}
        </BaseText>
      </div>
      {!isLoading ? (
        <>
          <Form className="AddAndEditPsychoeducation__Form" onFinish={handleSave} form={form}>
            <FormItem
              label={`${psychoeducationTypeLabel} topic`}
              name="psychoeducationTopicId"
              className="AddAndEditPsychoeducation__Form-item-container"
              rules={[
                {
                  required: true,
                  message: `Please select the ${psychoeducationTypeLabel.toLowerCase()} topic`,
                },
              ]}
            >
              <Select
                data={psychoeducationTopicList.map((topic) => ({
                  label: topic.name,
                  value: topic.id,
                }))}
                disabled={false}
              />
            </FormItem>

            <FormItem
              label={`${psychoeducationTypeLabel} title`}
              name="title"
              className="AddAndEditPsychoeducation__Form-item-container"
              rules={[
                {
                  required: true,
                  message: `Please enter the ${psychoeducationTypeLabel.toLowerCase()} title`,
                },
                {
                  pattern: STRING_NOT_ONLY_SPACE_REGEX,
                  message: `Please enter the ${psychoeducationTypeLabel.toLowerCase()} title`,
                },
                {
                  max: 255,
                  message: `The ${psychoeducationTypeLabel.toLowerCase()} title should not exceed 255 characters`,
                },
              ]}
            >
              <Input />
            </FormItem>

            {psychoEducationType === EPsychoeducationType.VIDEO ? (
              <>
                <FormItem
                  leftLabel="Short description"
                  name="content"
                  className="AddAndEditPsychoeducation__Form-item-container"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the description',
                    },
                    {
                      max: 2000,
                      message: 'The video description should not exceed 2000 characters',
                    },
                    { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the video description' },
                  ]}
                >
                  <TextArea className="AddAndEditPsychoeducation__Form-text-area" />
                </FormItem>

                <FormItem
                  leftLabel="Video"
                  name="videoLink"
                  className="AddAndEditPsychoeducation__Form-item-container"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter the video link',
                    },
                    { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the video link' },
                  ]}
                >
                  <Input />
                </FormItem>
              </>
            ) : (
              <FormItem
                leftLabel="Content"
                name="content"
                className="AddAndEditPsychoeducation__Form-item-container"
                rules={[{ validator: validatePsychoeducationContent }]}
              >
                <Editor
                  isShowToolbar
                  className="AddAndEditPsychoeducation__Form-quill-editor"
                  modules={PSYCHOEDUCATION_ARTICLE_QUILL_MODULES}
                  formats={PSYCHOEDUCATION_ARTICLE_QUILL_FORMATS}
                />
              </FormItem>
            )}

            <div className="AddAndEditPsychoeducation__Form-item-container row center">
              <Button className="AddAndEditPsychoeducation__Form-button" onClick={onBack}>
                Cancel
              </Button>
              <Button
                className="AddAndEditPsychoeducation__Form-button"
                type="primary"
                htmlType="submit"
                loading={isLoadingCreateAndEdit}
                disabled={isLoadingCreateAndEdit}
              >
                Save
              </Button>
            </div>
          </Form>
          <PreviewModal
            content={content}
            open={openPreviewModal}
            onCancel={handleClosePreviewModal}
            onClose={handleClosePreviewModal}
          />
        </>
      ) : (
        <center>
          <ListLoadingSpinner />
        </center>
      )}
    </div>
  );
};

export default AddAndEditPsychoeducation;
