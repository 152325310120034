import { useState } from 'react';
import { Layout, Dropdown } from 'antd';
import type { MenuProps } from 'antd';
import { Link } from 'react-router-dom';
import { BaseText } from 'src/components/text';

import './Header.scss';
import { setItem } from 'src/utils/storage-utils';
import { RoutePaths } from 'src/routes/routes-constants';
import { EAuthToken } from 'src/variables/storage';
import { AvatarIcon, LogoutIcon, ProfileIcon } from 'src/assets/icons';
import ConfirmModal from 'src/components/popup/confirmModal/ConfirmModal';

const HeaderAntd = Layout.Header;

interface IHeader {
  username: string;
  avatar?: string;
}

const Header = ({ username }: IHeader) => {
  const [isConfirmLogout, setIsConfirmLogout] = useState<boolean>(false);

  const logout = () => {
    setIsConfirmLogout(false);
    setItem(EAuthToken.ACCESS_TOKEN, '');
    window.location.replace(RoutePaths.SIGN_IN);
  };

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <Link to={RoutePaths.PROFILE} className="Menu__item">
          <ProfileIcon color="red" />
          <BaseText type="body1" inline={true} className="Menu__item-title">
            My Profile
          </BaseText>
        </Link>
      ),
    },
    {
      key: '3',
      label: (
        <a href="#" className="Menu__item" onClick={() => setIsConfirmLogout(true)}>
          <LogoutIcon />
          <BaseText type="body1" inline={true} className="Menu__item-title">
            Logout
          </BaseText>
        </a>
      ),
    },
  ];

  return (
    <HeaderAntd className="Header">
      <div className="Header__right">
        <BaseText type="body1" inline={false}>
          {username}
        </BaseText>
        {/* <div className="Header__icon">
          <BellIcon color="red" />
        </div> */}
        <Dropdown menu={{ items }}>
          <div className="Header__avatar">
            <AvatarIcon />
          </div>
        </Dropdown>
      </div>
      <ConfirmModal
        open={isConfirmLogout}
        onCancelButton={() => setIsConfirmLogout(false)}
        onsubmit={logout}
        titleModal="Confirm"
      >
        <div className="LogoutModal">
          <BaseText type="body1">Are you sure to logout?</BaseText>
        </div>
      </ConfirmModal>
    </HeaderAntd>
  );
};

export default Header;
