import { ColumnsType } from 'antd/es/table';
import Table from 'src/components/table';
import { FilterIcon } from 'src/assets/icons';
import { BaseText } from 'src/components/text';
import { TFilterValues, TPagination } from 'src/interfaces/common-interface';
import './ClientsTable.scss';
import { TClient } from 'src/interfaces/clients-interface';
import StatusBox from 'src/components/status-box';
import { EPaymentStatus } from 'src/variables/enum-variables';
import { DEFAULT_CLIENTS_PAGE_SIZE } from 'src/pages/clients/clients-constants';

interface IProps extends TPagination {
  data: TClient[];
  loading: boolean;
  isClinicPractitionerTab: boolean;
  onChangePage: (page: number) => void;
  onClickRow: (rowData: TClient) => void;
  onFilter: (values: TFilterValues) => void;
}

const ClientsTable = ({
  isClinicPractitionerTab,
  data,
  loading,
  currentPage,
  totalPage,
  totalRecord,
  onChangePage,
  onClickRow,
  onFilter,
}: IProps) => {
  const pagination: TPagination = { currentPage, totalPage, totalRecord, pageSize: DEFAULT_CLIENTS_PAGE_SIZE };

  const column: ColumnsType<TClient> = isClinicPractitionerTab
    ? [
        {
          title: 'Clinic owner name',
          key: 'clinicOwnerName',
          render: (_, data) => (
            <div className="details_container">
              <div className="avatar">
                <img src={data.ownerAvatar} />
              </div>
              <div className="name">
                <BaseText type="body1">
                  {data.ownerFirstName} {data.ownerLastName}
                </BaseText>
                <BaseText type="body1">{data.ownerEmail}</BaseText>
              </div>
            </div>
          ),
          width: '33%',
        },
        {
          title: 'Clinic name',
          key: 'clinicName',
          dataIndex: 'clinicName',
        },
        {
          title: 'Pricing Plan',
          key: 'pricingPlan',
          render: (_, data) => data.pricingPlan && <BaseText>${data.pricingPlan}</BaseText>,
        },
        {
          title: 'Active practitioners',
          key: 'activePractitioner',
          dataIndex: 'activePractitioner',
        },
        {
          title: 'Active clients',
          key: 'activeClient',
          dataIndex: 'activeClient',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          width: 160,
          filters: [
            {
              text: 'Active',
              value: EPaymentStatus.ACTIVE,
            },
            {
              text: 'Cancelled',
              value: EPaymentStatus.CANCELED,
            },
            {
              text: 'Free trial',
              value: EPaymentStatus.TRIALING,
            },
          ],
          render: (_, data) => <StatusBox status={data.status} />,
          filterIcon: () => (
            <div className="filter_custom">
              <FilterIcon />
            </div>
          ),
        },
      ]
    : [
        {
          title: 'Practitioner name',
          key: 'practitionerName',
          render: (_, data) => (
            <div className="details_container">
              <div className="avatar">
                <img src={data.ownerAvatar} />
              </div>
              <div className="name">
                <BaseText type="body1">
                  {data.ownerFirstName} {data.ownerLastName}
                </BaseText>
                <BaseText type="body1">{data.ownerEmail}</BaseText>
              </div>
            </div>
          ),
          width: '33%',
        },
        {
          title: 'Pricing Plan',
          key: 'pricingPlan',
          render: (_, data) => data.pricingPlan && <BaseText>${data.pricingPlan}</BaseText>,
        },
        {
          title: 'Active clients',
          key: 'activeClient',
          dataIndex: 'activeClient',
        },
        {
          title: 'Status',
          key: 'status',
          dataIndex: 'status',
          width: 160,
          filters: [
            {
              text: 'Active',
              value: EPaymentStatus.ACTIVE,
            },
            {
              text: 'Cancelled',
              value: EPaymentStatus.CANCELED,
            },
            {
              text: 'Free trial',
              value: EPaymentStatus.TRIALING,
            },
          ],
          render: (_, data) => <StatusBox status={data.status} />,
          filterIcon: () => (
            <div className="filter_custom">
              <FilterIcon />
            </div>
          ),
        },
      ];

  return (
    <Table
      className="ClientsTable"
      dataSource={data}
      columns={column}
      loading={loading}
      pagination={pagination}
      onFilter={onFilter}
      onClickRow={onClickRow}
      onChangePage={onChangePage}
      locale={{
        emptyText: 'No result found',
      }}
    />
  );
};

export default ClientsTable;
