import { Form, Modal, ModalProps } from 'antd';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { PASSWORD_REGEX } from 'src/variables/constants';
import Button from 'src/components/button';
import './ChangePasswordModal.scss';
import { BaseText } from 'src/components/text';
import { TChangePasswordRequest, TChangePasswordResponse } from 'src/interfaces/profile-interface';
import { useState } from 'react';

export type TChangePassword = {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
};

interface IChangePasswordModal extends ModalProps {
  onSave: (values: TChangePasswordRequest) => Promise<TChangePasswordResponse | undefined>;
  onClose: () => void;
  email: string;
  errorMsg?: string;
  onChangeValues?: () => void;
}

const ChangePasswordModal = ({ onSave, onClose, email, errorMsg, onChangeValues, ...rest }: IChangePasswordModal) => {
  const [form] = Form.useForm<TChangePassword>();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleClose = () => {
    onClose();
    form.resetFields();
  };

  const handleSubmit = async (values: TChangePasswordRequest) => {
    setIsLoading(true);
    try {
      const result = await onSave(values);
      if (result) {
        handleClose();
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal centered footer={null} width={370} closable={false} {...rest} className="ChangePasswordModal">
      <div className="ChangePasswordModal__input-row">
        <BaseText type="title" textAlign="center" className="ChangePasswordModal__title">
          Change password
        </BaseText>
        <BaseText type="caption" textAlign="left" className="ChangePasswordModal__description">
          Strong passwords include numbers, letters and punctuation marks.
        </BaseText>
        <BaseText type="caption" textAlign="left" className="ChangePasswordModal__description">
          {`Email: ${email}`}
        </BaseText>
      </div>
      <Form form={form} onFinish={handleSubmit} onValuesChange={onChangeValues}>
        <div className="ChangePasswordModal__input-row">
          <BaseText>Current Password</BaseText>
          <FormItem
            name="oldPassword"
            rules={[
              { required: true, message: 'Please enter the password' },
              {
                pattern: PASSWORD_REGEX,
                message:
                  'Password must have at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character, maximum characters is 16.',
              },
            ]}
          >
            <Input type="password" />
          </FormItem>
        </div>
        <div className="ChangePasswordModal__input-row">
          <BaseText>New Password</BaseText>
          <FormItem
            name="newPassword"
            rules={[
              { required: true, message: 'Please enter the password' },
              {
                pattern: PASSWORD_REGEX,
                message:
                  'Password must have at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character, maximum characters is 16.',
              },
            ]}
          >
            <Input type="password" />
          </FormItem>
        </div>
        <div className="ChangePasswordModal__input-row">
          <BaseText>Confirm new Password</BaseText>
          <FormItem
            name="confirmPassword"
            rules={[
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (getFieldValue('newPassword') === value && value) {
                    return Promise.resolve();
                  }
                  if (!value) {
                    return Promise.reject(new Error('Please enter the password again'));
                  }
                  return Promise.reject(new Error('Password does not match'));
                },
              }),
            ]}
          >
            <Input type="password" />
          </FormItem>
        </div>
        <div className="ChangePasswordModal__buttons">
          {errorMsg && (
            <BaseText type="caption" color="error" textAlign="center" className="RegisterPage__errorMsg">
              {errorMsg}
            </BaseText>
          )}
          <Button type="primary" htmlType="submit" loading={isLoading}>
            Save
          </Button>
          <Button onClick={handleClose}>Cancel</Button>
        </div>
      </Form>
    </Modal>
  );
};

export default ChangePasswordModal;
