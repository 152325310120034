import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_GET_LIST_RESPONSE } from 'src/variables/common';
import * as asyncActions from './psychoeducation-actions';
import { IPsychoeducationTopic, TGetPsychoeducationListResponse } from 'src/interfaces/psychoeducation-interface';

type TPsychoeducationState = {
  psychoeducationList: TGetPsychoeducationListResponse;
  psychoeducationTopicList: IPsychoeducationTopic[];
};

const initialState: TPsychoeducationState = {
  psychoeducationList: DEFAULT_GET_LIST_RESPONSE,
  psychoeducationTopicList: [],
};

export const psychoeducationSlice = createSlice({
  name: 'psychoeducation',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(asyncActions.getPsychoeducationList.fulfilled, (state, action) => {
      state.psychoeducationList = action.payload;
    });
    builder.addCase(asyncActions.getPsychoeducationTopicList.fulfilled, (state, action) => {
      state.psychoeducationTopicList = action.payload.data;
    });
  },
});

export default psychoeducationSlice;
