import { Form, FormItemProps } from 'antd';

import './FormItem.scss';

interface IFormItemProps extends FormItemProps {
  leftLabel?: React.ReactNode;
  rightLabel?: React.ReactNode;
}

const FormItem = ({ children, className, label, leftLabel, rightLabel, ...rest }: IFormItemProps) => {
  return (
    <Form.Item
      className={`FormItem ${className ?? ''}`}
      required={false}
      label={
        label ? (
          label
        ) : leftLabel || rightLabel ? (
          <>
            {typeof leftLabel === 'string' ? <span>{leftLabel}</span> : leftLabel}
            {typeof rightLabel === 'string' ? <span className="rightLabel">{rightLabel}</span> : rightLabel}
          </>
        ) : null
      }
      {...rest}
    >
      {children}
    </Form.Item>
  );
};

export default FormItem;
