import { EGetListClientsType } from 'src/interfaces/clients-interface';

export const DEFAULT_CLIENTS_PAGE_SIZE = 15;

export const CLIENTS_TAB = [
  {
    key: EGetListClientsType.CLINIC,
    label: 'Clinics',
  },
  {
    key: EGetListClientsType.SOLO,
    label: 'Solo practitioners',
  },
];
