export enum EAddAndEditPsychoeducationPageType {
  ADD_NEW = 'ADD_NEW',
  EDIT = 'EDIT',
}

export const PSYCHOEDUCATION_ARTICLE_QUILL_MODULES = {
  toolbar: [
    [{ size: ['small', 'large', 'huge'] }],
    ['bold', 'italic', 'underline'],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }, 'link', 'image'],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const PSYCHOEDUCATION_ARTICLE_QUILL_FORMATS = [
  'bold',
  'italic',
  'underline',
  'link',
  'list',
  'bullet',
  'indent',
  'align',
  'size',
  'image',
];

export interface ICreateNewPsychoeducationForm {
  title: string;
  content: string;
  videoLink: string;
  psychoeducationTopicId: string;
}
