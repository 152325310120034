import { BaseText } from 'src/components/text';
import './StatisticCard.scss';

interface IStatisticCardProps {
  type: 'discharged' | 'active' | 'pending' | 'message';
  content: string | number;
  heading: string;
}

const StatisticCard = ({ type, heading, content }: IStatisticCardProps) => {
  return (
    <div className="StatisticCard">
      <div className="StatisticCard__wrapper">
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
          <div className={`StatisticCard__decor StatisticCard__decor--${type}`}></div>
          <div>
            <BaseText type="subHeading" className="StatisticCard__heading">
              {heading}
            </BaseText>
          </div>
        </div>
        <BaseText type="xl-3" className={`StatisticCard__content--${type}`}>
          {content}
        </BaseText>
      </div>
    </div>
  );
};

export default StatisticCard;
