import { BaseText } from 'src/components/text';
import Button from 'src/components/button';
import './FooterPage.scss';
import { useState } from 'react';
import ChangePasswordModal from './ChangePasswordModal';
import { TChangePasswordRequest } from 'src/interfaces/profile-interface';
import { showSuccessToast } from 'src/components/toast/Toast';
import { useAppDispatch } from 'src/stores';
import { changePassword } from 'src/stores/user';
import { unwrapResult } from '@reduxjs/toolkit';
import ResponseError from 'src/interfaces/error-response-interface';

export type FooterPageProps = {
  email: string;
};

const FooterPage = ({ email }: FooterPageProps) => {
  const dispatch = useAppDispatch();
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>('');

  const handleChangePassword = async (values: TChangePasswordRequest) => {
    try {
      const result = unwrapResult(await dispatch(changePassword(values)));
      if (result.success) {
        showSuccessToast("Congratulations! You've successfully changed your password.");
        return result;
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      setErrorMsg(message);
    }
  };

  const onChangeValues = () => {
    setErrorMsg('');
  };

  return (
    <div className="FooterPage">
      <BaseText type="display1">My account</BaseText>
      <BaseText type="default">{`Email: ${email}`}</BaseText>
      <Button
        type="primary"
        onClick={() => {
          setOpenChangePasswordModal(!openChangePasswordModal);
        }}
      >
        Change password
      </Button>
      <ChangePasswordModal
        email={email}
        open={openChangePasswordModal}
        onClose={() => {
          setOpenChangePasswordModal(!openChangePasswordModal);
        }}
        onSave={handleChangePassword}
        errorMsg={errorMsg}
        onChangeValues={onChangeValues}
      />
    </div>
  );
};

export default FooterPage;
