import { BorderOutlined, MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Image, Switch, Tooltip, Upload, UploadFile } from 'antd';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { RcFile, UploadChangeParam } from 'antd/es/upload';

import { AddGalerryIcon, PlusCircleIcon, RadioButtonIcon, TitleDescriptionIcon } from 'src/assets/icons';
import { BlankPictureImg } from 'src/assets/images';
import Editor from 'src/components/editor/Editor';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import Select from 'src/components/select';
import { showErrorToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import { getBase64, validateFileType, validateSizeImage } from 'src/utils/common-utils';
import {
  ANSWER_OPTIONS,
  EAnswerOption,
  EFormItemType,
  IHomeWorkFormItemData,
  QUILL_DESCRIPTION_FORMATS,
  QUILL_DESCRIPTION_MODULES,
  QUILL_QUESTION_FORMATS,
  QUILL_QUESTION_MODULES,
  QUILL_TITLE_FORMATS,
  QUILL_TITLE_MODULES,
} from '../constants';
import './NewQuestionnaryComponent.scss';
import { BaseText } from 'src/components/text';
import { EHomeworkImageAcceptedFileType } from 'src/variables/enum-variables';
import { validateHomeworkDescription, validateHomeworkQuestion, validateHomeworkTitle } from 'src/utils/validate-utils';

export interface NewQuestionnaryComponentProps {
  isEdit?: boolean;
}

const NewQuestionnaryComponent = ({ isEdit }: NewQuestionnaryComponentProps) => {
  const form = Form.useFormInstance();
  const [selectedHomeWorkId, setSelectedHomeWorkId] = useState<string | undefined>('');
  const [homeworkIdEdit, setHomeWorkIdEdit] = useState<string | undefined>('');
  const [previewUrls, setPreviewUrls] = useState<{ [homeWorkId: string]: string }>({});

  const handleChangeFile = async (info: UploadChangeParam<UploadFile<unknown>>, index: number) => {
    form.setFieldValue([index, 'photo'], info.file);
  };

  const handleBeforeUploadFile = async (file: RcFile, homeWorkId: string) => {
    const validFileType = file.type && validateFileType(file.type, EHomeworkImageAcceptedFileType);
    const validFileSize = file.size && validateSizeImage(file.size, 5);

    if (!validFileType) {
      showErrorToast('Allowed file types: JPG, PNG, JPEG.');
      return;
    }

    if (!validFileSize) {
      showErrorToast('Maximum file size: 5MB.');
      return;
    }

    try {
      const previewUrl = await getBase64(file as RcFile);
      setPreviewUrls({ ...previewUrls, [homeWorkId]: previewUrl });

      return false;
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  };

  const handleRemoveFile = (index: number, homeworkId?: string) => {
    form.setFieldValue([index, 'photo'], undefined);

    const newPreviewUrls = previewUrls;

    delete newPreviewUrls[index];
    setPreviewUrls(newPreviewUrls);
  };

  return (
    <Form.List name="items">
      {(items, { add, remove }) => {
        return (
          <div className="NewQuestionnaryComponent">
            <div className="NewQuestionnaryComponent__form">
              {items.map((item, idx) => {
                const data: IHomeWorkFormItemData = form.getFieldValue(['items', item.name]);
                setHomeWorkIdEdit(data.homeworkId);
                const isHomeWorkSelected = idx.toString() === selectedHomeWorkId;
                return (
                  <div
                    key={item.key}
                    className="NewQuestionnaryComponent__form-card"
                    aria-disabled={!isHomeWorkSelected}
                    onClick={() => {
                      setSelectedHomeWorkId(idx.toString());
                    }}
                    style={{ border: isHomeWorkSelected ? '2px solid black' : '2px solid #F4F4F4' }}
                  >
                    <div className="NewQuestionnaryComponent__form-card__container">
                      {data.type === EFormItemType.QUESTION ? (
                        <>
                          <div className="NewQuestionnaryComponent__form-card__container-header">
                            <FormItem
                              name={[idx, 'question']}
                              className="NewQuestionnaryComponent__form-card__container-header-question"
                              rules={[{ validator: validateHomeworkQuestion }]}
                            >
                              <Editor
                                modules={QUILL_QUESTION_MODULES}
                                formats={QUILL_QUESTION_FORMATS}
                                isShowToolbar={isHomeWorkSelected}
                                placeholder="Question"
                              />
                            </FormItem>

                            <FormItem
                              name={[idx, 'questionType']}
                              className="NewQuestionnaryComponent__form-card__container-header-questionType"
                              rules={[{ required: true, message: 'Please enter the question type' }]}
                              initialValue={ANSWER_OPTIONS[1].value}
                            >
                              <Select data={ANSWER_OPTIONS} />
                            </FormItem>
                          </div>

                          <Form.List name={[idx, 'options']}>
                            {(options, { add, remove }) => {
                              const questionType = form.getFieldValue(['items', item.name]).questionType;
                              return (
                                <div>
                                  {questionType === EAnswerOption.SHORT_ANSWER ? (
                                    <FormItem>
                                      <Input placeholder="Short answer" disabled />
                                    </FormItem>
                                  ) : (
                                    options.map((option, index) => (
                                      <div key={option.key}>
                                        <FormItem
                                          name={[index, 'value']}
                                          rules={[{ required: true, message: 'Please enter the answer' }]}
                                        >
                                          <Input
                                            prefix={
                                              questionType === EAnswerOption.SINGLE ? (
                                                <RadioButtonIcon width={14} height={14} />
                                              ) : (
                                                <BorderOutlined />
                                              )
                                            }
                                            suffix={
                                              options.length > 1 ? (
                                                <MinusCircleOutlined onClick={() => remove(option.name)} />
                                              ) : null
                                            }
                                            placeholder={`Option ${index + 1}`}
                                          />
                                        </FormItem>
                                      </div>
                                    ))
                                  )}
                                  <FormItem hidden={questionType === EAnswerOption.SHORT_ANSWER}>
                                    <Button type="link" onClick={() => add({ key: uuidv4() })}>
                                      <PlusOutlined /> New option
                                    </Button>
                                  </FormItem>
                                </div>
                              );
                            }}
                          </Form.List>
                        </>
                      ) : data.type === EFormItemType.FORM_HEADER ? (
                        <>
                          <FormItem name={[idx, 'title']} rules={[{ validator: validateHomeworkTitle }]}>
                            <Editor
                              modules={QUILL_TITLE_MODULES}
                              formats={QUILL_TITLE_FORMATS}
                              isShowToolbar={isHomeWorkSelected}
                              placeholder="Title"
                            />
                          </FormItem>
                          <FormItem name={[idx, 'description']} rules={[{ validator: validateHomeworkDescription }]}>
                            <Editor
                              modules={QUILL_DESCRIPTION_MODULES}
                              formats={QUILL_DESCRIPTION_FORMATS}
                              isShowToolbar={isHomeWorkSelected}
                              placeholder="Description"
                            />
                          </FormItem>
                        </>
                      ) : (
                        <>
                          <FormItem
                            name={[idx, 'photoTitle']}
                            rules={[{ required: true, message: 'Please enter the photo title' }]}
                          >
                            <Input placeholder="Title" />
                          </FormItem>
                          <FormItem
                            name={[idx, 'photo']}
                            // rules={[{ required: true, message: 'Please choose a photo' }]}
                          >
                            <Upload
                              listType="picture"
                              beforeUpload={(file) => handleBeforeUploadFile(file, idx.toString())}
                              onRemove={() => handleRemoveFile(idx, data.homeworkId)}
                              onChange={(info) => handleChangeFile(info, idx)}
                              maxCount={1}
                              showUploadList={false}
                              accept={Object.values(EHomeworkImageAcceptedFileType)
                                .map((type) => `.${type}`)
                                .join(',')}
                            >
                              <Image
                                src={previewUrls[idx] || data?.photoUri || BlankPictureImg}
                                preview={false}
                                width="100%"
                                style={{ borderRadius: 8 }}
                                height="auto"
                              />
                            </Upload>
                          </FormItem>
                          <BaseText type="caption" textAlign="center">
                            Maximum file size: 5MB. Allowed file types: JPG, PNG, JPEG.
                          </BaseText>
                        </>
                      )}
                    </div>

                    {isHomeWorkSelected && idx !== 0 ? (
                      <>
                        <Divider />
                        <div className="NewQuestionnaryComponent__form-card__footer">
                          <div className="NewQuestionnaryComponent__form-card__footer-function">
                            <Button
                              size="small"
                              onClick={() => {
                                const newHomeworkId = uuidv4();
                                const currentItem = form.getFieldValue(['items', item.name]);
                                add({ ...currentItem, homeworkId: isEdit ? data.homeworkId : newHomeworkId });

                                if (currentItem.type === EFormItemType.PHOTO) {
                                  const newPreviewUrls = previewUrls;
                                  newPreviewUrls[newHomeworkId] = previewUrls[currentItem.homeworkId];

                                  setPreviewUrls(newPreviewUrls);
                                }
                              }}
                            >
                              Copy
                            </Button>
                            <Button
                              type="primary"
                              danger
                              size="small"
                              onClick={() => {
                                remove(item.name);
                                handleRemoveFile(idx);
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                          <Divider type="vertical" />
                          <div className="NewQuestionnaryComponent__form-card__footer-required">
                            Obligatory
                            <FormItem name={[idx, 'required']} valuePropName="checked">
                              <Switch />
                            </FormItem>
                          </div>
                        </div>
                      </>
                    ) : null}
                  </div>
                );
              })}
            </div>

            <div className="NewQuestionnaryComponent__controller">
              <Tooltip placement="left" title="Add more question">
                <FormItem>
                  <PlusCircleIcon
                    width={24}
                    height={24}
                    onClick={() =>
                      add({
                        homeworkId: isEdit ? homeworkIdEdit : uuidv4(),
                        required: false,
                        type: EFormItemType.QUESTION,
                      })
                    }
                  />
                </FormItem>
              </Tooltip>
              <Tooltip placement="left" title="Add more title and description">
                <FormItem>
                  <TitleDescriptionIcon
                    width={24}
                    height={24}
                    onClick={() =>
                      add({
                        homeworkId: isEdit ? homeworkIdEdit : uuidv4(),
                        required: false,
                        type: EFormItemType.FORM_HEADER,
                      })
                    }
                  />
                </FormItem>
              </Tooltip>
              <Tooltip placement="left" title="Add more photo">
                <FormItem>
                  <AddGalerryIcon
                    width={24}
                    height={24}
                    onClick={() =>
                      add({
                        homeworkId: isEdit ? homeworkIdEdit : uuidv4(),
                        required: false,
                        type: EFormItemType.PHOTO,
                      })
                    }
                  />
                </FormItem>
              </Tooltip>
            </div>
          </div>
        );
      }}
    </Form.List>
  );
};

export default NewQuestionnaryComponent;
