export enum EHomeworkActions {
  GET_HOMEWORK_TOPICS = 'GET_HOMEWORK_TOPICS',
  CREATE_HOMEWORK_TOPICS = 'CREATE_HOMEWORK_TOPICS',
  GET_HOMEWORK_LIST = 'GET_HOMEWORK_LIST',
  CREATE_HOMEWORK = 'CREATE_HOMEWORK',
  UPLOAD_HOMEWORK_PHOTO = 'UPLOAD_HOMEWORK_PHOTO',
  DELETE_HOMEWORK = 'DELETE_HOMEWORK',
  GET_HOMEWORK_BY_ID = 'GET_HOMEWORK_BY_ID',
  UPDATE_HOMEWORK_BY_ID = 'UPDATE_HOMEWORK_BY_ID',
  EDIT_HOMEWORK_TOPIC = 'EDIT_HOMEWORK_TOPIC',
  DELETE_HOMEWORK_TOPIC = 'DELETE_HOMEWORK_TOPIC',
}
