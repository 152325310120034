import { List } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';

import '../Homework.scss';
import { SearchIcon } from 'src/assets/icons';
import Input from 'src/components/input';
import { EHomeworkStatus, EHomeworkType, ESortByHomeworkParam, ESortType } from 'src/variables/enum-variables';
import { useAppDispatch } from 'src/stores';
import { deleteHomework, getHomeworkList } from 'src/stores/homework';
import { ListLoadingSpinner } from 'src/components/spinner';
import { HomeworkTask } from 'src/components/homework-task';
import { TGetHomeworkListResponse, THomework } from 'src/interfaces/homework-interface';
import useDebounce from 'src/hooks/useDebounce';
import { RoutePaths } from 'src/routes/routes-constants';

const Questionnaires = () => {
  const { topicId } = useParams() as { topicId: string };
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [listHomework, setListHomework] = useState<THomework[]>([]);
  const [query, setQuery] = useState<string>('');
  const queryDebounce = useDebounce(query, 1000);
  const [loadMore, setLoadMore] = useState({
    hasMore: true,
    page: 1,
  });
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const getListHomework = async (page: number) => {
    setIsLoading(true);
    const res = await dispatch(
      getHomeworkList({
        page: page,
        size: 20,
        title: query,
        type: EHomeworkType.QUESTIONNAIRE,
        homeworkTopicId: topicId,
        sortType: ESortType.ASC,
        sortBy: ESortByHomeworkParam.TITLE,
        status: EHomeworkStatus.ACTIVE,
      }),
    );

    const data = res.payload as TGetHomeworkListResponse;

    if (data.currentPage !== 1) {
      setListHomework([...listHomework, ...data.data]);
    } else {
      setListHomework(data.data);
    }

    if (data.currentPage >= data.totalPage) {
      setLoadMore({
        ...loadMore,
        hasMore: false,
      });
    }

    setIsLoading(false);
  };

  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };

  const onLoadMore = () => {
    if (isLoading) return;
    setLoadMore({
      ...loadMore,
      page: loadMore.page + 1,
    });
  };

  useEffect(() => {
    setLoadMore({
      hasMore: true,
      page: 1,
    });
  }, [topicId, queryDebounce]);

  useEffect(() => {
    if (loadMore.hasMore) getListHomework(loadMore.page);
  }, [loadMore]);

  const handleDelete = async (id: string) => {
    const res = await dispatch(deleteHomework(id));
    if (res.meta.requestStatus === 'fulfilled') {
      setLoadMore({
        hasMore: true,
        page: 1,
      });
    }
  };

  return (
    <div className="Homework__content">
      <Input
        placeholder="Search"
        prefix={<SearchIcon />}
        className="Homework__content-search"
        value={query}
        onChange={onSearch}
      />
      <div className="Homework__content-list has-border" id="scrollable-div">
        <InfiniteScroll
          dataLength={listHomework.length}
          hasMore={loadMore.hasMore}
          loader={
            <center>
              <ListLoadingSpinner />
            </center>
          }
          next={onLoadMore}
          scrollableTarget="scrollable-div"
        >
          <List
            dataSource={listHomework}
            renderItem={(item) => (
              <HomeworkTask
                text={item.title}
                type={EHomeworkType.QUESTIONNAIRE}
                hasDeleteIcon={!['DASS-21', 'DASS-42'].includes(item.title)}
                hasEditIcon={!['DASS-21', 'DASS-42'].includes(item.title)}
                onDelete={() => {
                  handleDelete(item.id);
                }}
                onEdit={() => navigate(`${RoutePaths.HOMEWORK_ADD_NEW}/${item.id}`)}
              />
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Questionnaires;
