export enum EClientDetailTabKey {
  GENERAL_INFORMATION = 'GENERAL_INFORMATION',
  DETAILED_STATISTIC = 'DETAILED_STATISTIC',
  SUBSCRIPTION_INFO = 'SUBSCRIPTION_INFO',
  FILES = 'FILES',
}

export const CLIENT_DETAIL_TAB = [
  {
    key: EClientDetailTabKey.GENERAL_INFORMATION,
    label: 'General information',
  },
  {
    key: EClientDetailTabKey.DETAILED_STATISTIC,
    label: 'Detailed statistics',
  },
  {
    key: EClientDetailTabKey.SUBSCRIPTION_INFO,
    label: 'Subscription info',
  },
];
