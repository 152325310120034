import { ConfigProvider, Button as AntButton, ButtonProps } from 'antd';

import './Button.scss';

interface IButtonProps extends ButtonProps {
  children: React.ReactNode;
}

const Button = ({ children, className, ...rest }: IButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorTextDisabled: '#ffffff',
          colorBgContainerDisabled: '#E1E6EF',
        },
      }}
    >
      <AntButton className={`Button ${className ?? ''}`} {...rest}>
        {children}
      </AntButton>
    </ConfigProvider>
  );
};

export default Button;
