import { List } from 'antd';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BaseText } from 'src/components/text';

import './SubscriptionChangelogList.scss';
import { TClinicAndSoloSubscriptionChangeLog } from 'src/interfaces/clients-interface';

const SCROLLABLE_TARGET = 'SubscriptionChangelogList__scrollable-div';

interface ISubscriptionChangelogListProps {
  data: TClinicAndSoloSubscriptionChangeLog[];
  hasMore: boolean;
  onLoadMore: () => void;
}

const SubscriptionChangelogList = ({ data, hasMore, onLoadMore }: ISubscriptionChangelogListProps) => {
  return (
    <div className="SubscriptionChangelogList">
      <div className="SubscriptionChangelogList__row heading">
        <BaseText>Subscription</BaseText>
        <BaseText>Price</BaseText>
        <BaseText>Practitioner license</BaseText>
        <BaseText>Date</BaseText>
      </div>
      <div className="SubscriptionChangelogList__list-wrapper" id={SCROLLABLE_TARGET}>
        <InfiniteScroll
          dataLength={data.length}
          hasMore={hasMore}
          loader={<></>}
          next={onLoadMore}
          scrollableTarget={SCROLLABLE_TARGET}
        >
          <List
            dataSource={data}
            renderItem={(item) => (
              <div className="SubscriptionChangelogList__row">
                <BaseText>{item.packageName}</BaseText>
                <BaseText>${item.price}</BaseText>
                <BaseText>{item.practitionerLicense}</BaseText>
                <BaseText>{item.startDate && dayjs(item.startDate).format('MMMM DD, YYYY')}</BaseText>
              </div>
            )}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SubscriptionChangelogList;
