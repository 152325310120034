import { List } from 'antd';
import dayjs from 'dayjs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BaseText } from 'src/components/text';

import './SubscriptionPaymentLogList.scss';
import { DownloadInvoiceIcon } from 'src/assets/icons';
import { TClinicAndSoloSubscriptionPaymentLog } from 'src/interfaces/clients-interface';
import { paymentLogStatus } from 'src/utils/common-utils';
import { STATUS_PAYMENT_LABEL } from 'src/variables/enum-variables';

interface ISubscriptionChangelogListProps {
  data: TClinicAndSoloSubscriptionPaymentLog[];
  hasMore: boolean;
  onLoadMore: () => void;
  onDownloadInvoice: (invoiceId: string) => void;
}

const SCROLLABLE_TARGET = 'SubscriptionPaymentLogList__scrollable-div';

const SubscriptionPaymentLogList = ({
  data,
  hasMore,
  onLoadMore,
  onDownloadInvoice,
}: ISubscriptionChangelogListProps) => {
  return (
    <div className="SubscriptionPaymentLogList">
      <div className="SubscriptionPaymentLogList__row header">
        <BaseText>Date</BaseText>
        <BaseText>Payments</BaseText>
        <BaseText>Status</BaseText>
        <div></div>
      </div>
      <div className="SubscriptionPaymentLogList__list-wrapper" id={SCROLLABLE_TARGET}>
        <InfiniteScroll
          dataLength={data.length}
          hasMore={hasMore}
          loader={<></>}
          next={onLoadMore}
          scrollableTarget={SCROLLABLE_TARGET}
        >
          <List
            dataSource={data}
            renderItem={(item) => {
              const status = paymentLogStatus(item.status);
              return (
                <div className="SubscriptionPaymentLogList__row">
                  <BaseText>{item.createdAt && dayjs(item.createdAt).format('MMMM DD, YYYY')}</BaseText>
                  <BaseText>{`${
                    Number(item.price) >= 0 ? `$${Number(item.price)}` : `-$${-1 * Number(item.price)}`
                  }`}</BaseText>
                  <BaseText color={status === STATUS_PAYMENT_LABEL.OPEN ? 'error' : 'success'}>{status}</BaseText>
                  <div onClick={() => onDownloadInvoice(item.id)}>
                    <DownloadInvoiceIcon />
                  </div>
                </div>
              );
            }}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default SubscriptionPaymentLogList;
