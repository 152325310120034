import CommonContent from 'src/components/containers/CommonContent';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { BaseText } from 'src/components/text';
import { ChevronDownIcon } from 'src/assets/icons';
import { useEffect, useState } from 'react';
import { EComponentName, ETimePeriodTracker } from 'src/variables/enum-variables';
import { getFilterByTitle, getFilterTitle, mapRevenue, sortValue } from 'src/utils/common-utils';
import TimePeriod from 'src/components/TimePeriod/TimePeriod';
import './RevenueReport.scss';
import RevenueDetailList from './RevenueDetailList';
import { TFilter, TRevenueDetailResponse } from 'src/interfaces/dashboard-interface';
import { useAppDispatch } from 'src/stores';
import { getRevenueReport } from 'src/stores/dashboard';
import { showErrorToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import dayjs from 'dayjs';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  type: 'bar',
  responsive: true,
  plugins: {
    legend: {
      position: 'top' as const,
      display: false,
    },
  },
  scales: {
    x: {
      grid: {
        drawBorder: true, // <-- this removes y-axis line
        lineWidth: 0,
      },
    },
    y: {
      grid: {
        drawBorder: true, // <-- this removes y-axis line
        lineWidth: 0,
      },
    },
  },
};

const RevenueReport = () => {
  const dispatch = useAppDispatch();
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<TFilter>(getFilterByTitle(ETimePeriodTracker.LAST_12_MONTHS));
  const [revenueReport, setRevenueReport] = useState<TRevenueDetailResponse[]>([]);
  const [labels, setLabels] = useState<string[]>([]);
  const [revenue, setRevenue] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const toggleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const onFilter = (value: TFilter) => {
    setFilter(value);
    setIsShowFilter(false);
  };

  const getRevenue = async () => {
    setIsLoading(true);
    try {
      const result = await dispatch(getRevenueReport(filter));
      const data = result.payload as TRevenueDetailResponse;

      if (data) {
        const newData = sortValue(data);
        const withNestedKeys = Object.entries(newData).map((entry) => {
          return { [entry[0]]: entry[1] };
        });
        setRevenueReport([...withNestedKeys]);
        setLabels(Object.keys(newData));
        setRevenue(mapRevenue([...withNestedKeys]));
      }
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getRevenue();
  }, [filter]);

  const data = {
    labels,
    datasets: [
      {
        label: '',
        data: revenue,
        backgroundColor: 'rgba(255, 99, 132, 0.5)',
      },
    ],
  };
  return (
    <CommonContent
      title={
        <div className="RevenueReport__title">
          <BaseText type="headline" className="RevenueReport__title-headline">
            Revenue report
          </BaseText>
          <BaseText type="default" className="RevenueReport__title-time">
            {`${dayjs(filter.startDate).format('MMMM D, YYYY')} - ${dayjs(filter.endDate).format('MMMM D, YYYY')}`}
          </BaseText>
        </div>
      }
      className="RevenueReport"
    >
      <div className="RevenueReport__container">
        <div className="RevenueReport__chart">
          <BaseText type="caption" className="RevenueReport__chart-title">
            $
          </BaseText>
          <Bar options={options} data={data} />
        </div>
        <div className="RevenueReport__right">
          <div className="RevenueReport__right-filter">
            <div className="RevenueReport__filter" onClick={toggleShowFilter}>
              <BaseText type="caption">{getFilterTitle(filter.type)}</BaseText>
              <ChevronDownIcon />
            </div>
          </div>
          <div>
            <RevenueDetailList
              data={revenueReport}
              hasMore={true}
              onLoadMore={() => console.log('')}
              isLoading={isLoading}
            />
          </div>
        </div>
      </div>
      {isShowFilter ? (
        <TimePeriod
          isOpen={true}
          onClose={toggleShowFilter}
          value={filter}
          onSubmit={onFilter}
          componentName={EComponentName.REVENUE_REPORT}
        />
      ) : null}
    </CommonContent>
  );
};

export default RevenueReport;
