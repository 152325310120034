import { createSlice } from '@reduxjs/toolkit';

import * as asyncActions from './dashboard-action';
import { TSaleOverviewResponse } from 'src/interfaces/dashboard-interface';

type TDashboardState = {
  saleOverview: TSaleOverviewResponse | undefined;
};

const initialState: TDashboardState = {
  saleOverview: undefined,
};

export const clientsSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(asyncActions.getSaleOverview.fulfilled, (state, action) => {
      state.saleOverview = action.payload as TSaleOverviewResponse;
    });
  },
});

export default clientsSlice;
