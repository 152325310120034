import { Card } from 'antd';
import { BaseText } from 'src/components/text';

import './SubscriptionCard.scss';

interface ISubscriptionCardProps {
  packageName: string;
  packageAmount: number;
  packagePrice: number;
}

const SubscriptionCard = ({ packageAmount, packageName, packagePrice }: ISubscriptionCardProps) => {
  return (
    <div className="SubscriptionCard">
      <Card className="SubscriptionCard__wrapper active">
        <BaseText type="subHeading" className="SubscriptionCard__package-name">
          {packageName}
        </BaseText>
        <div className="SubscriptionCard__package-price">
          <BaseText type="xl-3">${packagePrice}</BaseText>
          <div>
            <div>
              <BaseText inline={false} type="headline" className="currency">
                AUD
              </BaseText>
              <BaseText type="body1">(plus GST) /month</BaseText>
            </div>
          </div>
        </div>
        <BaseText className="SubscriptionCard__license">
          {packageAmount} practitioner {packageAmount > 1 ? 'licenses' : 'license'}
        </BaseText>
      </Card>
    </div>
  );
};

export default SubscriptionCard;
