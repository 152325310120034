import * as homeworkActions from './homework-actions';
import * as homeworkConstants from './homework-constants';
import homeworkSlice from './homework-slice';

export const {
  getHomeworkTopics,
  createHomeworkTopics,
  getHomeworkList,
  createHomework,
  uploadHomeworkPhoto,
  deleteHomework,
  getHomeworkById,
  updateHomeworkById,
  updateHomeworkTopicAction,
  deleteHomeworkTopicAction,
} = homeworkActions;
export const { EHomeworkActions } = homeworkConstants;
export const {
  reducer: homeworkReducer,
  actions: { setActivityTopicList, setVideoTopicList, setWrittenTaskTopicList, setQuestionTopicList },
} = homeworkSlice;
