import { HomeworkDeleteIcon, HomeworkEditIcon } from 'src/assets/icons';
import { BaseText } from '../text';

import './HomeworkTask.scss';
import { EHomeworkType } from 'src/variables/enum-variables';
import ConfirmModal from '../popup/confirmModal/ConfirmModal';
import { useState } from 'react';

interface IHomeworkTaskProps {
  type: EHomeworkType.ACTIVITY | EHomeworkType.QUESTIONNAIRE | EHomeworkType.WRITTEN_TASK;
  text: string;
  hasEditIcon?: boolean;
  hasDeleteIcon?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

const HomeworkTask = ({ type, text, hasEditIcon, hasDeleteIcon, onDelete, onEdit }: IHomeworkTaskProps) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);

  return (
    <div className={`HomeworkTask ${type}`}>
      <BaseText type="body1">{text}</BaseText>
      <div className="HomeworkTask__icons">
        {hasEditIcon && <HomeworkEditIcon onClick={onEdit} />}
        {hasDeleteIcon && <HomeworkDeleteIcon onClick={() => setIsConfirmDelete(true)} />}
      </div>
      <ConfirmModal
        open={isConfirmDelete}
        onCancelButton={() => setIsConfirmDelete(false)}
        onsubmit={() => {
          setIsConfirmDelete(false);
          if (onDelete) onDelete();
        }}
        titleModal="Remove this task?"
        txtBtnCancel="No"
        txtBtnConfirm="Yes"
      >
        <div className="LogoutModal">
          <BaseText type="body1">Do you want remove this task?</BaseText>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default HomeworkTask;
