import { TCommonGetListResponse, TPagination } from 'src/interfaces/common-interface';
import { EGender } from './enum-variables';

export const DEFAULT_PAGE_SIZE = 10;

export const DEFAULT_PAGINATION: TPagination = {
  currentPage: 1,
  totalPage: 1,
  totalRecord: 1,
};

export const DEFAULT_GET_LIST_RESPONSE: TCommonGetListResponse<[]> = {
  ...DEFAULT_PAGINATION,
  data: [],
};

export const GENDER_OPTIONS = [
  { label: 'Female', value: EGender.FEMALE },
  { label: 'Male', value: EGender.MALE },
  { label: 'Other', value: EGender.OTHER },
  { label: 'Prefer not to say', value: EGender.NOT_TO_SAY },
];
