import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { Radio, Modal, Space } from 'antd';

import './TimePeriod.scss';
import Button from 'src/components/button';
import DatePicker from 'src/components/date-picker';
import { EComponentName, ETimePeriodTracker } from 'src/variables/enum-variables';
import { BaseText } from '../text';
import { validateFilterDate } from 'src/utils/validate-utils';
import { getFilterByTitle } from 'src/utils/common-utils';
import { TFilter } from 'src/interfaces/dashboard-interface';

interface ITimePeriodProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (value: TFilter) => void;
  value: TFilter;
  componentName?: string;
}

const TimePeriod = ({ isOpen, value, onClose, onSubmit, componentName }: ITimePeriodProps) => {
  const [errorMsg, setErrorMsg] = useState<string>('');
  const [filter, setFilter] = useState<TFilter>({
    type: value.type,
    startDate: null,
    endDate: null,
  });

  const onChangeType = (type: string) => {
    const newFilter = getFilterByTitle(type);
    setFilter({
      ...filter,
      ...newFilter,
      type,
    });
  };

  const handleSubmit = () => {
    if (filter.type === ETimePeriodTracker.CUSTOM) {
      const isValid = validateFilterDate(filter.startDate, filter.endDate);
      if (typeof isValid === 'string') {
        setErrorMsg(isValid);
        return;
      }
    } else {
      const newFilter = getFilterByTitle(filter.type);
      setFilter({
        ...filter,
        ...newFilter,
      });
    }

    onSubmit(filter);
  };

  useEffect(() => {
    setFilter(value);
  }, []);

  return (
    <Modal
      open={isOpen}
      className="TimePeriodModal"
      onCancel={onClose}
      centered
      closable={false}
      footer={null}
      width={370}
    >
      <Space direction="vertical" className="TimePeriodModal__container">
        <BaseText type="title" className="TimePeriodModal__title">
          Time period
        </BaseText>
        {componentName === EComponentName.REVENUE_REPORT ? (
          <Radio
            onChange={() => onChangeType(ETimePeriodTracker.LAST_6_MONTHS)}
            checked={filter.type === ETimePeriodTracker.LAST_6_MONTHS}
          >
            <BaseText type="body1">Last 6 months</BaseText>
          </Radio>
        ) : (
          <Radio
            onChange={() => onChangeType(ETimePeriodTracker.LAST_30_DAYS)}
            checked={filter.type === ETimePeriodTracker.LAST_30_DAYS}
          >
            <BaseText type="body1">Last 30 days</BaseText>
          </Radio>
        )}
        <Radio
          onChange={() => onChangeType(ETimePeriodTracker.LAST_12_MONTHS)}
          checked={filter.type === ETimePeriodTracker.LAST_12_MONTHS}
        >
          <BaseText type="body1">Last 12 months</BaseText>
        </Radio>
        <Radio
          onChange={() => onChangeType(ETimePeriodTracker.CUSTOM)}
          checked={filter.type === ETimePeriodTracker.CUSTOM}
        >
          <BaseText type="body1">Custom</BaseText>
        </Radio>
        {filter.type === ETimePeriodTracker.CUSTOM ? (
          <div>
            <div className="TimePeriodModal__date">
              <div className="TimePeriodModal__date--item">
                <BaseText type="caption" className="TimePeriodModal__date--item-label">
                  From
                </BaseText>
                <DatePicker
                  placeholder="-- / -- / ----"
                  disabledDate={(date) => date.isAfter(new Date())}
                  value={filter.startDate ? dayjs(filter.startDate) : null}
                  onChange={(time) => setFilter({ ...filter, startDate: time })}
                />
              </div>
              <div className="TimePeriodModal__date--item">
                <BaseText type="caption" className="TimePeriodModal__date--item-label">
                  To
                </BaseText>
                <DatePicker
                  placeholder="-- / -- / ----"
                  disabledDate={(date) => date.isAfter(new Date())}
                  value={filter.endDate ? dayjs(filter.endDate) : null}
                  onChange={(time) => setFilter({ ...filter, endDate: time })}
                />
              </div>
            </div>
            {errorMsg && (
              <BaseText type="caption" color="error" textAlign="center" className="RegisterPage__errorMsg">
                {errorMsg}
              </BaseText>
            )}
          </div>
        ) : null}
        <Button type="primary" block onClick={handleSubmit} className="TimePeriodModal__submit">
          Ok
        </Button>
      </Space>
    </Modal>
  );
};

export default TimePeriod;
