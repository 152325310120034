import { createSlice } from '@reduxjs/toolkit';
import { TGetListQuotesResponse } from 'src/interfaces/quotes-interface';
import { DEFAULT_GET_LIST_RESPONSE } from 'src/variables/common';
import * as asyncActions from './quotes-actions';

type TQuotesState = {
  quotesList: TGetListQuotesResponse;
};

const initialState: TQuotesState = {
  quotesList: DEFAULT_GET_LIST_RESPONSE,
};

export const quotesSlice = createSlice({
  name: 'quotes',
  initialState: initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(asyncActions.getListQuotes.fulfilled, (state, action) => {
      state.quotesList = action.payload as TGetListQuotesResponse;
    });
  },
});

export default quotesSlice;
