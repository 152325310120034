import { Image } from 'antd';
import { HeartCloudImg } from 'src/assets/images';

import './HomeworkVideo.scss';
import { BaseText } from '../text';
import { getThumbnailYoutube } from 'src/utils/file-utils';
import { HomeworkDeleteIcon, HomeworkEditIcon } from 'src/assets/icons';
import ConfirmModal from '../popup/confirmModal/ConfirmModal';
import { useState } from 'react';

interface IHomeworkVideoProps {
  title: string;
  description: string;
  video?: string | null;
  hasEditIcon?: boolean;
  hasDeleteIcon?: boolean;
  onEdit?: () => void;
  onDelete?: () => void;
}

const HomeworkVideo = ({
  title,
  description,
  video,
  hasEditIcon,
  hasDeleteIcon,
  onDelete,
  onEdit,
}: IHomeworkVideoProps) => {
  const [isConfirmDelete, setIsConfirmDelete] = useState<boolean>(false);

  return (
    <div className="HomeworkVideo">
      <Image
        src={video ? getThumbnailYoutube(video) : HeartCloudImg}
        preview={false}
        className="HomeworkVideo__thumbnail"
        height={110}
        width="100%"
      />
      <div className="HomeworkVideo__content">
        <BaseText type="body1" className="HomeworkVideo__content-title">
          {title}
        </BaseText>
        <BaseText type="caption" className="HomeworkVideo__content-description">
          {description}
        </BaseText>
      </div>
      <div className="HomeworkVideo__icons">
        {hasEditIcon && <HomeworkEditIcon className="pointer" onClick={onEdit} />}
        {hasDeleteIcon && <HomeworkDeleteIcon className="pointer" onClick={() => setIsConfirmDelete(true)} />}
      </div>
      <ConfirmModal
        open={isConfirmDelete}
        onCancelButton={() => setIsConfirmDelete(false)}
        onsubmit={() => {
          setIsConfirmDelete(false);
          if (onDelete) onDelete();
        }}
        titleModal="Delete video"
        txtBtnCancel="No"
        txtBtnConfirm="Yes"
      >
        <div className="LogoutModal">
          <BaseText type="body1">Do you want to delete this video?</BaseText>
        </div>
      </ConfirmModal>
    </div>
  );
};

export default HomeworkVideo;
