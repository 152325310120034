import { Input as AntInput, InputProps as AntInputProps, InputRef } from 'antd';

import './Input.scss';

interface InputProps extends AntInputProps {
  ref?: React.Ref<InputRef>;
}

const Input = ({ className, type, ...rest }: InputProps) => {
  const inputClassName = `Input wrapper ${className ?? ''} `;

  if (type === 'password') return <AntInput.Password className={inputClassName} {...rest} />;

  return <AntInput className={inputClassName} type={type || 'text'} {...rest} />;
};

export default Input;
