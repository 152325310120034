import { List } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { BaseText } from 'src/components/text';

import './RevenueDetailList.scss';
import { TRevenueDetailResponse } from 'src/interfaces/dashboard-interface';

interface IRevenueDetailListProps {
  data: TRevenueDetailResponse[];
  hasMore: boolean;
  onLoadMore: () => void;
  isLoading: boolean;
}

const SCROLLABLE_TARGET = 'RevenueDetailList__scrollable-div';

const RevenueDetailList = ({ data, hasMore, isLoading, onLoadMore }: IRevenueDetailListProps) => {
  return (
    <div className="RevenueDetailList">
      <div className="RevenueDetailList__row header">
        <BaseText className="content-row">Date</BaseText>
        <BaseText className="content-row">Transactions</BaseText>
        <BaseText className="content-row">Payments</BaseText>
      </div>
      <div className="RevenueDetailList__list-wrapper" id={SCROLLABLE_TARGET}>
        <InfiniteScroll
          dataLength={data.length}
          hasMore={hasMore}
          loader={<></>}
          next={onLoadMore}
          scrollableTarget={SCROLLABLE_TARGET}
        >
          <List
            loading={isLoading}
            dataSource={data}
            renderItem={(item) => {
              const keys = Object.keys(item);
              const { numberTransaction, payment } = item[keys[0]];
              const month = keys[0];

              return (
                <div className="RevenueDetailList__row content">
                  <BaseText className="content-item">{month}</BaseText>
                  <BaseText className="content-item">{numberTransaction}</BaseText>
                  <BaseText className="content-item">{payment ? payment.toFixed(2) : payment}</BaseText>
                </div>
              );
            }}
          />
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default RevenueDetailList;
