import { Form, Typography } from 'antd';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import HeartCloudImg from 'src/assets/images/heart-cloud.png';
import Button from 'src/components/button';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { BaseText } from 'src/components/text';
import { ImgContainer } from 'src/containers/auth';
import { RoutePaths } from 'src/routes/routes-constants';
import { TRootState, useAppDispatch } from 'src/stores';
import { EAuthActions, sendOtpLoginAction } from 'src/stores/auth';
import { EReft } from 'src/variables/enum-variables';
import { emailRules } from 'src/variables/rules-form';
import './SignInPage.scss';

const LoginPage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [errorMsg, setErrorMsg] = useState<string>('');
  const { sendingOTP } = useSelector((state: TRootState) => ({
    sendingOTP: state.loading[EAuthActions.SEND_OTP_LOGIN],
  }));

  const onFinish = async (values: { email: string; password: string; admin: true }) => {
    dispatch(
      sendOtpLoginAction({
        email: values.email.toLowerCase(),
        password: values.password,
        onSuccess: () => {
          navigate(RoutePaths.VERIFY_OTP, {
            state: {
              email: values.email.toLowerCase(),
              password: values.password,
            },
          });
        },
        onError: (error) => {
          const { errorType, id } = error.data;
          if (errorType === 'notVerifyAccount') {
            navigate(`${RoutePaths.CONFIRM_EMAIL}?id=${id}&ref=${EReft.SIGN_IN}`, {
              state: {
                email: values.email,
              },
            });
            return;
          }
          setErrorMsg('Invalid email or password, please try again');
        },
      }),
    );
  };

  const onForgotPassword = () => {
    navigate(RoutePaths.FORGOT_PASSWORD);
  };

  return (
    <ImgContainer bgImgSrc={HeartCloudImg} className="LoginPage">
      <Form name="LoginForm" onFinish={onFinish} autoComplete="off" layout="vertical">
        <BaseText type="display1" textAlign="center" className="LoginPage__container-heading">
          Sign in
        </BaseText>
        <FormItem label="Email" name="email" rules={emailRules}>
          <Input />
        </FormItem>
        <FormItem
          leftLabel="Password"
          rightLabel={
            <Typography.Link
              style={{ fontSize: 12 }}
              onClick={(e) => {
                e.stopPropagation();
                onForgotPassword();
              }}
            >
              Forgot password ?
            </Typography.Link>
          }
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input type="password" />
        </FormItem>
        <FormItem>
          {errorMsg && (
            <BaseText type="caption" color="error" textAlign="center" className="LoginPage__errorMsg">
              {errorMsg}
            </BaseText>
          )}
          <Button type="primary" htmlType="submit" size="large" block loading={sendingOTP}>
            Log in
          </Button>
        </FormItem>
      </Form>
    </ImgContainer>
  );
};

export default LoginPage;
