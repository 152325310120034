export enum EUserType {
  CLINIC_OWNER = 'CLINIC',
  SOLO_PRACTITIONER = 'SOLO',
  OWNER = 'OWNER',
  PRACTITIONER = 'PRACTITIONER',
  ADMIN = 'ADMIN',
}

export enum EUserTypeDisplay {
  CLINIC_OWNER = 'clinic-owner',
  SOLO_PRACTITIONER = 'solo-practitioner',
  PRACTITIONER = 'invite-practitioner',
}

export enum EReft {
  SIGN_IN = 'login',
}

export enum EHomeworkType {
  ACTIVITY = 'Activity',
  QUESTIONNAIRE = 'Questionnaire',
  WRITTEN_TASK = 'WrittenTask',
  VIDEO = 'Video',
}

export enum EHomeworkStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum EHomework {
  ACTIVITIES = 'Activities',
  QUESTIONNAIRES = 'Questionnaires',
  WRITTEN_TASKS = 'Journals',
  VIDEOS = 'Videos',
}

export enum EHomeworkActivityTopic {
  MINDFULNESS_ACTIVITIES = 'Mindfulness Activities',
  ACTIVITIES_FOR_CHILDREN = 'Activities for children',
  BEHAVIOURAL_ACTIVITIES = 'Behavioural activities',
  RELAXATION_ACTIVITIES = 'Relaxation activities',
  SLEEP_ACTIVITIES = 'Sleep activities',
  POSITIVE_PSYCHOLOGY = 'Positive psychology',
  TITLE_HEADING_SAMPLE = 'Title heading sample',
}

export enum EHomeworkVideoTopic {
  OCD = 'O.C.D',
  STRESS = 'Stress',
  ANXIETY = 'Anxiety',
  SLEEPING_DISORDER = 'Sleeping Disorder',
}

export enum EDateTimeSuffix {
  AM = 'AM',
  PM = 'PM',
}

export enum ESortType {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum EHomeworkTopicSortBy {
  NAME = 'name',
}

export enum ESortByHomeworkParam {
  TITLE = 'title',
}

export enum EHomeworkImageAcceptedFileType {
  JPG = 'jpg',
  JPEG = 'jpeg',
  PNG = 'png',
}

export enum EPaymentStatus {
  CANCELED = 'CANCELED',
  ACTIVE = 'ACTIVE',
  TRIALING = 'TRIALING',
}

export enum EProfileStatus {
  PENDING = 'PENDING',
  INACTIVE = 'INACTIVE',
  ACTIVE = 'ACTIVE',
  DISCHARGED = 'DISCHARGED',
}

export enum EGender {
  FEMALE = 'female',
  MALE = 'male',
  OTHER = 'other',
  NOT_TO_SAY = 'not',
}

export enum ESubscriptionType {
  SOLO = 'SOLO',
  CLINIC = 'CLINIC',
}

export enum EPsychoeducationType {
  ARTICLE = 'ARTICLE',
  VIDEO = 'VIDEO',
}

export enum ETimePeriodTracker {
  LAST_30_DAYS = 'last_30_days',
  LAST_6_MONTHS = 'last_6_months',
  LAST_12_MONTHS = 'last_12_months',
  CUSTOM = 'custom',
}

export enum EComponentName {
  REVENUE_REPORT = 'RevenueReport',
}

export enum TStatusPayment {
  OPEN = 'OPEN',
  PAID = 'PAID',
}

export const STATUS_PAYMENT_LABEL = {
  [TStatusPayment.OPEN]: 'Failed',
  [TStatusPayment.PAID]: 'Paid',
};
