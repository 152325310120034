import { TimePicker as AntTimePicker, TimePickerProps } from 'antd';
import { ArrowIcon } from 'src/assets/icons';
import './TimePicker.scss';

interface ITimePickerProps extends TimePickerProps {
  onChange?: () => void;
}

const TimePicker = ({ onChange, className, ...rest }: ITimePickerProps) => (
  <AntTimePicker
    onChange={onChange}
    className={`TimePicker ant-picker ${className ?? ''}`}
    suffixIcon={<ArrowIcon />}
    showNow={false}
    {...rest}
  />
);

export default TimePicker;
