import { ColumnsType } from 'antd/es/table';
import Table from 'src/components/table';
import { FileIcon, HomeworkDeleteIcon, HomeworkEditIcon, VideoIcon } from 'src/assets/icons';
import { BaseText } from 'src/components/text';
import { TPagination } from 'src/interfaces/common-interface';
import './PsychoeducationTable.scss';
import { EPsychoeducationType } from 'src/variables/enum-variables';
import { DEFAULT_CLIENTS_PAGE_SIZE } from 'src/pages/clients/clients-constants';
import { IPsychoeducation } from 'src/interfaces/psychoeducation-interface';
import dayjs from 'dayjs';

interface IProps extends TPagination {
  data: IPsychoeducation[];
  loading: boolean;
  onChangePage: (page: number) => void;
  onClickRow: (rowData: IPsychoeducation) => void;
  onDelete: (item: IPsychoeducation) => void;
  onEdit: (item: IPsychoeducation) => void;
}

const PsychoeducationTable = ({
  data,
  loading,
  currentPage,
  totalPage,
  totalRecord,
  onChangePage,
  onClickRow,
  onDelete,
  onEdit,
}: IProps) => {
  const pagination: TPagination = { currentPage, totalPage, totalRecord, pageSize: DEFAULT_CLIENTS_PAGE_SIZE };

  const column: ColumnsType<IPsychoeducation> = [
    {
      title: '',
      key: 'icon',
      render: (_, data) => (data.type === EPsychoeducationType.ARTICLE ? <FileIcon /> : <VideoIcon />),
      width: '40px',
    },
    {
      title: 'Name',
      key: 'title',
      render: (_, data) => (
        <div onClick={() => onClickRow(data)} className="PsychoeducationTable__row-title">
          <BaseText>{data.title}</BaseText>
        </div>
      ),
      width: '65%',
    },
    {
      title: 'Modified',
      key: 'updatedAt',
      render: (_, data) => (
        <div className="PsychoeducationTable__row-modified">
          {data.updatedAt && <BaseText>{dayjs(data.updatedAt).format('MMMM DD, YYYY')}</BaseText>}
          <div className="PsychoeducationTable__row-buttons">
            <HomeworkEditIcon onClick={() => onEdit(data)} />
            <HomeworkDeleteIcon onClick={() => onDelete(data)} />
          </div>
        </div>
      ),
    },
  ];

  return (
    <Table
      className="PsychoeducationTable"
      dataSource={data}
      columns={column}
      loading={loading}
      pagination={pagination}
      onChangePage={onChangePage}
      locale={{
        emptyText: 'No result found',
      }}
    />
  );
};

export default PsychoeducationTable;
