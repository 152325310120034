import dayjs from 'dayjs';
import StatusBox from 'src/components/status-box';
import { BaseText } from 'src/components/text';
import SubscriptionChangelogList from 'src/containers/clients/SubscriptionChangelogList';
import SubscriptionPaymentLogList from 'src/containers/clients/SubscriptionPaymentLogList';
import {
  EGetListClientsType,
  TClinicAndSoloGeneralInformation,
  TClinicAndSoloSubscriptionInfo,
  TGetClinicAndSoloSubscriptionChangeLogResponse,
  TGetClinicAndSoloSubscriptionPaymentLogResponse,
} from 'src/interfaces/clients-interface';
import SubscriptionCard from './SubscriptionCard';
import './SubscriptionInfo.scss';

interface ISubscriptionInfoProps {
  generalInformation: TClinicAndSoloGeneralInformation;
  subscriptionInfo: TClinicAndSoloSubscriptionInfo;
  subscriptionChangeLog?: TGetClinicAndSoloSubscriptionChangeLogResponse;
  subscriptionPaymentLog?: TGetClinicAndSoloSubscriptionPaymentLogResponse;
  onLoadMoreSubscriptionChangeLog: () => void;
  onLoadMoreSubscriptionPaymentLog: () => void;
  onDownloadInvoice: (invoiceId: string) => void;
}

const SubscriptionInfo = ({
  generalInformation,
  subscriptionInfo,
  subscriptionChangeLog,
  subscriptionPaymentLog,
  onLoadMoreSubscriptionChangeLog,
  onLoadMoreSubscriptionPaymentLog,
  onDownloadInvoice,
}: ISubscriptionInfoProps) => {
  return (
    <div className="SubscriptionInfo">
      <div className="SubscriptionInfo__top-content">
        <div className="SubscriptionInfo__current-subscription">
          <div className="SubscriptionInfo__current-subscription-heading">
            <BaseText type="title">Current subscription</BaseText>
            {subscriptionInfo?.status ? (
              <StatusBox status={subscriptionInfo.status} />
            ) : generalInformation.status ? (
              <StatusBox status={generalInformation.status} />
            ) : (
              <></>
            )}
          </div>
          {subscriptionInfo?.plan && (
            <SubscriptionCard
              packageName={subscriptionInfo?.plan.package.name}
              packageAmount={subscriptionInfo.plan.amount || 0}
              packagePrice={subscriptionInfo.plan.price || 0}
            />
          )}
          <BaseText className="SubscriptionInfo__current-subscription-registered">
            Registered ANTSA from {dayjs(subscriptionInfo.registeredAt).format('DD/MM/YYYY')}
          </BaseText>
        </div>
        <div className="SubscriptionInfo__activities">
          <BaseText type="title" className="SubscriptionInfo__activities-heading">
            Activities this month
          </BaseText>
          <BaseText>
            Next payment due:{' '}
            {subscriptionInfo.nextPaymentAt && dayjs(subscriptionInfo.nextPaymentAt).format('DD/MM/YYYY')}
          </BaseText>
          {generalInformation.type === EGetListClientsType.CLINIC && (
            <BaseText>Active users: {subscriptionInfo.totalPractitionerActive}</BaseText>
          )}
        </div>
      </div>
      <div className="SubscriptionInfo__logs">
        <div className="SubscriptionInfo__logs-subscription-changelog">
          <BaseText type="title" className="SubscriptionInfo__logs-title">
            Subscription changelog
          </BaseText>
          <SubscriptionChangelogList
            data={subscriptionChangeLog?.data || []}
            hasMore={
              subscriptionChangeLog ? subscriptionChangeLog.currentPage < subscriptionChangeLog.totalPage : false
            }
            onLoadMore={onLoadMoreSubscriptionChangeLog}
          />
        </div>
        <div className="SubscriptionInfo__logs-payment-log">
          <BaseText type="title" className="SubscriptionInfo__logs-title">
            Payment log
          </BaseText>
          <SubscriptionPaymentLogList
            data={subscriptionPaymentLog?.data || []}
            hasMore={subscriptionPaymentLog ? subscriptionPaymentLog.hasMore : false}
            onLoadMore={onLoadMoreSubscriptionPaymentLog}
            onDownloadInvoice={onDownloadInvoice}
          />
        </div>
      </div>
    </div>
  );
};

export default SubscriptionInfo;
