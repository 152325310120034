import { Form, Space } from 'antd';
import { useLocation } from 'react-router-dom';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import StatusBox from 'src/components/status-box';
import { BaseText } from 'src/components/text';
import { FormItem } from 'src/components/forms';
import { TMyAccountRequest, TMyProfileResponse } from 'src/interfaces/profile-interface';
import Input from 'src/components/input';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import DatePicker from 'src/components/date-picker';
import { PhoneNumber } from 'src/components/phone-number';
import { validatePhoneNumber } from 'src/utils/validate-utils';
import Button from 'src/components/button';
import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import FooterPage from './FooterPage/FooterPage';
import dayjs from 'dayjs';
import { unwrapResult } from '@reduxjs/toolkit';
import './ProfilePage.scss';
import { getAdminProfile, updateProfileAdmin } from 'src/stores/user';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import { useAppDispatch } from 'src/stores';

const ProfilePage = () => {
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [isDisableSubmit, setDisableSubmit] = useState<boolean>(true);
  const [initialFormValues, setInitialFormValues] = useState<TMyProfileResponse>({
    email: '',
    firstName: '',
    lastName: '',
    phone: '',
    address: '',
  });
  const [forceRenderPhoneInput, setForceRenderPhoneInput] = useState(true);
  const phoneInputKey = useMemo(() => {
    return Math.random();
  }, [forceRenderPhoneInput]);

  const [forceRenderDateInput, setForceRenderDateInput] = useState(true);
  const dateInputKey = useMemo(() => {
    return Math.random();
  }, [forceRenderDateInput]);

  const getProfile = async () => {
    const result = await dispatch(getAdminProfile());
    const profile = result.payload as TMyProfileResponse;
    const formValue = {
      firstName: !profile?.firstName ? '' : profile?.firstName,
      lastName: !profile?.lastName ? '' : profile?.lastName,
      phone: !profile?.phone ? '' : profile?.phone,
      address: !profile?.address ? '' : profile?.address,
      ...(profile?.dob && { dob: dayjs(profile.dob).utc(true).toDate() }),
      email: profile.email,
    };

    form.setFieldsValue({ ...initialFormValues, ...formValue });
    setInitialFormValues({ ...initialFormValues, ...formValue });
    setForceRenderPhoneInput(!forceRenderPhoneInput);
    setForceRenderDateInput(!dateInputKey);
  };

  useEffect(() => {
    getProfile();
  }, []);

  const onFinish = async (values: TMyAccountRequest) => {
    setLoading(true);
    try {
      if (values.dob) {
        values.dob = dayjs(values.dob).utc(true).toDate();
      }

      const result = unwrapResult(await dispatch(updateProfileAdmin({ ...initialFormValues, ...values })));
      if (result.id) {
        getProfile();
        setInitialFormValues({ ...initialFormValues, ...values });
        handleValuesChange({ ...initialFormValues, ...values });
        showSuccessToast('Update profile successfully!');
      } else {
        showErrorToast(result.message);
      }
    } catch (error) {
      showErrorToast('Edit profile failed');
      throw error;
    } finally {
      setLoading(false);
    }
  };

  const handleResetFormData = () => {
    form.resetFields();
    setDisableSubmit(true);
  };

  const handleValuesChange = (values: TMyAccountRequest) => {
    const key = Object.keys(values)[0] as keyof TMyAccountRequest;
    if (initialFormValues && isEqual(values[key], initialFormValues[key])) {
      setDisableSubmit(true);
    } else {
      setDisableSubmit(false);
    }
  };

  return (
    <Container className="ProfilePage">
      <CommonContent
        title={
          <Space size={16} className="ProfilePage__title">
            <BaseText type="display1">My Account</BaseText>
            <StatusBox status={state?.client?.status} isShowDischarge />
          </Space>
        }
        className="ProfilePage__wrapper"
      >
        <BaseText type="title">Personal information</BaseText>
        <Form
          name="ProfilePageForm"
          className="ProfilePageForm"
          form={form}
          onFinish={onFinish}
          layout="vertical"
          initialValues={initialFormValues}
          onValuesChange={handleValuesChange}
        >
          <div className="ProfilePageForm__row">
            <FormItem
              label="First Name"
              name="firstName"
              rules={[
                { required: true, message: 'Please enter the first name' },
                { max: 50, message: 'Valid first name shouldn’t exceed 50 characters' },
                { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the first name' },
              ]}
            >
              <Input placeholder="First name" />
            </FormItem>
            <FormItem
              label="Last Name"
              name="lastName"
              rules={[
                { required: true, message: 'Please enter the last name' },
                { max: 50, message: 'Valid last name shouldn’t exceed 50 characters' },
                { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the last name' },
              ]}
            >
              <Input placeholder="Last name" />
            </FormItem>
          </div>

          <div className="ProfilePageForm__row">
            <FormItem
              leftLabel="Age (D.O.B)"
              rightLabel="DD / MM / YYYY"
              name="dob"
              valuePropName="dob"
              normalize={(date) => date && date.format('MM/DD/YYYY')}
            >
              <DatePicker
                placeholder="-- / -- / ----"
                disabledDate={(date) => date.isAfter(new Date())}
                {...(form.getFieldValue('dob') && { defaultValue: dayjs(form.getFieldValue('dob')) })}
                key={dateInputKey}
              />
            </FormItem>
            <FormItem
              label="Phone"
              name="phone"
              rules={[{ required: true, message: 'Please enter the phone number' }, { validator: validatePhoneNumber }]}
            >
              <PhoneNumber mask="+61 000 000 000" key={phoneInputKey} />
            </FormItem>
          </div>
          <div>
            <FormItem
              label="Address"
              name="address"
              rules={[
                { required: true, message: 'Please enter the address' },
                { max: 255, message: 'Valid address shouldn’t exceed 255 characters' },
                { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the address' },
              ]}
            >
              <Input placeholder="Address" />
            </FormItem>
          </div>
          <div className="ProfilePageForm__footer">
            <Button type="default" onClick={handleResetFormData} disabled={isDisableSubmit}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit" loading={loading} disabled={isDisableSubmit}>
              Save changes
            </Button>
          </div>
        </Form>
        <FooterPage email={initialFormValues.email} />
      </CommonContent>
    </Container>
  );
};

export default ProfilePage;
