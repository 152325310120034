import ReactQuill from 'react-quill';
import './Editor.scss';

import 'react-quill/dist/quill.snow.css';
import { useState } from 'react';

interface IEditorProps {
  isShowToolbar?: boolean;
  modules?: any;
  formats?: string[];
  className?: string;
  placeholder?: string;
  onChange?: (value: string) => void;
  value?: string;
}

const Editor = ({ isShowToolbar, modules, formats, className, placeholder, onChange, value }: IEditorProps) => {
  const [isFocused, setIsFocused] = useState(false);

  return (
    <div className="Editor">
      <ReactQuill
        theme={'snow'}
        modules={modules}
        formats={formats}
        className={`Editor__editing-area ${isFocused ? 'Editor__focused' : ''} ${className ?? ''} ${
          !isShowToolbar ? 'Editor__hide-toolbar' : ''
        }`}
        onFocus={() => setIsFocused(true)}
        value={value}
        onChange={onChange}
        onBlur={() => setIsFocused(false)}
        placeholder={placeholder}
        id="editor"
      />
    </div>
  );
};

export default Editor;
