import { Space, Spin } from 'antd';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import StatusBox from 'src/components/status-box';
import Tabs from 'src/components/tabs';
import { BaseText } from 'src/components/text';
import { CLIENT_DETAIL_TAB, EClientDetailTabKey } from './client-detail-constants';
import { useEffect, useState } from 'react';
import GeneralInformation from './components/GeneralInformation/GeneralInformation';
import './ClientDetail.scss';
import DetailedStatistic from './components/DetailedStatistic/DetailedStatistic';
import SubscriptionInfo from './components/SubscriptionInfo/SubscriptionInfo';
import Files from './components/Files/Files';
import { useAppDispatch } from 'src/stores';
import {
  getClinicAndSoloDetailStatistic,
  getClinicAndSoloGeneralInformation,
  getClinicAndSoloSubscriptionChangeLog,
  getClinicAndSoloSubscriptionInfo,
  getClinicAndSoloSubscriptionPaymentLog,
  getInvoiceDetail,
} from 'src/stores/clients';
import { useParams } from 'react-router-dom';
import {
  EGetListClientsType,
  TClinicAndSoloDetailStatistic,
  TClinicAndSoloGeneralInformation,
  TClinicAndSoloSubscriptionInfo,
  TGetClinicAndSoloSubscriptionChangeLogResponse,
  TGetClinicAndSoloSubscriptionPaymentLogResponse,
} from 'src/interfaces/clients-interface';
import { DEFAULT_CLIENTS_PAGE_SIZE } from './clients-constants';
import { unwrapResult } from '@reduxjs/toolkit';

const ClientDetail = () => {
  const dispatch = useAppDispatch();
  const { clinicId } = useParams() as { clinicId: string };

  const [currentTabKey, setCurrentTabKey] = useState<EClientDetailTabKey>(EClientDetailTabKey.GENERAL_INFORMATION);
  const [generalInformation, setGeneralInformation] = useState<TClinicAndSoloGeneralInformation>();
  const [detailStatistic, setDetailStatistic] = useState<TClinicAndSoloDetailStatistic>();
  const [subscriptionInfo, setSubscriptionInfo] = useState<TClinicAndSoloSubscriptionInfo>();
  const [subscriptionChangeLog, setSubscriptionChangeLog] = useState<TGetClinicAndSoloSubscriptionChangeLogResponse>();
  const [subscriptionPaymentLog, setSubscriptionPaymentLog] =
    useState<TGetClinicAndSoloSubscriptionPaymentLogResponse>();

  const handleChangeTab = (activeKey: string) => {
    setCurrentTabKey(activeKey as EClientDetailTabKey);
  };

  const getGeneralInformation = async () => {
    const res = await dispatch(
      getClinicAndSoloGeneralInformation({
        clinicId,
      }),
    );

    setGeneralInformation(res.payload as TClinicAndSoloGeneralInformation);
  };

  const getDetailStatistic = async () => {
    const res = await dispatch(
      getClinicAndSoloDetailStatistic({
        clinicId,
      }),
    );

    setDetailStatistic(res.payload as TClinicAndSoloDetailStatistic);
  };

  const getSubscriptionInfo = async () => {
    const res = await dispatch(
      getClinicAndSoloSubscriptionInfo({
        clinicId,
      }),
    );

    setSubscriptionInfo(res.payload as TClinicAndSoloSubscriptionInfo);
  };

  const getSubscriptionChangeLog = async (page: number) => {
    const res = await dispatch(
      getClinicAndSoloSubscriptionChangeLog({
        clinicId,
        page,
        size: DEFAULT_CLIENTS_PAGE_SIZE,
      }),
    );

    setSubscriptionChangeLog({
      ...(res.payload as TGetClinicAndSoloSubscriptionChangeLogResponse),
      data: [
        ...(subscriptionChangeLog?.data || []),
        ...(res.payload as TGetClinicAndSoloSubscriptionChangeLogResponse).data,
      ],
    });
  };

  const getSubscriptionPaymentLog = async (startAfter: string) => {
    const res = await dispatch(
      getClinicAndSoloSubscriptionPaymentLog({
        clinicId,
        ...(startAfter && { startAfter: startAfter }),
        limit: DEFAULT_CLIENTS_PAGE_SIZE,
      }),
    );

    setSubscriptionPaymentLog({
      ...(res.payload as TGetClinicAndSoloSubscriptionPaymentLogResponse),
      data: [
        ...(subscriptionPaymentLog?.data || []),
        ...(res.payload as TGetClinicAndSoloSubscriptionPaymentLogResponse).data,
      ],
    });
  };

  const handleLoadMoreSubscriptionChangeLog = () => {
    if (subscriptionChangeLog?.currentPage) getSubscriptionChangeLog(subscriptionChangeLog.currentPage + 1);
  };

  const handleLoadMoreSubscriptionPaymentLog = () => {
    if (subscriptionPaymentLog?.data) {
      const lastData = subscriptionPaymentLog.data[subscriptionPaymentLog.data.length - 1];
      getSubscriptionPaymentLog(lastData.id);
    }
  };

  const handleDownloadInvoice = async (invoiceId: string) => {
    const data = unwrapResult(
      await dispatch(
        getInvoiceDetail({
          clinicId,
          invoiceId,
        }),
      ),
    );
    if (data.invoiceUrl) {
      window.open(data.invoiceUrl, '_self');
    }
  };

  const tabItems = CLIENT_DETAIL_TAB.map((tab) => {
    switch (tab.key) {
      case EClientDetailTabKey.GENERAL_INFORMATION:
        return {
          ...tab,
          children: generalInformation ? <GeneralInformation clinicInfo={generalInformation} /> : <Spin size="large" />,
        };

      case EClientDetailTabKey.DETAILED_STATISTIC:
        return {
          ...tab,
          children:
            generalInformation && detailStatistic ? (
              <DetailedStatistic type={generalInformation.type} statistic={detailStatistic} />
            ) : (
              <Spin size="large" />
            ),
        };

      case EClientDetailTabKey.SUBSCRIPTION_INFO:
        return {
          ...tab,
          children:
            subscriptionInfo && generalInformation ? (
              <SubscriptionInfo
                generalInformation={generalInformation}
                subscriptionInfo={subscriptionInfo}
                subscriptionChangeLog={subscriptionChangeLog}
                subscriptionPaymentLog={subscriptionPaymentLog}
                onLoadMoreSubscriptionChangeLog={handleLoadMoreSubscriptionChangeLog}
                onLoadMoreSubscriptionPaymentLog={handleLoadMoreSubscriptionPaymentLog}
                onDownloadInvoice={handleDownloadInvoice}
              />
            ) : (
              <Spin size="large" />
            ),
        };

      default:
        return tab;
    }
  });

  useEffect(() => {
    getGeneralInformation();
    getDetailStatistic();
    getSubscriptionInfo();
    getSubscriptionChangeLog(0);
    getSubscriptionPaymentLog('');
  }, [clinicId]);

  return (
    <Container className="ClientDetail">
      <CommonContent
        title={
          <Space size={16} className="ClientDetail__title">
            <BaseText type="display1">
              {generalInformation?.type === EGetListClientsType.CLINIC
                ? generalInformation?.name || ''
                : `${generalInformation?.owner?.firstName || ''} ${generalInformation?.owner?.lastName || ''}`}
            </BaseText>
            {generalInformation?.status && <StatusBox status={generalInformation.status} />}
          </Space>
        }
        className="ClientDetail__wrapper"
      >
        <Tabs items={tabItems} activeKey={currentTabKey} onChange={handleChangeTab} />
      </CommonContent>
    </Container>
  );
};

export default ClientDetail;
