import { useEffect, useState } from 'react';
import { Form, Typography } from 'antd';
import { useNavigate, useSearchParams } from 'react-router-dom';

import './ResetPassword.scss';
import { BaseText } from 'src/components/text';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import Button from 'src/components/button';
import { PASSWORD_REGEX } from 'src/variables/constants';
import { IconError } from 'src/assets/icons';
import ResponseError from 'src/interfaces/error-response-interface';
import { RoutePaths } from 'src/routes/routes-constants';
import { showSuccessToast } from 'src/components/toast/Toast';
import { checkStatusLinkChangePassword, resetPassword } from 'src/services/auth-service';

const ResetPassword = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [isLinkValid, setIsLinkValid] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [email, setEmail] = useState('');
  const token = searchParams.get('token');
  const [errorMsg, setErrorMsg] = useState<string>('');

  const onFinish = async (values: { newPassword: string }) => {
    if (token) {
      try {
        const response = await resetPassword({ resetToken: token, newPassword: values.newPassword });

        if (response.id) {
          showSuccessToast(`Congratulations! You've successfully reset your password.`);
          setTimeout(() => {
            navigate(RoutePaths.SIGN_IN);
          }, 500);
        }
      } catch (error) {
        const message = (error as ResponseError).message;
        setErrorMsg(message);
      }
    }
  };

  const onVerifyToken = async () => {
    if (token) {
      try {
        const response = await checkStatusLinkChangePassword({ token });
        if (response.email) {
          setIsLinkValid(true);
          setEmail(response.email);
        }
      } catch (error) {
        setIsLinkValid(false);
      }
    }
    setIsLoading(false);
  };

  const handleBackToLogin = () => {
    navigate(RoutePaths.SIGN_IN);
  };

  useEffect(() => {
    onVerifyToken();
  }, []);

  return (
    <div className="ResetPassword">
      {!loading &&
        (isLinkValid ? (
          <Form className="ResetPassword__Form" autoComplete="off" layout="vertical" onFinish={onFinish}>
            <BaseText type="display1" textAlign="center" className="ResetPassword__container-heading">
              Reset password
            </BaseText>
            <BaseText type="body1" className="ResetPassword__description">
              Strong passwords include numbers, letters and punctuation marks.
            </BaseText>
            <BaseText type="body1" className="ResetPassword__description">
              Email: {email}
            </BaseText>
            <FormItem
              label="New password"
              name="newPassword"
              rules={[
                { required: true, message: 'Please enter the password' },
                {
                  pattern: PASSWORD_REGEX,
                  message:
                    'Password must have at least 8 characters that include at least 1 lowercase character, 1 uppercase character, 1 number and 1 special character, maximum characters is 16.',
                },
              ]}
            >
              <Input type="password" />
            </FormItem>
            <FormItem
              label="Confirm new password"
              name="confirmNewPassword"
              rules={[
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (getFieldValue('newPassword') === value && value) {
                      return Promise.resolve();
                    }
                    if (!value) {
                      return Promise.reject(new Error('Please enter the new password again'));
                    }
                    return Promise.reject(new Error('Password does not match'));
                  },
                }),
              ]}
            >
              <Input type="password" />
            </FormItem>
            {errorMsg && (
              <BaseText type="caption" color="error" textAlign="center" className="RegisterPage__errorMsg">
                {errorMsg}
              </BaseText>
            )}
            <Button type="primary" htmlType="submit" size="large">
              Reset password
            </Button>
            <Typography.Link
              className="ResetPassword__back-login"
              onClick={(e) => {
                e.stopPropagation();
                navigate(RoutePaths.SIGN_IN);
              }}
            >
              Back to login
            </Typography.Link>
          </Form>
        ) : (
          <>
            <IconError width={64} height={64} />
            <div className="ResetPassword__error">
              <BaseText type="headline" className="ResetPassword__error-title">
                This link has expired
              </BaseText>
              <BaseText type="body1" className="ResetPassword__error-description">
                Please request password reset again
              </BaseText>
              <Button type="primary" onClick={handleBackToLogin}>
                Back to Log in
              </Button>
            </div>
          </>
        ))}
    </div>
  );
};

export default ResetPassword;
