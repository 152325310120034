import {
  EHomeworkStatus,
  EHomeworkTopicSortBy,
  EHomeworkType,
  ESortByHomeworkParam,
} from 'src/variables/enum-variables';
import { TCommonGetListParams, TCommonGetListResponse } from './common-interface';
import { IHomeWorkFormItemData } from 'src/pages/homework/AddNew/constants';

export type TGetHomeworkTopicsParams = TCommonGetListParams<EHomeworkTopicSortBy> & {
  name?: string;
  homeworkType?: EHomeworkType;
};

export type THomeworkTopic = {
  createdAt: string;
  homeworkType: EHomeworkType;
  id: string;
  name: string;
  updatedAt: string;
};

export enum ECategoryType {
  ACTION = 'Action',
  NOTIFICATION = 'Notification',
}

export type THomework = {
  category: ECategoryType;
  createdAt: string;
  description: string;
  enableRemind: boolean;
  homeworkTopicId: string;
  id: string;
  profileId: string | null;
  status: EHomeworkStatus;
  title: string;
  type: EHomeworkType;
  updatedAt: string;
  videoLink: string | null;
  remindAtHour: number;
  items?: IHomeWorkFormItemData[];
  timezone: string;
};

export type TGetHomeworkTopicsResponse = TCommonGetListResponse<THomeworkTopic[]>;

export type TCreateHomeworkTopicsParams = {
  name: string;
  homeworkType: EHomeworkType;
};

export type TCreateHomeworkParams = {
  id?: string;
  title: string;
  category: ECategoryType;
  homeworkTopicId: string;
  profileId?: string;
  type: EHomeworkType | string;
  description: string;
  enableRemind: boolean;
  remindAtHour?: number;
  videoLink?: string;
  status: EHomeworkStatus;
  items?: any[];
  timezone: string;
};

export type TCreateHomeworkTopicsResponse = THomeworkTopic;

export type TGetHomeworkListParams = TCommonGetListParams<ESortByHomeworkParam> & {
  title?: string;
  homeworkTopicId?: string;
  profileId?: string;
  type?: EHomeworkType;
  status?: EHomeworkStatus;
};

export type TGetHomeworkListResponse = TCommonGetListResponse<THomework[]>;

export type TUpdateHomeworkTopicRequest = {
  id: string;
  name: string;
  type: EHomeworkType;
};

export type TUpdateHomeworkTopicResponse = THomeworkTopic;
