import { useEffect, useState } from 'react';
import { List } from 'antd';
import Button from 'src/components/button';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import { SearchInput } from 'src/components/input';
import { BaseText } from 'src/components/text';

import Pagination from 'src/components/pagination';
import { EQuoteAction, IAddAndEditQuoteFormValues } from './components/QuoteActionModal/quote-action-modal-constants';
import QuoteActionModal from './components/QuoteActionModal/QuoteActionModal';
import Quote from './components/Quote/Quote';
import useDebounce from 'src/hooks/useDebounce';
import { TRootState, useAppDispatch } from 'src/stores';
import { addNewQuote, deleteQuote, getListQuotes, updateQuote } from 'src/stores/quotes';
import { DEFAULT_QUOTES_PAGE_SIZE } from './quotes-of-the-day-constants';
import { useSelector } from 'react-redux';
import { TQuote } from 'src/interfaces/quotes-interface';

import './QuotesOfTheDay.scss';

const QuotesOfTheDay = () => {
  const dispatch = useAppDispatch();
  const { quotesList } = useSelector((state: TRootState) => state.quotes);

  const [searchText, setSearchText] = useState<string>('');
  const searchTextDebounce = useDebounce(searchText);
  const [quoteActionModalInfo, setQuoteActionModalInfo] = useState<{
    isOpen: boolean;
    type: EQuoteAction;
    editQuote: TQuote | undefined;
  }>({
    isOpen: false,
    type: EQuoteAction.ADD_NEW,
    editQuote: undefined,
  });

  const handleSearchQuotes = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(e.target.value);
  };

  const onCloseQuoteActionModal = () => {
    setQuoteActionModalInfo({
      isOpen: false,
      type: EQuoteAction.ADD_NEW,
      editQuote: undefined,
    });
  };

  const onOpenAddQuoteModal = () => {
    setQuoteActionModalInfo({
      isOpen: true,
      type: EQuoteAction.ADD_NEW,
      editQuote: undefined,
    });
  };

  const onOpenDeleteQuoteModal = (quote: TQuote) => {
    setQuoteActionModalInfo({
      isOpen: true,
      type: EQuoteAction.DELETE,
      editQuote: quote,
    });
  };

  const onOpenUpdateQuoteModal = (quote: TQuote) => {
    setQuoteActionModalInfo({
      isOpen: true,
      type: EQuoteAction.EDIT,
      editQuote: quote,
    });
  };

  const getQuotes = (page: number) => {
    dispatch(
      getListQuotes({
        page,
        size: DEFAULT_QUOTES_PAGE_SIZE,
        ...(searchTextDebounce && { keyword: searchTextDebounce }),
      }),
    );
  };

  const handleAddNewQuote = async (values: IAddAndEditQuoteFormValues) => {
    await dispatch(
      addNewQuote({
        author: values.author,
        message: values.message,
      }),
    );
    onCloseQuoteActionModal();
    getQuotes(quotesList.currentPage);
  };

  const handleUpdateQuote = async (values: IAddAndEditQuoteFormValues) => {
    if (quoteActionModalInfo.editQuote) {
      await dispatch(
        updateQuote({
          id: quoteActionModalInfo.editQuote.id,
          author: values.author,
          message: values.message,
        }),
      );
      onCloseQuoteActionModal();
      getQuotes(quotesList.currentPage);
    }
  };

  const handleDeleteQuote = async () => {
    if (quoteActionModalInfo.editQuote) {
      await dispatch(
        deleteQuote({
          id: quoteActionModalInfo.editQuote.id,
        }),
      );
      onCloseQuoteActionModal();
      getQuotes(quotesList.currentPage);
    }
  };

  const handleChangePage = (page: number) => {
    getQuotes(page);
  };

  useEffect(() => {
    getQuotes(0);
  }, [searchTextDebounce]);

  return (
    <Container className="QuotesOfTheDay">
      <CommonContent
        title="Quotes of the day"
        action={
          <Button onClick={onOpenAddQuoteModal}>
            <BaseText>New quote</BaseText>
          </Button>
        }
      >
        <div className="QuotesOfTheDay__header">
          <SearchInput placeholder="Search" value={searchText} onChange={handleSearchQuotes} />
        </div>
        <List
          dataSource={quotesList.data}
          renderItem={(item) => (
            <Quote
              text={item.message}
              hasDeleteIcon
              hasEditIcon
              onDelete={() => onOpenDeleteQuoteModal(item)}
              onEdit={() => onOpenUpdateQuoteModal(item)}
            />
          )}
          className="QuotesOfTheDay__list"
        />
        {quotesList.totalPage && quotesList.totalPage > 1 ? (
          <Pagination
            currentPage={quotesList.currentPage}
            totalPage={quotesList.totalPage}
            totalRecord={quotesList.totalRecord}
            pageSize={DEFAULT_QUOTES_PAGE_SIZE}
            onChangePage={handleChangePage}
          />
        ) : null}
      </CommonContent>
      <QuoteActionModal
        open={quoteActionModalInfo.isOpen}
        type={quoteActionModalInfo.type}
        defaultQuote={quoteActionModalInfo.editQuote}
        onClose={onCloseQuoteActionModal}
        onCreate={handleAddNewQuote}
        onUpdate={handleUpdateQuote}
        onDelete={handleDeleteQuote}
      />
    </Container>
  );
};

export default QuotesOfTheDay;
