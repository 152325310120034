import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { CommonContent, Container } from 'src/components/containers';
import { BaseText } from 'src/components/text';
import {
  TSubscriptionData,
  TSubscriptionDataValues,
  TUpdatePlanPackageRequest,
} from 'src/interfaces/pricing-plan-interface';
import { TRootState, useAppDispatch } from 'src/stores';
import { EPlanActions, getPlanPackagesAction, updatePlanPackageAction } from 'src/stores/plan-management';
import { ESubscriptionType } from 'src/variables/enum-variables';
import EditSubscriptionModal from './components/EditSubscriptionModal';
import SubscriptionCard from './components/SubscriptionCard';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import './PricingPlanPage.scss';
import { ListLoadingSpinner } from 'src/components/spinner';

type TEditSubscriptionModalState = {
  open: boolean;
  type: ESubscriptionType;
  data?: TSubscriptionData;
};

const PricingPlanPage = () => {
  const dispatch = useAppDispatch();

  const { planPackages, updatingPlan, loading } = useSelector((state: TRootState) => ({
    planPackages: state.planManagement.planPackages,
    updatingPlan: state.loading[EPlanActions.UPDATE_PLAN_PACKAGE],
    loading: state.loading[EPlanActions.GET_PLAN_PACKAGES],
  }));

  const [selectedPlanId, setSelectedPlanId] = useState<string>('');
  const [editSubscriptionModalState, setEditSubscriptionModalState] = useState<TEditSubscriptionModalState>({
    open: false,
    type: ESubscriptionType.SOLO,
  });
  const formattedPlanPackages = useMemo(
    () =>
      planPackages.map((item) => ({
        ...item,
        price: item.type === ESubscriptionType.SOLO ? item.plans[0].price : undefined,
        descriptions: JSON.parse(item.description),
      })),
    [planPackages],
  );

  const handleChangePlan = (id: string) => {
    setSelectedPlanId(id);
  };

  const handleOpenEditSubscriptionModal = (type: ESubscriptionType, data: TSubscriptionData) => {
    setEditSubscriptionModalState({
      open: true,
      type,
      data,
    });
  };

  const handleCloseEditSubscriptionModal = () => {
    setEditSubscriptionModalState({
      open: false,
      type: ESubscriptionType.SOLO,
      data: undefined,
    });
  };

  const getPlanPackages = useCallback(async () => {
    dispatch(
      getPlanPackagesAction({
        onSuccess: (response) => {
          const multiPlan = response.find((item) => item.type === ESubscriptionType.CLINIC);
          multiPlan?.plans[0] && !selectedPlanId && handleChangePlan(multiPlan?.plans[0].id);
        },
      }),
    );
  }, [dispatch]);

  const handleUpdatePlanPackage = (values: TSubscriptionDataValues) => {
    const bodyRequest: TUpdatePlanPackageRequest = {
      name: values.name,
      packageId: values.id,
      trialDay: Number(values.trialDay),
      description: JSON.stringify(values.descriptions),
      oldPlans: values.plans?.map((item) => ({ ...item, price: Number(item.price) })),
    };

    dispatch(
      updatePlanPackageAction({
        ...bodyRequest,
        onSuccess: () => {
          handleCloseEditSubscriptionModal();
          showSuccessToast('The plan has been updated successfully');
          getPlanPackages();
        },
        onError: (error) => {
          showErrorToast(error?.message as string);
        },
      }),
    );
  };

  useEffect(() => {
    getPlanPackages();
  }, []);

  return (
    <Container className="PricingPlanPage">
      <CommonContent title="Pricing plan">
        {loading ? (
          <div className="PricingPlanPage__spin-loading">
            <ListLoadingSpinner />
          </div>
        ) : (
          <div className="PricingPlanPage__content">
            <BaseText type="title" className="PricingPlanPage__header">
              Current subscriptions
            </BaseText>
            <div className="PricingPlanPage__packages">
              {formattedPlanPackages?.map((item) => (
                <SubscriptionCard
                  key={item.id}
                  type={item.type}
                  data={item}
                  selectedPlanId={selectedPlanId}
                  onChangePlan={handleChangePlan}
                  onClickEdit={() => handleOpenEditSubscriptionModal(item.type, item)}
                />
              ))}
            </div>
          </div>
        )}
      </CommonContent>

      <EditSubscriptionModal
        {...editSubscriptionModalState}
        updating={updatingPlan}
        onClose={handleCloseEditSubscriptionModal}
        onSubmit={handleUpdatePlanPackage}
      />
    </Container>
  );
};

export default PricingPlanPage;
