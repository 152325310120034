import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiClient } from 'src/services/api-client';
import { EQuoteActions } from './quotes-constants';
import {
  TAddNewQuoteParams,
  TAddNewQuoteResponse,
  TDeleteQuoteParams,
  TDeleteQuoteResponse,
  TGetListQuotesParams,
  TGetListQuotesResponse,
  TUpdateQuoteParams,
  TUpdateQuoteResponse,
} from 'src/interfaces/quotes-interface';
import { AxiosResponse } from 'axios';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';

export const getListQuotes = createAsyncThunk(
  EQuoteActions.GET_LIST_QUOTES,
  async (params: TGetListQuotesParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetListQuotesResponse> = await ApiClient.get(`/api/v1/admin/quotes`, {
        params,
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const addNewQuote = createAsyncThunk(
  EQuoteActions.ADD_NEW_QUOTES,
  async (params: TAddNewQuoteParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TAddNewQuoteResponse> = await ApiClient.post(`/api/v1/admin/quotes`, params);

      showSuccessToast('Your quote has been created');

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const updateQuote = createAsyncThunk(
  EQuoteActions.UPDATE_QUOTE,
  async (params: TUpdateQuoteParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TUpdateQuoteResponse> = await ApiClient.put(
        `/api/v1/admin/quotes/${params.id}`,
        params,
      );

      showSuccessToast('Your quote has been updated');

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const deleteQuote = createAsyncThunk(
  EQuoteActions.DELETE_QUOTE,
  async (params: TDeleteQuoteParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TDeleteQuoteResponse> = await ApiClient.delete(`/api/v1/admin/quotes/${params.id}`);

      showSuccessToast('Your quote has been deleted');

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);
