import * as psychoeducationActions from './psychoeducation-actions';
import * as psychoeducationConstants from './psychoeducation-constants';
import psychoeducationSlice from './psychoeducation-slice';

export const {
  getPsychoeducationList,
  addNewPsychoeducation,
  addNewPsychoeducationTopic,
  deletePsychoeducation,
  getPsychoeducationTopicList,
  updatePsychoeducation,
  getPsychoeducationDetail,
} = psychoeducationActions;
export const { EPsychoeducationActions } = psychoeducationConstants;
export const { reducer: psychoeducationReducer } = psychoeducationSlice;
