import { InputProps, InputRef, Spin } from 'antd';
import { useEffect, useState } from 'react';

import { SearchIcon } from 'src/assets/icons';
import { TChangeInputEvent } from 'src/interfaces/common-interface';
import Input from './Input';
import './Search.scss';

interface ISearchProps extends InputProps {
  loading?: boolean;
  ref?: React.Ref<InputRef>;
}

const Search = ({ className, placeholder, loading, width = '100%', ref, ...rest }: ISearchProps) => {
  return (
    <Input
      ref={ref}
      className={`Search ${className ?? ''}`}
      placeholder={placeholder ?? 'Search'}
      style={{ width }}
      prefix={loading ? <Spin /> : <SearchIcon />}
      size="middle"
      {...rest}
    />
  );
};

export default Search;
