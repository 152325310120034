import { EGender, EPaymentStatus } from 'src/variables/enum-variables';
import {
  TCommonGetListInfiniteScrollParams,
  TCommonGetListInfiniteScrollResponse,
  TCommonGetListParams,
  TCommonGetListResponse,
} from './common-interface';

export enum EGetListClientsType {
  CLINIC = 'CLINIC',
  SOLO = 'SOLO',
}

export type TGetListClientsParams = TCommonGetListParams<string> & {
  type: EGetListClientsType;
  status?: string;
};

export type TGetListClientsResponse = TCommonGetListResponse<TClient[]>;

export type TClient = {
  id: string;
  clinicName: string;
  clinicEmail: string;
  pricingPlan: number;
  amountPlan: number;
  status: string;
  ownerFirstName: string;
  ownerLastName: string;
  ownerEmail: string;
  ownerAvatar: string;
  activePractitioner: number;
  activeClient: number;
  createdAt: string;
  ownerProfileId: string;
};

export type TGetClinicAndSoloGeneralInformationParams = {
  clinicId: string;
};

export type TClinicAndSoloGeneralInformation = {
  abn: string | null;
  address: string | null;
  email: string | null;
  id: string;
  name: string;
  owner: {
    address: string | null;
    dob: string | null;
    email: string;
    firstName: string | null;
    lastName: string | null;
    phone: string | null;
    gender: EGender | null;
    practitionerTypeId: string | null;
    practitionerTypeName: string | null;
    practitionerTypeOther: string | null;
    provideNumber: string | null;
    role: string;
  };
  phone: string | null;
  status: EPaymentStatus | null;
  type: EGetListClientsType;
};

export type TGetClinicAndSoloDetailStatisticParams = {
  clinicId: string;
};

export type TClinicAndSoloDetailStatistic = {
  practitioners: {
    totalActive: number;
    totalPending: number;
    totalInactive: number;
    total: number;
  };
  clients: {
    totalActive: number;
    totalPending: number;
    totalInactive: number;
    total: number;
  };
};

export type TGetClinicAndSoloSubscriptionInfoParams = {
  clinicId: string;
};

export type TClinicAndSoloSubscriptionInfo = {
  id: string;
  startAt?: string;
  cancelAt: string | null;
  totalPractitionerActive: number;
  nextPaymentAt: string | null;
  status?: EPaymentStatus;
  plan?: {
    id: string;
    price: number;
    amount: number;
    package: {
      description: string;
      id: string;
      name: string;
    };
  };
  registeredAt: string;
};

export type TGetClinicAndSoloSubscriptionChangeLogParams = TCommonGetListParams<string> & {
  clinicId: string;
};

export type TClinicAndSoloSubscriptionChangeLog = {
  id: string;
  clinicId: string;
  packageName: string;
  price: number;
  practitionerLicense: number;
  startDate: string;
};

export type TGetClinicAndSoloSubscriptionChangeLogResponse = TCommonGetListResponse<
  TClinicAndSoloSubscriptionChangeLog[]
>;

export type TGetClinicAndSoloSubscriptionPaymentLogParams = TCommonGetListInfiniteScrollParams & {
  clinicId: string;
};

export type TClinicAndSoloSubscriptionPaymentLog = {
  id: string;
  price: number;
  status: string;
  currency: string;
  createdAt: string;
};

export type TGetClinicAndSoloSubscriptionPaymentLogResponse = TCommonGetListInfiniteScrollResponse<
  TClinicAndSoloSubscriptionPaymentLog[]
>;

export type TGetInvoiceDetailParams = {
  clinicId: string;
  invoiceId: string;
};

export type TGetInvoiceDetailResponseData = {
  invoiceUrl: string;
};
