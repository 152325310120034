import { UploadFile } from 'antd';
import { createAsyncThunk } from '@reduxjs/toolkit';

import { ApiClient } from 'src/services/api-client';
import { EHomeworkActions } from './homework-constants';
import {
  TCreateHomeworkParams,
  TCreateHomeworkTopicsParams,
  TCreateHomeworkTopicsResponse,
  TGetHomeworkListParams,
  TGetHomeworkListResponse,
  TGetHomeworkTopicsParams,
  TGetHomeworkTopicsResponse,
  THomework,
  TUpdateHomeworkTopicRequest,
  TUpdateHomeworkTopicResponse,
} from 'src/interfaces/homework-interface';
import { AxiosResponse } from 'axios';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';

export const getHomeworkTopics = createAsyncThunk(
  EHomeworkActions.GET_HOMEWORK_TOPICS,
  async (params: TGetHomeworkTopicsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetHomeworkTopicsResponse> = await ApiClient.get(`/api/v1/homework-topics`, {
        params,
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const createHomeworkTopics = createAsyncThunk(
  EHomeworkActions.CREATE_HOMEWORK_TOPICS,
  async (params: TCreateHomeworkTopicsParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TCreateHomeworkTopicsResponse> = await ApiClient.post(
        `/api/v1/homework-topics`,
        params,
      );
      showSuccessToast('Your topic has been created');

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const getHomeworkList = createAsyncThunk(
  EHomeworkActions.GET_HOMEWORK_LIST,
  async (params: TGetHomeworkListParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetHomeworkListResponse> = await ApiClient.get(`/api/v1/homeworks`, {
        params,
      });
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const createHomework = createAsyncThunk(
  EHomeworkActions.CREATE_HOMEWORK,
  async (params: TCreateHomeworkParams, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TCreateHomeworkTopicsResponse> = await ApiClient.post(`/api/v1/homeworks`, params);

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const uploadHomeworkPhoto = createAsyncThunk(
  EHomeworkActions.UPLOAD_HOMEWORK_PHOTO,
  async (params: { avatar: UploadFile }, { rejectWithValue }) => {
    try {
      const formData = new FormData();
      formData.append('avatar', params.avatar as unknown as Blob);
      const response: AxiosResponse<{ url: string }> = await ApiClient.post(`/api/v1/media/admin/upload`, formData);

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const deleteHomework = createAsyncThunk(
  EHomeworkActions.DELETE_HOMEWORK,
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response = await ApiClient.delete(`/api/v1/homeworks/${homeworkId}`);
      if (response.status === 200) {
        showSuccessToast('Task homework has been deleted');
      }
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const getHomeworkById = createAsyncThunk(
  EHomeworkActions.GET_HOMEWORK_BY_ID,
  async (homeworkId: string, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<THomework> = await ApiClient.get(`/api/v1/homeworks/${homeworkId}`);
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const updateHomeworkById = createAsyncThunk(
  EHomeworkActions.UPDATE_HOMEWORK_BY_ID,
  async (params: TCreateHomeworkParams, { rejectWithValue }) => {
    try {
      let response: AxiosResponse<THomework>;
      if (params.items) {
        response = await ApiClient.patch(`/api/v1/homeworks/${params.id}`, params);
      } else {
        response = await ApiClient.put(`/api/v1/homeworks/${params.id}`, params);
      }
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const updateHomeworkTopicAction = createAsyncThunk(
  EHomeworkActions.EDIT_HOMEWORK_TOPIC,
  async (
    payload: TUpdateHomeworkTopicRequest & {
      onSuccess?: (response?: TUpdateHomeworkTopicResponse) => void;
      onError?: (error: ResponseError) => void;
    },
    { rejectWithValue },
  ) => {
    const { id, name, type, onSuccess, onError } = payload;
    try {
      const response = await ApiClient.patch(`/api/v1/homework-topics/${id}`, { name, homeworkType: type });
      onSuccess?.(response.data);
      return response.data;
    } catch (error) {
      onError?.(error as ResponseError);
      return rejectWithValue(error);
    }
  },
);
export const deleteHomeworkTopicAction = createAsyncThunk(
  EHomeworkActions.DELETE_HOMEWORK_TOPIC,
  async (
    payload: {
      id: string;
      onSuccess?: () => void;
      onError?: (error: ResponseError) => void;
    },
    { rejectWithValue },
  ) => {
    const { id, onSuccess, onError } = payload;
    try {
      const response = await ApiClient.delete(`/api/v1/homework-topics/${id}`);
      onSuccess?.();
      return response.data;
    } catch (error) {
      onError?.(error as ResponseError);
      return rejectWithValue(error);
    }
  },
);
