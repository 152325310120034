import { HomeworkDeleteIcon, HomeworkEditIcon } from 'src/assets/icons';

import { BaseText } from 'src/components/text';
import './Quote.scss';

interface IQuoteProps {
  text: string;
  hasEditIcon?: boolean;
  hasDeleteIcon?: boolean;
  onEdit: () => void;
  onDelete: () => void;
}

const Quote = ({ text, hasEditIcon, hasDeleteIcon, onDelete, onEdit }: IQuoteProps) => {
  return (
    <div className="Quote">
      <BaseText type="body1">{text}</BaseText>
      <div className="Quote__icons">
        {hasEditIcon && <HomeworkEditIcon onClick={onEdit} />}
        {hasDeleteIcon && <HomeworkDeleteIcon onClick={onDelete} />}
      </div>
    </div>
  );
};

export default Quote;
