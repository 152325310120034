import getYouTubeID from 'get-youtube-id';

export const getThumbnailYoutube = (url: string) => {
  if (url) {
    const youtubeId = getYouTubeID(url);
    return `http://img.youtube.com/vi/${youtubeId}/hqdefault.jpg`;
  }

  return '';
};
