import { createSlice } from '@reduxjs/toolkit';

import { TGetListClientsResponse } from 'src/interfaces/clients-interface';
import { DEFAULT_GET_LIST_RESPONSE } from 'src/variables/common';
import * as asyncActions from './clients-actions';

type TPractitionersState = {
  clientList: TGetListClientsResponse;
};

const initialState: TPractitionersState = {
  clientList: DEFAULT_GET_LIST_RESPONSE,
};

export const clientsSlice = createSlice({
  name: 'clients',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(asyncActions.getClients.fulfilled, (state, action) => {
      state.clientList = action.payload as TGetListClientsResponse;
    });
  },
});

export default clientsSlice;
