import { EHomeworkType } from 'src/variables/enum-variables';
import { v4 as uuidv4 } from 'uuid';

// -----------------------INTERFACES------------------------------------
export interface IHomeWorkFormData {
  title: string;
  homeworkTopicId: string;
  type: EHomeworkType | string;
  description: string;
  timePicker: any;
  suffixTimePicker: string;
  videoLink: string;
  items: IHomeWorkFormItemData[];
  timezone: string;
}

export interface IHomeWorkFormItemData {
  type?: EFormItemType;
  index: number;
  required: boolean;
  photo?: any;

  homeworkId: string;
  title?: string;
  description?: string;
  question?: string;
  questionType?: string;
  rangeFrom?: number;
  rangeTo?: number;
  options?: { key: string; value: string }[];
  photoTitle?: string;
  photoUri?: string;
  id?: string;
}

// -----------------------ENUMS------------------------------------
export enum EFormItemType {
  QUESTION = 'QUESTION',
  FORM_HEADER = 'FORM_HEADER',
  PHOTO = 'PHOTO',
}

export enum EAnswerOption {
  SINGLE = 'SINGLE_CHOICE',
  MULTIPLE = 'MULTI_CHOICE',
  SHORT_ANSWER = 'SHORT_ANSWER',
}

// -----------------------CONSTANTS------------------------------------
export const ANSWER_OPTIONS = [
  { value: EAnswerOption.SINGLE, label: 'Single-choice' },
  { value: EAnswerOption.MULTIPLE, label: 'Multiple-choice' },
  { value: EAnswerOption.SHORT_ANSWER, label: 'Short answer' },
];

export const HOME_WORK_TYPES = Object.entries(EHomeworkType).map(([, value]) => ({
  label: value,
  value: value,
}));

export const INIT_FORM_ITEM_HEADER: IHomeWorkFormItemData = {
  homeworkId: '',
  type: EFormItemType.FORM_HEADER,
  title: '',
  description: '',
  required: false,
  index: 0,
};

export const INIT_FORM_ITEM_QUESTION: IHomeWorkFormItemData = {
  homeworkId: '',
  type: EFormItemType.QUESTION,
  question: '',
  questionType: ANSWER_OPTIONS[1].value,
  options: [{ key: uuidv4(), value: '' }],
  required: false,
  index: 0,
};

export const INIT_FORM_ITEM_PHOTO: IHomeWorkFormItemData = {
  homeworkId: '',
  type: EFormItemType.PHOTO,
  photoTitle: '',
  photoUri: '',
  required: false,
  index: 0,
};

export const QUILL_TITLE_MODULES = {
  toolbar: [['bold', 'italic', 'underline', 'link']],
  clipboard: {
    matchVisual: false,
  },
  keyboard: {
    bindings: {
      enter: {
        key: 13,
        handler: () => false,
      },
    },
  },
};

export const QUILL_TITLE_FORMATS = ['bold', 'italic', 'underline', 'link'];

export const QUILL_DESCRIPTION_MODULES = {
  toolbar: [
    ['bold', 'italic', 'underline', 'link'],
    [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const QUILL_DESCRIPTION_FORMATS = ['bold', 'italic', 'underline', 'link', 'list', 'bullet', 'indent'];

export const QUILL_QUESTION_MODULES = {
  toolbar: [['bold', 'italic', 'underline', 'link']],
  clipboard: {
    matchVisual: false,
  },
};

export const QUILL_QUESTION_FORMATS = ['bold', 'italic', 'underline', 'link'];
