import { Input } from 'antd';
import { TextAreaProps } from 'antd/es/input';

import './TextArea.scss';

const { TextArea: AntTextArea } = Input;

const TextArea = ({ className, ...rest }: TextAreaProps) => {
  const inputClassName = `TextArea ${className ?? ''}`;
  return <AntTextArea className={inputClassName} {...rest} />;
};

export default TextArea;
