import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiClient } from 'src/services/api-client';
import { EUserActions } from './user-constants';
import {
  TChangePasswordRequest,
  TChangePasswordResponse,
  TMyAccountRequest,
  TMyAccountResponse,
} from 'src/interfaces/profile-interface';
import { AxiosResponse } from 'axios';

// example use async/await
const login = createAsyncThunk(
  'user/login',
  async (data: { username: string; password: string }, { rejectWithValue }) => {
    try {
      const response = await fetch('https://fake-rest-api-nodejs.herokuapp.com/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      const jsonData = await response.json();

      if (response.status < 200 || response.status >= 300) {
        return rejectWithValue(jsonData);
      }

      return jsonData;
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
);

const getAdminProfile = createAsyncThunk(EUserActions.GET_ADMIN_PROFILE, async () => {
  const response = await ApiClient.get(`/api/v1/admin/profile`);

  return response.data;
});

const updateProfileAdmin = createAsyncThunk(EUserActions.UPDATE_PROFILE_ADMIN, async (params: TMyAccountRequest) => {
  try {
    const response: AxiosResponse<TMyAccountResponse> = await ApiClient.put(`/api/v1/admin/profile`, params);

    return response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    return error.data;
  }
});

const changePassword = createAsyncThunk(
  EUserActions.CHANGE_PASSWORD,
  async (params: TChangePasswordRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TChangePasswordResponse> = await ApiClient.put(
        `/api/v1/admin/profile/change-password`,
        params,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export { login, getAdminProfile, updateProfileAdmin, changePassword };
