import StatisticCard from './StatisticCard';
import './DetailedStatistic.scss';
import { EGetListClientsType, TClinicAndSoloDetailStatistic } from 'src/interfaces/clients-interface';

interface IDetailedStatisticProps {
  statistic: TClinicAndSoloDetailStatistic;
  type: EGetListClientsType;
}

const DetailedStatistic = ({ statistic, type }: IDetailedStatisticProps) => {
  return (
    <div className="DetailedStatistic">
      {type === EGetListClientsType.CLINIC && (
        <div className="DetailedStatistic__practitioners">
          <StatisticCard type="message" heading="Number of all practitioners" content={statistic.practitioners.total} />
          <StatisticCard type="active" heading="Active practitioners" content={statistic.practitioners.totalActive} />
          <StatisticCard
            type="pending"
            heading="Pending practitioners"
            content={statistic.practitioners.totalPending}
          />
          <StatisticCard
            type="discharged"
            heading="Inactive practitioners"
            content={statistic.practitioners.totalInactive}
          />
        </div>
      )}
      <div className="DetailedStatistic__clients">
        <StatisticCard type="message" heading="Number of all clients" content={statistic.clients.total} />
        <StatisticCard type="active" heading="Active clients" content={statistic.clients.totalActive} />
        <StatisticCard type="pending" heading="Pending clients" content={statistic.clients.totalPending} />
        <StatisticCard type="discharged" heading="Discharged clients" content={statistic.clients.totalInactive} />
      </div>
    </div>
  );
};

export default DetailedStatistic;
