import { StoreValue } from 'antd/es/form/interface';
import { getTextFromHtmlTags } from './txt-utils';
import dayjs, { Dayjs } from 'dayjs';

export const validateHomeworkTitle = (_: unknown, value: StoreValue) => {
  const unformattedText = getTextFromHtmlTags(value);

  if (unformattedText.length === 0 || unformattedText.trim().length === 0) {
    return Promise.reject('Please enter the form title');
  }

  return Promise.resolve();
};

export const validateHomeworkDescription = (_: unknown, value: StoreValue) => {
  const unformattedText = getTextFromHtmlTags(value);

  if (unformattedText.length === 0 || unformattedText.trim().length === 0) {
    return Promise.reject('Please enter the form description');
  }

  return Promise.resolve();
};

export const validateHomeworkQuestion = (_: unknown, value: StoreValue) => {
  const unformattedText = getTextFromHtmlTags(value);

  if (unformattedText.length === 0 || unformattedText.trim().length === 0) {
    return Promise.reject('Please enter the question');
  }
  return Promise.resolve();
};

export const validatePhoneNumber = (_: unknown, value: StoreValue) => {
  const newValue = value && value.replaceAll('_', '');
  if (!newValue) {
    return Promise.reject();
  }
  if (newValue && newValue.length === 15) {
    return Promise.resolve();
  }
  if (newValue.length === 6) {
    return Promise.reject(new Error('Please enter the phone number'));
  }
  return Promise.reject(new Error('Invalid input. Please enter a valid phone number'));
};

export const validateFilterDate = (
  startDate: Dayjs | Date | null | undefined,
  endDate: Dayjs | Date | null | undefined,
) => {
  if (!startDate) {
    return 'Please enter the from date';
  } else {
    if (dayjs(startDate).diff(dayjs(), 'day') >= 1) {
      return 'Start date must be less than today';
    }
  }

  if (!endDate) {
    return 'Please enter the to date';
  } else {
    if (dayjs(endDate).diff(dayjs(), 'day') >= 1) {
      return 'End date must be less than today';
    }
  }

  if (dayjs(startDate).diff(dayjs(endDate), 'day') >= 1) {
    return 'End date must be greater than Start date';
  }

  const diff = dayjs(dayjs(endDate).format('YYYY-MM-DD')).diff(
    dayjs(dayjs(startDate).format('YYYY-MM-DD')),
    'year',
    true,
  );
  if (diff > 1) {
    return 'Period should not exceed 12 months';
  }

  return true;
};

export const validatePsychoeducationContent = (_: unknown, value: StoreValue) => {
  if (!value) {
    return Promise.reject('Please enter the article content');
  }

  const unformattedText = getTextFromHtmlTags(value);

  if (unformattedText.length === 0 || unformattedText.trim().length === 0) {
    return Promise.reject('Please enter the article content');
  }

  return Promise.resolve();
};

export const validateReminderAt = (_: unknown, value: StoreValue) => {
  if (!value) {
    return Promise.reject(new Error('Please enter the time'));
  }

  const pattern = /^(1[0-2]|0?[1-9]):[0-5][0-9]$/i;
  if (pattern.test(value)) {
    return Promise.resolve();
  }

  return Promise.reject(new Error('Please enter a valid time format'));
};
