import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { RcFile } from 'antd/es/upload';
import objectSupport from 'dayjs/plugin/objectSupport';
import timezone from 'dayjs/plugin/timezone';

import { EProfileStatus, ETimePeriodTracker, STATUS_PAYMENT_LABEL, TStatusPayment } from 'src/variables/enum-variables';
import { TFilter, TRevenueDetailResponse } from 'src/interfaces/dashboard-interface';

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(objectSupport);

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

export const validateSizeImage = (fileSize: number, maxSize = 1): boolean => {
  // unit is MB, default maxSize is 1MB
  const formattedFileSize = fileSize / 1024 / 1024;
  const isValidFileSize = formattedFileSize < maxSize;

  return isValidFileSize;
};

export const validateFileType = (fileType: string, acceptedFileType: { [key: number | string]: string }): boolean => {
  const acceptedFileTypeArr = Object.values(acceptedFileType);

  return acceptedFileTypeArr.some((type) => fileType.includes(type));
};

export const capitalizeFirstLetter = (input: string, isShowDischarge?: boolean) => {
  if (!input) return '';
  if (input === EProfileStatus.INACTIVE && isShowDischarge) {
    return 'Discharged';
  }

  input = input.toLowerCase();
  return input.charAt(0).toUpperCase() + input.slice(1);
};

export const getFilterTitle = (type: string) => {
  if (type === ETimePeriodTracker.LAST_30_DAYS) {
    return 'Last 30 days';
  } else if (type === ETimePeriodTracker.LAST_6_MONTHS) {
    return 'Last 6 months';
  } else if (type === ETimePeriodTracker.LAST_12_MONTHS) {
    return 'Last 12 months';
  } else {
    return 'Custom';
  }
};

export const getFilterByTitle = (type: string): TFilter => {
  const today = dayjs();
  let priorDate = dayjs();
  if (type === ETimePeriodTracker.LAST_30_DAYS) {
    priorDate = dayjs().set('date', today.get('date') - 30);
  } else if (type === ETimePeriodTracker.LAST_6_MONTHS) {
    priorDate = dayjs().set('month', today.get('month') - 6);
  } else if (type === ETimePeriodTracker.LAST_12_MONTHS) {
    priorDate = dayjs().set('month', today.get('month') - 12);
  }
  return { startDate: priorDate, endDate: today, type };
};

export const sortValue = (values: TRevenueDetailResponse): TRevenueDetailResponse => {
  const result = Object.keys(values)
    .sort((a, b) => (dayjs(a).isAfter(dayjs(b)) ? 1 : -1))
    .reduce(
      (_sortedObj, key) => ({
        ..._sortedObj,
        [key]: values[key],
      }),
      {},
    );
  return result;
};

export const mapRevenue = (values: TRevenueDetailResponse[]): number[] => {
  return values.map((item) => {
    const keys = Object.keys(item);
    const { payment } = item[keys[0]];
    return payment;
  });
};

export const getCurrentTimezone = () => {
  return dayjs.tz.guess();
};

export const paymentLogStatus = (status: string) => {
  if (status === TStatusPayment.OPEN) return STATUS_PAYMENT_LABEL[TStatusPayment.OPEN];
  return STATUS_PAYMENT_LABEL[TStatusPayment.PAID];
};
