import { createAsyncThunk } from '@reduxjs/toolkit';
import { ApiClient } from 'src/services/api-client';
import { AxiosResponse } from 'axios';
import { EPsychoeducationActions } from './psychoeducation-constants';
import {
  TCreateNewPsychoeducationRequest,
  TCreateNewPsychoeducationResponse,
  TCreateNewPsychoeducationTopicRequest,
  TCreateNewPsychoeducationTopicResponse,
  TDeletePsychoeducationRequest,
  TDeletePsychoeducationResponse,
  TGetPsychoeducationDetailRequest,
  TGetPsychoeducationDetailResponse,
  TGetPsychoeducationListRequest,
  TGetPsychoeducationListResponse,
  TGetPsychoeducationTopicListResponse,
  TUpdatePsychoeducationRequest,
  TUpdatePsychoeducationResponse,
} from 'src/interfaces/psychoeducation-interface';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';
import { EPsychoeducationType } from 'src/variables/enum-variables';

export const getPsychoeducationTopicList = createAsyncThunk(
  EPsychoeducationActions.GET_LIST_PSYCHOEDUCATION_TOPIC,
  async (_, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetPsychoeducationTopicListResponse> = await ApiClient.get(
        `/api/v1/psychoeducation-topic`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const addNewPsychoeducationTopic = createAsyncThunk(
  EPsychoeducationActions.ADD_NEW_PSYCHOEDUCATION_TOPIC,
  async (params: TCreateNewPsychoeducationTopicRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TCreateNewPsychoeducationTopicResponse> = await ApiClient.post(
        `/api/v1/psychoeducation-topic`,
        params,
      );
      showSuccessToast('Your topic has been created');
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const getPsychoeducationList = createAsyncThunk(
  EPsychoeducationActions.GET_LIST_PSYCHOEDUCATION,
  async (params: TGetPsychoeducationListRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetPsychoeducationListResponse> = await ApiClient.get(`/api/v1/psychoeducation`, {
        params,
      });

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const getPsychoeducationDetail = createAsyncThunk(
  EPsychoeducationActions.GET_DETAIL_PSYCHOEDUCATION,
  async (params: TGetPsychoeducationDetailRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TGetPsychoeducationDetailResponse> = await ApiClient.get(
        `/api/v1/psychoeducation/${params.id}`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      return rejectWithValue(error.data);
    }
  },
);

export const addNewPsychoeducation = createAsyncThunk(
  EPsychoeducationActions.ADD_NEW_PSYCHOEDUCATION,
  async (params: TCreateNewPsychoeducationRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TCreateNewPsychoeducationResponse> = await ApiClient.post(
        `/api/v1/psychoeducation`,
        params,
      );

      showSuccessToast(
        `Your ${response.data.type === EPsychoeducationType.ARTICLE ? 'article' : 'video'} has been created`,
      );
      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const updatePsychoeducation = createAsyncThunk(
  EPsychoeducationActions.UPDATE_PSYCHOEDUCATION,
  async ({ id, ...params }: TUpdatePsychoeducationRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TUpdatePsychoeducationResponse> = await ApiClient.put(
        `/api/v1/psychoeducation/${id}`,
        params,
      );

      showSuccessToast(
        `Your ${response.data.type === EPsychoeducationType.ARTICLE ? 'article' : 'video'} has been updated`,
      );

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);

export const deletePsychoeducation = createAsyncThunk(
  EPsychoeducationActions.DELETE_PSYCHOEDUCATION,
  async (params: TDeletePsychoeducationRequest, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<TDeletePsychoeducationResponse> = await ApiClient.delete(
        `/api/v1/psychoeducation/${params.id}`,
      );

      showSuccessToast('Delete success');

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (error: any) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
      return rejectWithValue(error.data);
    }
  },
);
