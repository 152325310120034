/* eslint-disable @typescript-eslint/no-explicit-any */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { EPlanActions } from './constants';
import { ApiClient } from 'src/services/api-client';
import { TGetPlanPackagesResponse } from 'src/interfaces/pricing-plan-interface';
import { TGetPlanPackagesAction, TUpdatePlanPackageAction } from './types';

export const getPlanPackagesAction = createAsyncThunk(
  EPlanActions.GET_PLAN_PACKAGES,
  async (payload: TGetPlanPackagesAction, { rejectWithValue }) => {
    const { onSuccess, onError } = payload;
    try {
      const response: AxiosResponse<TGetPlanPackagesResponse> = await ApiClient.get('/api/v1/plan/packages');
      onSuccess?.(response.data);
      return response.data;
    } catch (error: any) {
      onError?.(error);
      return rejectWithValue(error.data);
    }
  },
);

export const updatePlanPackageAction = createAsyncThunk(
  EPlanActions.UPDATE_PLAN_PACKAGE,
  async (payload: TUpdatePlanPackageAction, { rejectWithValue }) => {
    const { onSuccess, onError, packageId, ...bodyRequest } = payload;
    try {
      const response: AxiosResponse<TGetPlanPackagesResponse> = await ApiClient.put(
        `/api/v1/plan/packages/${packageId}`,
        bodyRequest,
      );
      onSuccess?.();
      return response.data;
    } catch (error: any) {
      onError?.(error);
      return rejectWithValue(error.data);
    }
  },
);
