import { Layout } from 'antd';
import { Navigate, Outlet } from 'react-router-dom';

import './AuthLayout.scss';
import Header from './Header';
import Sidebar from './Sidebar';
import { RoutePaths } from 'src/routes/routes-constants';
import { TRootState, useAppDispatch } from 'src/stores';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getAdminProfile } from 'src/stores/user';
import ResponseError from 'src/interfaces/error-response-interface';
import { showErrorToast } from 'src/components/toast/Toast';

const { Content } = Layout;
interface IProps {
  children?: React.ReactNode;
  isAuthenticated: boolean;
}

const AuthLayout = ({ children, isAuthenticated }: IProps) => {
  const dispatch = useAppDispatch();
  const profile = useSelector((state: TRootState) => state.user.profile);

  const getProfile = async () => {
    try {
      await dispatch(getAdminProfile());
    } catch (error) {
      const message = (error as ResponseError).message;
      showErrorToast(message);
    }
  };

  useEffect(() => {
    getProfile();
  }, []);

  if (!isAuthenticated) return <Navigate to={RoutePaths.SIGN_IN} />;

  return (
    <Layout hasSider className="AuthLayout">
      <Header
        username={`${profile?.firstName ? profile.firstName : ''} ${profile?.lastName ? profile.lastName : ''}`}
        avatar="https://www.pngitem.com/pimgs/m/560-5603874_product-image-logo-avatar-minimalist-flat-line-hd.png"
      />
      <Layout>
        <Sidebar />
        <Content className="AuthLayout__content">
          {children}
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};

export default AuthLayout;
