import { useEffect } from 'react';
import { Form, Modal, Button as AntButton } from 'antd';
import { MinusCircleOutlined, PlusCircleOutlined } from '@ant-design/icons';

import Button from 'src/components/button';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { BaseText } from 'src/components/text';
import { TSubscriptionData, TSubscriptionDataValues } from 'src/interfaces/pricing-plan-interface';
import { ESubscriptionType } from 'src/variables/enum-variables';
import './EditSubscriptionModal.scss';

interface IProps {
  open: boolean;
  type: ESubscriptionType;
  data?: TSubscriptionData;
  updating: boolean;
  onClose: () => void;
  onSubmit: (values: TSubscriptionDataValues) => void;
}

const EditSubscriptionModal = ({ open, type, data, updating, onClose, onSubmit }: IProps) => {
  const [form] = Form.useForm<TSubscriptionDataValues>();
  const isSolo = type === ESubscriptionType.SOLO;

  const handleCloseModal = () => {
    onClose();
    setTimeout(() => {
      form.resetFields();
    }, 300);
  };

  const handleSubmit = (values: TSubscriptionDataValues) => {
    onSubmit({ ...values, id: data?.id ?? '' });
  };

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data);
    }
  }, [data]);

  return (
    <Modal className="EditSubscriptionModal" open={open} footer={null} closable={false} onCancel={handleCloseModal}>
      <BaseText textAlign="center" type="title">
        {isSolo ? 'Fixed plan' : 'Multi plan'}
      </BaseText>

      <Form form={form} layout="vertical" onFinish={handleSubmit}>
        <FormItem
          label="Plan name"
          name="name"
          rules={[
            { required: true, message: 'This field is required' },
            { whitespace: true, message: 'This field is required' },
          ]}
        >
          <Input />
        </FormItem>

        <Form.List name="plans">
          {(fields) => {
            return (
              <>
                {isSolo &&
                  fields.map((field) => (
                    <FormItem
                      {...field}
                      label="Price"
                      name={[field.name, 'price']}
                      key={field.key}
                      rules={[
                        {
                          validator: (_, value) => {
                            if (!value) return Promise.reject('This field is required');
                            if (value < 1) return Promise.reject('The price must greater than 0');

                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input type="number" prefix="$" />
                    </FormItem>
                  ))}

                {!isSolo && (
                  <>
                    <div className="EditSubscriptionModal__list-label">
                      <BaseText type="caption">Plan</BaseText>
                    </div>
                    {fields.map((field) => (
                      <div className="EditSubscriptionModal__list-content" key={field.key}>
                        <FormItem {...field} label="Practitioner license" name={[field.name, 'amount']}>
                          <Input type="number" disabled />
                        </FormItem>

                        <FormItem
                          {...field}
                          label="Price"
                          name={[field.name, 'price']}
                          rules={[
                            {
                              validator: (_, value) => {
                                if (!value) return Promise.reject('This field is required');
                                if (value < 1) return Promise.reject('The price must greater than 0');

                                return Promise.resolve();
                              },
                            },
                          ]}
                        >
                          <Input type="number" prefix="$" />
                        </FormItem>
                      </div>
                    ))}
                  </>
                )}
              </>
            );
          }}
        </Form.List>

        <FormItem
          label="Free trial day"
          name="trialDay"
          rules={[
            {
              validator: (_, value) => {
                if (value === '') return Promise.reject('This field is required');
                if (value && value < 1)
                  return Promise.reject('The number of days for free trial must equal or greater than 1');

                return Promise.resolve();
              },
            },
          ]}
        >
          <Input type="number" min={1} />
        </FormItem>

        <Form.List name="descriptions">
          {(fields, { add, remove }) => {
            return (
              <>
                <div className="EditSubscriptionModal__list-label">
                  <BaseText type="caption">Description</BaseText>
                  <AntButton
                    type="link"
                    icon={<PlusCircleOutlined />}
                    className="EditSubscriptionModal__addBtn"
                    onClick={() => add('')}
                  />
                </div>
                {fields.map((field) => (
                  <div className="EditSubscriptionModal__list-content" key={field.key}>
                    <FormItem
                      {...field}
                      name={[field.name]}
                      rules={[
                        { required: fields.length === 1, message: 'This field is required' },
                        { whitespace: true, message: 'This field is required' },
                      ]}
                    >
                      <Input />
                    </FormItem>
                    <AntButton
                      type="link"
                      icon={<MinusCircleOutlined />}
                      className="EditSubscriptionModal__addBtn"
                      disabled={fields.length === 1}
                      onClick={() => remove(field.name)}
                    />
                  </div>
                ))}
              </>
            );
          }}
        </Form.List>

        <Button block type="primary" className="EditSubscriptionModal__confirmBtn" htmlType="submit" loading={updating}>
          Save
        </Button>
        <Button block type="text" className="EditSubscriptionModal__cancelBtn" onClick={handleCloseModal}>
          <BaseText>Cancel</BaseText>
        </Button>
      </Form>
    </Modal>
  );
};

export default EditSubscriptionModal;
