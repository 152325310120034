import { useEffect, useState } from 'react';
import { ChevronDownIcon } from 'src/assets/icons';
import CommonContent from 'src/components/containers/CommonContent';
import { BaseText } from 'src/components/text';
import { getFilterByTitle, getFilterTitle } from 'src/utils/common-utils';
import { ETimePeriodTracker } from 'src/variables/enum-variables';
import TimePeriod from 'src/components/TimePeriod/TimePeriod';
import './Overview.scss';
import { getSaleOverview } from 'src/stores/dashboard';
import { TFilter, TSaleOverviewResponse } from 'src/interfaces/dashboard-interface';
import { TRootState, useAppDispatch } from 'src/stores';
import { useSelector } from 'react-redux';
import { EDashboardActions } from 'src/stores/dashboard';
import { Spin } from 'antd';

const Overview = () => {
  const dispatch = useAppDispatch();
  const [isShowFilter, setIsShowFilter] = useState<boolean>(false);
  const [filter, setFilter] = useState<TFilter>(getFilterByTitle(ETimePeriodTracker.LAST_30_DAYS));
  const [saleOverview, setSaleOverview] = useState<TSaleOverviewResponse>({
    numberClinic: 0,
    numberSoloPractitioner: 0,
  });
  const { loading } = useSelector((state: TRootState) => ({
    loading: state.loading[EDashboardActions.GET_SALES_OVERVIEW],
  }));

  const toggleShowFilter = () => {
    setIsShowFilter(!isShowFilter);
  };

  const onFilter = (value: TFilter) => {
    setFilter(value);
    setIsShowFilter(false);
  };

  const getOverview = async () => {
    const result = await dispatch(getSaleOverview(filter));
    const data = result.payload as TSaleOverviewResponse;
    setSaleOverview({ ...saleOverview, ...data });
  };

  useEffect(() => {
    getOverview();
  }, [filter]);

  return (
    <CommonContent
      title={<BaseText type="headline">Sale overview</BaseText>}
      className="Overview"
      action={
        <div className="Overview__filter" onClick={toggleShowFilter}>
          <BaseText type="caption">{getFilterTitle(filter.type)}</BaseText>
          <ChevronDownIcon />
        </div>
      }
    >
      {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', height: 112 }}>
          <Spin />
        </div>
      ) : (
        <div className="Overview__container">
          <div className="Overview__container-card">
            <div className="Overview__container-card-item">
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <div>
                  <BaseText type="subHeading">Registered clinics</BaseText>
                </div>
              </div>
              <BaseText type="display1">{saleOverview.numberClinic}</BaseText>
            </div>
          </div>
          <div className="Overview__container-card">
            <div className="Overview__container-card-item">
              <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                <div>
                  <BaseText type="subHeading">Registered solo practitioners</BaseText>
                </div>
              </div>
              <BaseText type="display1">{saleOverview.numberSoloPractitioner}</BaseText>
            </div>
          </div>
        </div>
      )}

      {isShowFilter ? <TimePeriod isOpen={true} onClose={toggleShowFilter} value={filter} onSubmit={onFilter} /> : null}
    </CommonContent>
  );
};

export default Overview;
