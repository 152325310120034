import { Form, ModalProps } from 'antd';
import { useEffect, useMemo } from 'react';
import Button from 'src/components/button';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { ConfirmModal } from 'src/components/modals';
import { BaseText } from 'src/components/text';
import { TextArea } from 'src/components/text-area';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import { EQuoteAction, IAddAndEditQuoteFormValues } from './quote-action-modal-constants';

import './QuoteActionModal.scss';

interface IQuoteActionModalProps extends ModalProps {
  type: EQuoteAction;
  defaultQuote?: IAddAndEditQuoteFormValues;
  onCreate: (values: IAddAndEditQuoteFormValues) => void;
  onUpdate: (values: IAddAndEditQuoteFormValues) => void;
  onClose: () => void;
  onDelete: () => void;
}

const QuoteActionModal = (props: IQuoteActionModalProps) => {
  const { type, defaultQuote, onCreate, onUpdate, onClose, onDelete, ...modalProps } = props;
  const [form] = Form.useForm<IAddAndEditQuoteFormValues>();

  useEffect(() => {
    if (defaultQuote) {
      form.setFieldsValue({
        author: defaultQuote.author,
        message: defaultQuote.message,
      });
    }
  }, [defaultQuote]);

  const onCloseModal = () => {
    onClose();
    form.resetFields();
  };

  useEffect(() => {
    if (modalProps.open === false) form.resetFields();
  }, [modalProps.open]);

  const modalHeader = useMemo(() => {
    switch (type) {
      case EQuoteAction.ADD_NEW:
        return 'Add new quote';
      case EQuoteAction.EDIT:
        return 'Edit quote';
      case EQuoteAction.DELETE:
        return 'Delete quote';
    }
  }, [type]);

  return (
    <ConfirmModal {...modalProps} onCancel={onCloseModal} modalHeader={modalHeader} className="QuoteActionModal">
      <Form onFinish={type === EQuoteAction.ADD_NEW ? onCreate : onUpdate} form={form}>
        {type !== EQuoteAction.DELETE && (
          <div className="QuoteActionModal__input-fields">
            <div>
              <BaseText className="hasRequireMark">Quote</BaseText>
              <FormItem
                name="message"
                rules={[
                  {
                    required: true,
                    message: 'Please enter the quote',
                  },
                  { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the quote' },
                  {
                    max: 1000,
                    message: `Maximum length for this field is 1000 characters`,
                  },
                ]}
              >
                <TextArea className="QuoteActionModal__quote-textarea" />
              </FormItem>
            </div>

            <div>
              <BaseText>Author</BaseText>
              <FormItem
                name="author"
                rules={[
                  {
                    max: 100,
                    message: `Maximum length for this field is 100 characters`,
                  },
                ]}
              >
                <Input />
              </FormItem>
            </div>
          </div>
        )}
        {type === EQuoteAction.DELETE && <BaseText textAlign="center">Do you want to delete this quote?</BaseText>}
        <div className={`QuoteActionModal__buttons ${type === EQuoteAction.DELETE && 'delete'}`}>
          <Button
            type="primary"
            className="QuoteActionModal__button"
            htmlType={type === EQuoteAction.DELETE ? 'button' : 'submit'}
            onClick={type === EQuoteAction.DELETE ? onDelete : undefined}
          >
            {type === EQuoteAction.DELETE ? 'Delete' : 'Save'}
          </Button>
          <Button className="QuoteActionModal__button" onClick={onCloseModal}>
            Cancel
          </Button>
        </div>
      </Form>
    </ConfirmModal>
  );
};

export default QuoteActionModal;
