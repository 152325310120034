import dayjs from 'dayjs';
import { BaseText } from 'src/components/text';
import './GeneralInformation.scss';
import { EGetListClientsType, TClinicAndSoloGeneralInformation } from 'src/interfaces/clients-interface';
import { GENDER_OPTIONS } from 'src/variables/common';

interface IGeneralInformationProps {
  clinicInfo: TClinicAndSoloGeneralInformation;
}

const GeneralInformation = ({ clinicInfo }: IGeneralInformationProps) => {
  return (
    <div className="GeneralInformation">
      {clinicInfo.type === EGetListClientsType.CLINIC ? (
        <>
          <div className="GeneralInformation__section">
            <BaseText type="title" className="GeneralInformation__section-header">
              Clinic info
            </BaseText>
            <div className="GeneralInformation__section-details">
              <div className="GeneralInformation__info">
                <BaseText>Clinic name</BaseText>
                <BaseText>{clinicInfo.name}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>ABN/ACN</BaseText>
                <BaseText>{clinicInfo.abn}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Email address</BaseText>
                <BaseText>{clinicInfo.email}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Phone number</BaseText>
                <BaseText>{clinicInfo.phone}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Address</BaseText>
                <BaseText>{clinicInfo.address}</BaseText>
              </div>
            </div>
          </div>
          <div className="GeneralInformation_section">
            <BaseText type="title" className="GeneralInformation__section-header">
              Clinic owner
            </BaseText>
            <div className="GeneralInformation__section-details">
              <div className="GeneralInformation__info">
                <BaseText>Full Name</BaseText>
                <BaseText>
                  {clinicInfo.owner.firstName} {clinicInfo.owner.lastName}
                </BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Gender</BaseText>
                <BaseText>{GENDER_OPTIONS.find((gender) => gender.value === clinicInfo.owner.gender)?.label}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>D.O.B</BaseText>
                <BaseText>{clinicInfo.owner.dob && dayjs(clinicInfo.owner.dob).format('DD/MM/YYYY')}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>ABN/Provider No</BaseText>
                <BaseText>{clinicInfo.owner.provideNumber}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Email address</BaseText>
                <BaseText>{clinicInfo.owner.email}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Phone number</BaseText>
                <BaseText>{clinicInfo.owner.phone}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Address</BaseText>
                <BaseText>{clinicInfo.owner.address}</BaseText>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="GeneralInformation__section">
            <BaseText type="title" className="GeneralInformation__section-header">
              Practitioner info
            </BaseText>
            <div className="GeneralInformation__section-details">
              <div className="GeneralInformation__info">
                <BaseText>Practitioner name</BaseText>
                <BaseText>
                  {clinicInfo.owner.firstName} {clinicInfo.owner.lastName}
                </BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>D.O.B</BaseText>
                <BaseText>{clinicInfo.owner.dob && dayjs(clinicInfo.owner.dob).format('DD/MM/YYYY')}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Gender</BaseText>
                <BaseText>{GENDER_OPTIONS.find((gender) => gender.value === clinicInfo.owner.gender)?.label}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>ABN/ACN</BaseText>
                <BaseText>{clinicInfo.abn}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Type of Practitioner</BaseText>
                <BaseText>
                  {clinicInfo.owner.practitionerTypeName}{' '}
                  {clinicInfo.owner.practitionerTypeOther && `(${clinicInfo.owner.practitionerTypeOther})`}
                </BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Email address</BaseText>
                <BaseText>{clinicInfo.owner.email}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Phone number</BaseText>
                <BaseText>{clinicInfo.owner.phone}</BaseText>
              </div>
              <div className="GeneralInformation__info">
                <BaseText>Address</BaseText>
                <BaseText>{clinicInfo.owner.address}</BaseText>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default GeneralInformation;
