import { Layout, MenuProps, Menu, Form, Dropdown } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { useEffect, useMemo, useState } from 'react';
import { ItemType, SubMenuType } from 'antd/es/menu/hooks/useItems';

import './PsychoeducationPage.scss';
import { BaseText } from 'src/components/text';
import Button from 'src/components/button';
import { FileIcon, HomeworkNewIcon, SearchIcon, VideoIcon } from 'src/assets/icons';
import { ConfirmModal } from 'src/components/modals';
import Input from 'src/components/input';
import { TRootState, useAppDispatch } from 'src/stores';
import { EPsychoeducationType } from 'src/variables/enum-variables';
import { FormItem } from 'src/components/forms';
import { STRING_NOT_ONLY_SPACE_REGEX } from 'src/variables/constants';
import {
  IPsychoeducation,
  TCreateNewPsychoeducationRequest,
  TUpdatePsychoeducationRequest,
} from 'src/interfaces/psychoeducation-interface';
import useDebounce from 'src/hooks/useDebounce';
import AddAndEditPsychoeducation from './AddAndEditPsychoeducation/AddAndEditPsychoeducation';
import { EAddAndEditPsychoeducationPageType } from './AddAndEditPsychoeducation/add-and-edit-psychoeducation-constants';
import {
  addNewPsychoeducation,
  addNewPsychoeducationTopic,
  deletePsychoeducation,
  getPsychoeducationList,
  getPsychoeducationTopicList,
  updatePsychoeducation,
} from 'src/stores/psychoeducation';
import { useSelector } from 'react-redux';
import { unwrapResult } from '@reduxjs/toolkit';
import PsychoeducationDetail from './PsychoeducationDetail/PsychoeducationDetail';
import { LeftOutlined } from '@ant-design/icons';
import PsychoeducationTable from 'src/containers/psychoeducation/PsychoeducationTable';
import { ListLoadingSpinner } from 'src/components/spinner';

const { Sider, Content } = Layout;

const ADD_NEW_TOPIC_LIST_ITEM_KEY = 'add-new';
const DEFAULT_PAGE_SIZE = 15;

const PsychoeducationPage = () => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const searchTextDebounce = useDebounce(searchText, 1000);
  const { psychoeducationList, psychoeducationTopicList } = useSelector((state: TRootState) => state.psychoeducation);

  const [form] = useForm();
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
  const [openAddNewTopicModal, setOpenAddNewTopicModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedPsychoeducation, setSelectedPsychoeducation] = useState<IPsychoeducation>();
  const [isLoadingListPsychoeducation, setIsLoadingListPsychoeducation] = useState(false);
  const [isLoadingPsychoeducationTopic, setIsLoadingPsychoeducationTopic] = useState(false);

  const [deletePsychoeducationModalInfo, setDeletePsychoeducationModalInfo] = useState<{
    isOpen: boolean;
    psychoeducation: IPsychoeducation | null;
  }>({
    isOpen: false,
    psychoeducation: null,
  });

  const [addAndEditPsychoeducationInfo, setAddAndEditPsychoeducationInfo] = useState<{
    psychoeducation: IPsychoeducation | null;
    isOpen: boolean;
    pageType: EAddAndEditPsychoeducationPageType | null;
    psychoEducationType: EPsychoeducationType | null;
  }>({
    psychoeducation: null,
    isOpen: false,
    pageType: null,
    psychoEducationType: null,
  });

  const NavBarList: ItemType[] = useMemo(() => {
    return [
      {
        key: ADD_NEW_TOPIC_LIST_ITEM_KEY,
        label: (
          <Button className="new-topic" onClick={() => setOpenAddNewTopicModal(true)}>
            <BaseText>New Topic</BaseText>
          </Button>
        ),
      },
      ...psychoeducationTopicList.map((topic) => ({ key: topic.id, label: topic.name })),
    ];
  }, [psychoeducationTopicList]);

  const createNewDropdownItems: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <div className="PsychoeducationPageHeader__dropdown-item">
          <FileIcon />
          <BaseText>New Article</BaseText>
        </div>
      ),
      onClick: () =>
        handleOpenAddAndEditPage(EAddAndEditPsychoeducationPageType.ADD_NEW, EPsychoeducationType.ARTICLE, null),
    },
    {
      key: '2',
      label: (
        <div className="PsychoeducationPageHeader__dropdown-item">
          <VideoIcon />
          <BaseText>Video</BaseText>
        </div>
      ),
      onClick: () =>
        handleOpenAddAndEditPage(EAddAndEditPsychoeducationPageType.ADD_NEW, EPsychoeducationType.VIDEO, null),
    },
  ];

  const selectedTopicLabel = useMemo(
    () => (NavBarList.find((listItem) => listItem?.key === selectedKeys[0]) as SubMenuType)?.label,
    [NavBarList, selectedKeys],
  );

  const handleSelectTopic: MenuProps['onClick'] = (e) => {
    if (!e.key.includes(ADD_NEW_TOPIC_LIST_ITEM_KEY)) {
      setSelectedKeys(e.keyPath);
      handleClosePsychoeducationDetail();
    }
  };

  const handleCloseAddNewTopicModal = () => {
    setOpenAddNewTopicModal(false);
    form.resetFields();
  };

  const handleOpenDeletePsychoeducationModal = (item: IPsychoeducation) => {
    setDeletePsychoeducationModalInfo({
      isOpen: true,
      psychoeducation: item,
    });
  };

  const handleCloseDeletePsychoeducationModal = () => {
    setDeletePsychoeducationModalInfo({
      isOpen: false,
      psychoeducation: null,
    });
  };

  const handleOpenAddAndEditPage = (
    pageType: EAddAndEditPsychoeducationPageType,
    psychoEducationType: EPsychoeducationType,
    psychoeducation: IPsychoeducation | null,
  ) => {
    setAddAndEditPsychoeducationInfo({
      psychoeducation,
      isOpen: true,
      pageType,
      psychoEducationType,
    });
  };

  const handleCloseAddAndEditPage = () => {
    setAddAndEditPsychoeducationInfo({
      psychoeducation: null,
      isOpen: false,
      pageType: null,
      psychoEducationType: null,
    });
  };

  const getPsychoeducationTopics = async () => {
    setIsLoadingPsychoeducationTopic(true);
    const res = unwrapResult(await dispatch(getPsychoeducationTopicList()));
    setSelectedKeys([res.data[0].id]);
    setIsLoadingPsychoeducationTopic(false);
  };

  const getListPsychoeducation = async (page: number) => {
    const topicId = selectedKeys[0];
    if (topicId) {
      setIsLoadingListPsychoeducation(true);
      await dispatch(
        getPsychoeducationList({
          page,
          size: DEFAULT_PAGE_SIZE,
          psychoeducationTopicId: topicId,
          ...(searchTextDebounce && { keyword: searchTextDebounce }),
        }),
      );
      setIsLoadingListPsychoeducation(false);
    }
  };

  const handleLoadMorePsychoeducationList = (page: number) => getListPsychoeducation(page);

  const onSaveNewTopic = async (values: { topicName: string }) => {
    const { topicName } = values;
    if (topicName) {
      setIsLoading(true);
      const res = await dispatch(
        addNewPsychoeducationTopic({
          name: topicName,
        }),
      );

      setIsLoading(false);

      if (res.meta.requestStatus === 'fulfilled') {
        getPsychoeducationTopics();
        handleCloseAddNewTopicModal();
      }
    }
  };

  const handleCreatePsychoeducation = async (params: TCreateNewPsychoeducationRequest) => {
    if (addAndEditPsychoeducationInfo.psychoEducationType) {
      setIsLoading(true);
      const res = await dispatch(addNewPsychoeducation(params));
      setIsLoading(false);

      if (res.meta.requestStatus === 'fulfilled') {
        handleCloseAddAndEditPage();
        getListPsychoeducation(psychoeducationList.currentPage);
      }
    }
  };

  const handleEditPsychoeducation = async (params: TUpdatePsychoeducationRequest) => {
    if (addAndEditPsychoeducationInfo.psychoEducationType) {
      setIsLoading(true);
      const res = await dispatch(updatePsychoeducation(params));
      setIsLoading(false);

      if (res.meta.requestStatus === 'fulfilled') {
        handleCloseAddAndEditPage();
        getListPsychoeducation(psychoeducationList.currentPage);
      }
    }
  };

  const handleDeletePsychoeducation = async () => {
    if (deletePsychoeducationModalInfo.psychoeducation?.id) {
      setIsLoading(true);
      const res = await dispatch(
        deletePsychoeducation({
          id: deletePsychoeducationModalInfo.psychoeducation.id,
        }),
      );
      setIsLoading(false);
      handleCloseDeletePsychoeducationModal();

      if (res.meta.requestStatus === 'fulfilled') {
        getListPsychoeducation(psychoeducationList.currentPage);
      }
    }
  };

  const handleClosePsychoeducationDetail = () => {
    setSelectedPsychoeducation(undefined);
  };

  useEffect(() => {
    getListPsychoeducation(1);
  }, [searchTextDebounce, selectedKeys]);

  useEffect(() => {
    getPsychoeducationTopics();
  }, []);

  return (
    <>
      <div className="PsychoeducationPageHeader">
        <BaseText type="heading">Psychoeducation</BaseText>
        {!addAndEditPsychoeducationInfo.isOpen && (
          <Dropdown menu={{ items: createNewDropdownItems }} trigger={['click']}>
            <Button className="PsychoeducationPageHeader__button">
              <HomeworkNewIcon />
              <BaseText>Create new</BaseText>
            </Button>
          </Dropdown>
        )}
      </div>
      <Layout className={`PsychoeducationPage ${addAndEditPsychoeducationInfo.isOpen && 'is-add-and-edit-page'}`}>
        {!addAndEditPsychoeducationInfo.isOpen && (
          <Sider className="Sider" width={230}>
            <Menu
              theme="light"
              mode="inline"
              selectedKeys={selectedKeys}
              onClick={handleSelectTopic}
              items={NavBarList}
              className="Sider__menu"
            />
          </Sider>
        )}
        <Content className="Content">
          {isLoadingPsychoeducationTopic ? (
            <div className="Content__spin-loading">
              <ListLoadingSpinner />
            </div>
          ) : (
            <>
              {!addAndEditPsychoeducationInfo.isOpen && (
                <div className="Content__header">
                  {selectedPsychoeducation && <LeftOutlined height={12} onClick={handleClosePsychoeducationDetail} />}
                  <BaseText type="title" className="Content__title">
                    {selectedTopicLabel}
                  </BaseText>
                </div>
              )}

              {addAndEditPsychoeducationInfo.isOpen &&
              addAndEditPsychoeducationInfo.pageType &&
              addAndEditPsychoeducationInfo.psychoEducationType ? (
                <AddAndEditPsychoeducation
                  psychoeducation={addAndEditPsychoeducationInfo.psychoeducation}
                  pageType={addAndEditPsychoeducationInfo.pageType}
                  psychoEducationType={addAndEditPsychoeducationInfo.psychoEducationType}
                  isLoadingCreateAndEdit={isLoading}
                  onBack={handleCloseAddAndEditPage}
                  onCreatePsychoeducation={handleCreatePsychoeducation}
                  onEditPsychoeducation={handleEditPsychoeducation}
                />
              ) : selectedKeys.length ? (
                selectedPsychoeducation ? (
                  <PsychoeducationDetail id={selectedPsychoeducation.id} />
                ) : (
                  <div className="Content__list">
                    <Input
                      placeholder="Search"
                      prefix={<SearchIcon />}
                      className="Content__search"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />

                    <PsychoeducationTable
                      currentPage={psychoeducationList.currentPage}
                      data={psychoeducationList.data}
                      totalPage={psychoeducationList.totalPage}
                      loading={isLoadingListPsychoeducation}
                      totalRecord={psychoeducationList.totalRecord}
                      pageSize={DEFAULT_PAGE_SIZE}
                      onClickRow={(data) => setSelectedPsychoeducation(data)}
                      onEdit={(item) =>
                        handleOpenAddAndEditPage(EAddAndEditPsychoeducationPageType.EDIT, item.type, item)
                      }
                      onChangePage={handleLoadMorePsychoeducationList}
                      onDelete={handleOpenDeletePsychoeducationModal}
                    />
                  </div>
                )
              ) : null}
            </>
          )}
        </Content>
      </Layout>
      <ConfirmModal
        open={openAddNewTopicModal}
        onCancel={handleCloseAddNewTopicModal}
        modalHeader="Add new topic"
        className="PsychoeducationPage__AddNewTopicModal"
      >
        <Form onFinish={onSaveNewTopic} form={form}>
          <div>
            <BaseText>Topic name</BaseText>
            <FormItem
              name="topicName"
              rules={[
                {
                  required: true,
                  message: 'Please enter the topic name',
                },
                {
                  max: 50,
                  message: `Valid topic name shouldn't exceed 50 characters`,
                },
                { pattern: STRING_NOT_ONLY_SPACE_REGEX, message: 'Please enter the topic name' },
              ]}
            >
              <Input />
            </FormItem>
          </div>
          <div className="AddNewTopicModal__buttons">
            <Button className="AddNewTopicModal__button" onClick={handleCloseAddNewTopicModal}>
              Cancel
            </Button>
            <Button
              type="primary"
              className="AddNewTopicModal__button"
              htmlType="submit"
              disabled={isLoading}
              loading={isLoading}
            >
              Save
            </Button>
          </div>
        </Form>
      </ConfirmModal>

      <ConfirmModal
        open={deletePsychoeducationModalInfo.isOpen}
        onCancel={handleCloseDeletePsychoeducationModal}
        modalHeader={
          deletePsychoeducationModalInfo.psychoeducation?.type === EPsychoeducationType.ARTICLE
            ? 'Delete article'
            : 'Delete video'
        }
        className="PsychoeducationPage__DeletePsychoeducationModal"
      >
        <BaseText textAlign="center">
          Do you want to delete this{' '}
          {deletePsychoeducationModalInfo.psychoeducation?.type === EPsychoeducationType.ARTICLE ? 'article' : 'video'}?
        </BaseText>
        <div className="DeletePsychoeducationModal__buttons">
          <Button className="DeletePsychoeducationModal__button" onClick={handleCloseDeletePsychoeducationModal}>
            Cancel
          </Button>
          <Button
            type="primary"
            className="DeletePsychoeducationModal__button"
            onClick={handleDeletePsychoeducation}
            disabled={isLoading}
            loading={isLoading}
          >
            Delete
          </Button>
        </div>
      </ConfirmModal>
    </>
  );
};

export default PsychoeducationPage;
