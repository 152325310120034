import { useEffect, useMemo, useState } from 'react';
import { ImgContainer } from 'src/containers/auth';
import ForgotPasswordImage from 'src/assets/images/forgot-password.png';
import ForgotPasswordImage1 from 'src/assets/images/forgot-password-1.png';
import { Form, Typography } from 'antd';
import { BaseText } from 'src/components/text';
import { FormItem } from 'src/components/forms';
import Input from 'src/components/input';
import { emailRules } from 'src/variables/rules-form';
import './ForgotPasswordPage.scss';
import Button from 'src/components/button';
import { EForgotPasswordStep } from './forgot-password-constants';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'src/routes/routes-constants';
import { forgotPassword } from 'src/services/auth-service';
import { TForgotPasswordRequest } from 'src/interfaces/auth-interface';
import { showErrorToast, showSuccessToast } from 'src/components/toast/Toast';
import ResponseError from 'src/interfaces/error-response-interface';

const EXPIRE_TIME_MS = 5 * 60 * 1000;

const ForgotPasswordPage = () => {
  const navigate = useNavigate();
  const [step, setStep] = useState<EForgotPasswordStep>(EForgotPasswordStep.INPUT_EMAIL);
  const [loading, setLoading] = useState<boolean>(false);
  const [countdown, setCountdown] = useState(EXPIRE_TIME_MS);
  const [email, setEmail] = useState('');

  const formatCountdown = useMemo(() => {
    const minutes = Math.floor(countdown / 60000);
    const seconds = ((countdown % 60000) / 1000).toFixed(0);
    const formattedSeconds = Number(seconds) >= 10 ? seconds : `0${seconds}`;

    return `${minutes}:${formattedSeconds}`;
  }, [countdown]);

  const onSendEmailForgotPassword = async (values: TForgotPasswordRequest) => {
    try {
      setLoading(true);
      const response = await forgotPassword({ email: values.email });
      if (response.id) {
        setStep(EForgotPasswordStep.SENT_VERIFICATION);
        setCountdown(EXPIRE_TIME_MS);
        showSuccessToast(
          `${
            step === EForgotPasswordStep.INPUT_EMAIL
              ? 'Please check your email to reset password!'
              : 'Resend reset password link successfully!'
          }  `,
        );
      }
    } catch (error) {
      const message = (error as ResponseError).message;

      showErrorToast(message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let interval: NodeJS.Timer;
    if (step === EForgotPasswordStep.INPUT_EMAIL) {
      setCountdown(EXPIRE_TIME_MS);
    } else {
      interval = setInterval(() => {
        setCountdown((prev) => (prev > 0 ? prev - 1000 : 0));
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [step]);

  return (
    <ImgContainer
      bgImgSrc={step === EForgotPasswordStep.INPUT_EMAIL ? ForgotPasswordImage : ForgotPasswordImage1}
      className="ForgotPasswordPage"
    >
      <Form
        className="ForgotPasswordPage__Form"
        autoComplete="off"
        layout="vertical"
        onFinish={onSendEmailForgotPassword}
        onValuesChange={(_, values) => setEmail(values.email)}
      >
        <BaseText type="display1" textAlign="center" className="ForgotPasswordPage__container-heading">
          Forgot password
        </BaseText>
        <BaseText type="body1" className="ForgotPasswordPage__description">
          {step === EForgotPasswordStep.INPUT_EMAIL
            ? "Type the address linked to your account and we'll send you password reset instructions. They might end up in your spam folder, so please check there as well."
            : `A verification link has been sent to the email ${email}.`}
        </BaseText>
        {step === EForgotPasswordStep.SENT_VERIFICATION && (
          <BaseText type="body1" className="ForgotPasswordPage__description">
            Verification link will expire after {formatCountdown}
          </BaseText>
        )}
        <FormItem label="Email" name="email" rules={emailRules} hidden={step === EForgotPasswordStep.SENT_VERIFICATION}>
          <Input />
        </FormItem>
        <Button type="primary" htmlType="submit" size="large" block loading={loading} disabled={loading}>
          {step === EForgotPasswordStep.INPUT_EMAIL ? 'Reset password' : 'Resend'}
        </Button>
        {step === EForgotPasswordStep.INPUT_EMAIL ? (
          <div className="ForgotPasswordPage__input-email-login">
            <BaseText>Don&apos;t have an account yet?</BaseText>
            <Typography.Link
              className="ForgotPasswordPage__login"
              onClick={(e) => {
                e.stopPropagation();
                navigate(RoutePaths.SIGN_IN);
              }}
            >
              Sign in
            </Typography.Link>
          </div>
        ) : (
          <Typography.Link
            className="ForgotPasswordPage__input-email-login"
            onClick={(e) => {
              e.stopPropagation();
              navigate(RoutePaths.SIGN_IN);
            }}
          >
            Back to login
          </Typography.Link>
        )}
      </Form>
    </ImgContainer>
  );
};

export default ForgotPasswordPage;
