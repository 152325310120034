import { EPsychoeducationType } from 'src/variables/enum-variables';
import { useEffect, useState } from 'react';
import { useAppDispatch } from 'src/stores';
import { getPsychoeducationDetail } from 'src/stores/psychoeducation';
import { IPsychoeducation } from 'src/interfaces/psychoeducation-interface';
import { unwrapResult } from '@reduxjs/toolkit';
import { BaseText } from 'src/components/text';
import ReactPlayer from 'react-player';
import { ListLoadingSpinner } from 'src/components/spinner';

import './PsychoeducationDetail.scss';

interface IPsychoeducationDetailProps {
  id: string;
}

const PsychoeducationDetail = ({ id }: IPsychoeducationDetailProps) => {
  const dispatch = useAppDispatch();
  const [psychoeducationDetail, setPsychoeducationDetail] = useState<IPsychoeducation>();
  const [loading, setLoading] = useState<boolean>(true);

  const getDetailPsychoeducation = async () => {
    if (id) {
      setLoading(true);
      const data = unwrapResult(await dispatch(getPsychoeducationDetail({ id })));
      setPsychoeducationDetail(data);
      setLoading(false);
    }
  };

  useEffect(() => {
    getDetailPsychoeducation();
  }, []);

  return loading ? (
    <center>
      <ListLoadingSpinner />
    </center>
  ) : (
    <div className="PsychoeducationDetail">
      {psychoeducationDetail?.type === EPsychoeducationType.ARTICLE ? (
        <div
          className="view ql-editor PsychoeducationDetail__ql-editor"
          dangerouslySetInnerHTML={{ __html: psychoeducationDetail?.content || '' }}
        />
      ) : (
        <div className="PsychoeducationDetail__video">
          <BaseText type="headline" className="PsychoeducationDetail__video--title">
            {psychoeducationDetail?.title}
          </BaseText>
          <BaseText className="PsychoeducationDetail__video--content">{psychoeducationDetail?.content}</BaseText>
          <ReactPlayer
            url={psychoeducationDetail?.videoLink}
            className="PsychoeducationDetail__video--player"
            controls
            width="100%"
            height={560}
          />
        </div>
      )}
    </div>
  );
};

export default PsychoeducationDetail;
