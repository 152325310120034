import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { THomeworkTopic } from 'src/interfaces/homework-interface';

type THomeworkState = {
  activityTopicList: THomeworkTopic[];
  videoTopicList: THomeworkTopic[];
  writtenTaskTopicList: THomeworkTopic[];
  questionTopicList: THomeworkTopic[];
};

const initialState: THomeworkState = {
  activityTopicList: [],
  videoTopicList: [],
  writtenTaskTopicList: [],
  questionTopicList: [],
};

export const homeworkSlice = createSlice({
  name: 'homework',
  initialState: initialState,
  reducers: {
    setActivityTopicList: (state, action: PayloadAction<THomeworkTopic[]>) => {
      state.activityTopicList = action.payload;
    },
    setVideoTopicList: (state, action: PayloadAction<THomeworkTopic[]>) => {
      state.videoTopicList = action.payload;
    },
    setWrittenTaskTopicList: (state, action: PayloadAction<THomeworkTopic[]>) => {
      state.writtenTaskTopicList = action.payload;
    },
    setQuestionTopicList: (state, action: PayloadAction<THomeworkTopic[]>) => {
      state.questionTopicList = action.payload;
    },
  },
});

export default homeworkSlice;
