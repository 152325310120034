import { Layout, Space } from 'antd';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import { RoutePaths } from 'src/routes/routes-constants';
import { BaseText } from 'src/components/text';
import { EUserType } from 'src/variables/enum-variables';
import { SIDEBAR_ITEMS_BY_TYPE } from './sidebar-constants';
import { ESidebarItemLabel, TSidebarItem } from './sidebar-types';
import {
  ActiveClientIcon,
  ClientIcon,
  FullAntsaLogo,
  AntsaLogo,
  IconMenuHomework,
  IconMenuPsychoeducation,
  IconMenuQoute,
  IconMenuSubcription,
  IconMenuHomeworkActive,
  IconMenuPsychoeducationActive,
  IconMenuQouteActive,
  IconMenuSubcriptionActive,
} from 'src/assets/icons';
import './Sidebar.scss';

const { Sider } = Layout;

interface IProps {
  className?: string;
}

export const SIDEBAR_ITEMS: { [key: string]: TSidebarItem } = {
  [ESidebarItemLabel.CLIENTS]: {
    icon: <ClientIcon />,
    activeIcon: <ActiveClientIcon />,
    label: ESidebarItemLabel.CLIENTS,
    path: RoutePaths.CLIENTS,
  },
  [ESidebarItemLabel.HOMEWORK]: {
    icon: <IconMenuHomework />,
    activeIcon: <IconMenuHomeworkActive />,
    label: ESidebarItemLabel.HOMEWORK,
    path: RoutePaths.HOMEWORK,
  },
  [ESidebarItemLabel.PSYCHOEDUCATION]: {
    icon: <IconMenuPsychoeducation />,
    activeIcon: <IconMenuPsychoeducationActive />,
    label: ESidebarItemLabel.PSYCHOEDUCATION,
    path: RoutePaths.PSYCHO_EDUCATION,
  },
  [ESidebarItemLabel.QUOTES]: {
    icon: <IconMenuQoute />,
    activeIcon: <IconMenuQouteActive />,
    label: ESidebarItemLabel.QUOTES,
    path: RoutePaths.QUOTES_OF_THE_DAY,
  },
  [ESidebarItemLabel.PRICING_PLAN]: {
    icon: <IconMenuSubcription />,
    activeIcon: <IconMenuSubcriptionActive />,
    label: ESidebarItemLabel.PRICING_PLAN,
    path: RoutePaths.PRICING_PLAN,
  },
};

const Sidebar = ({ className }: IProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems = SIDEBAR_ITEMS_BY_TYPE[EUserType.ADMIN]?.map((item) => SIDEBAR_ITEMS[item]) || [];

  return (
    <Sider className={`Sidebar ${className ?? ''}`} theme="light" width={340}>
      <Space
        className="Sidebar__logo"
        size={15}
        onClick={() => {
          navigate(`${RoutePaths.HOME}`);
        }}
      >
        <AntsaLogo />
        <FullAntsaLogo />
      </Space>
      <div className="Sidebar__menu">
        {menuItems.map((menu: TSidebarItem, index: number) => (
          <SidebarItem item={menu} key={index} isActive={location.pathname === menu.path} />
        ))}
      </div>
    </Sider>
  );
};

interface ISidebarItem {
  isActive?: boolean;
  item: TSidebarItem;
}

const SidebarItem = ({ item, isActive }: ISidebarItem) => {
  const { label, icon, activeIcon, path } = item;
  return (
    <Link to={path}>
      <div className={`SidebarItem ${isActive ? 'active' : ''}`}>
        <span className="active-icon">{activeIcon}</span>
        <span className="icon">{icon}</span>
        <BaseText className="SidebarItem__label" type="subHeading">
          {label}
        </BaseText>
      </div>
    </Link>
  );
};

export default Sidebar;

export { SidebarItem };
