import { Modal, ModalProps } from 'antd';
import { BaseText } from 'src/components/text';
import Button from 'src/components/button';
import './PreviewModal.scss';

interface IPreviewModalProps extends ModalProps {
  content: string;
  onClose: () => void;
}

const PreviewModal = ({ content, className, onClose, ...rest }: IPreviewModalProps) => {
  return (
    <Modal className={`PreviewModal ${className}`} footer={null} closable={false} centered {...rest}>
      <BaseText type="heading" textAlign="center" className="PreviewModal__title">
        Preview
      </BaseText>
      <div className="view ql-editor PreviewModal__ql-editor" dangerouslySetInnerHTML={{ __html: content }}></div>
      <div className="PreviewModal__buttons">
        <Button className="PreviewModal__button" type="primary" onClick={onClose}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default PreviewModal;
