import { createSlice } from '@reduxjs/toolkit';

import { TGetPlanPackagesResponse } from 'src/interfaces/pricing-plan-interface';
import * as asyncActions from './actions';

type TPlanManagementState = {
  planPackages: TGetPlanPackagesResponse;
};

const initialState: TPlanManagementState = {
  planPackages: [],
};

export const clientsSlice = createSlice({
  name: 'planManagement',
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(asyncActions.getPlanPackagesAction.fulfilled, (state, action) => {
      state.planPackages = action.payload;
    });
  },
});

export default clientsSlice;
