import { ChangeEvent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import CommonContent from 'src/components/containers/CommonContent';
import Container from 'src/components/containers/Container';
import { TabsHeader } from 'src/components/tabs';
import { ClientsTable } from 'src/containers/clients';
import { EGetListClientsType, TClient } from 'src/interfaces/clients-interface';
import { TFilterValues } from 'src/interfaces/common-interface';
import { TRootState, useAppDispatch } from 'src/stores';
import { EClientsAction, getClients } from 'src/stores/clients';
import { CLIENTS_TAB, DEFAULT_CLIENTS_PAGE_SIZE } from './clients-constants';
import { SearchInput } from 'src/components/input';
import useDebounce from 'src/hooks/useDebounce';
import { useNavigate } from 'react-router-dom';
import { RoutePaths } from 'src/routes/routes-constants';
import './Clients.scss';

const Clients = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { clientList } = useSelector((state: TRootState) => state.clients);

  const [currentTabKey, setCurrentTabKey] = useState<EGetListClientsType>(EGetListClientsType.CLINIC);
  const [search, setSearch] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const searchDebounce = useDebounce(search);
  const { loading } = useSelector((state: TRootState) => ({
    loading: state.loading[EClientsAction.GET_CLIENTS],
  }));

  const getClientList = (page: number) => {
    dispatch(
      getClients({
        page,
        size: DEFAULT_CLIENTS_PAGE_SIZE,
        type: currentTabKey,
        ...(searchDebounce && { keyword: searchDebounce }),
        ...(filterStatus && { status: filterStatus }),
      }),
    );
  };

  const onChangePage = (page: number) => {
    getClientList(page);
  };

  const onClickRow = (rowData: TClient) => {
    navigate(RoutePaths.CLIENT_DETAIL(rowData.id));
  };

  const onFilter = (values: TFilterValues) => {
    setFilterStatus(values.status?.join(',') || '');
  };

  const onChangeTab = (key: string) => {
    setCurrentTabKey(key as EGetListClientsType);
  };

  const handleSearchClient = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  useEffect(() => {
    getClientList(0);
  }, [currentTabKey, searchDebounce, filterStatus]);

  return (
    <Container className="Clients">
      <CommonContent title="Clients">
        <div className="Clients__header">
          <TabsHeader
            items={CLIENTS_TAB}
            activeKey={currentTabKey}
            onChangeTab={onChangeTab}
            secondaryHeader={false}
            className="Clients__tabs"
          />
          <SearchInput
            placeholder="Search by name or email"
            width="320px"
            value={search}
            onChange={handleSearchClient}
          />
        </div>
        <ClientsTable
          {...clientList}
          isClinicPractitionerTab={currentTabKey === EGetListClientsType.CLINIC}
          loading={loading}
          onChangePage={onChangePage}
          onClickRow={onClickRow}
          onFilter={onFilter}
        />
      </CommonContent>
    </Container>
  );
};

export default Clients;
